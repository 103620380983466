<template>
  <v-card flat>
    <grid-layout
      :layout="getListSetupFields"
      :col-num="Number(getCols)"
      :auto-size="true"
      :use-css-transforms="true"
      :row-height="24"
      :margin="[8, 8]"
    >
      <grid-item
        v-for="item in getListSetupFields"
        v-bind:key="item.id"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :maxH="item['input_ui']==='3' ? 12 : item.h"
        :i="item.i"
        :is-resizable="item['input_ui']==='3'"
        :is-draggable="item['input_ui']==='3'"
        :id="item.id"
      >
        <v-card outlined class="align-center" height="100%">
          <v-col class="col-12" v-if="item['tip'] === '1'">
            <input-indicator
              :indicator-data="item"
              :input-data="
                dataPropComputed[`${item['tip']}_${item['master_id']}`]
              "
            />
          </v-col>

          <v-col
            class="col-12"
            v-if="item['tip'] === '2' && item['birim'] === '0'"
          >
            <slide-switch
              :switch-data="item"
              :output-data="
                dataPropComputed[`${item['tip']}_${item['master_id']}`]
              "
              :modem-id="getDataProperty['Id']"
            />
          </v-col>

          <v-col
            class="col-12"
            v-if="item['tip'] === '2' && item['birim'] !== '0'"
          >
            <push-button :modem-id="getDataProperty['Id']" :pb-data="item" />
          </v-col>

          <v-col class="col-12" v-if="item['tip'] === '3'">
            <input-value-indicator
              :indicator-data="item"
              :input-data="
                isNaN(dataPropComputed[`${item['tip']}_${item['master_id']}`])
                  ? dataPropComputed[`${item['tip']}_${item['master_id']}`]
                  : Number(
                      dataPropComputed[`${item['tip']}_${item['master_id']}`]
                    ).toFixed(3)
              "
            />
          </v-col>

          <v-col class="col-12" v-if="item['tip'] === '4'">
            <input-value-indicator
              :indicator-data="item"
              :input-data="
                isNaN(dataPropComputed[`${item['tip']}_${item['master_id']}`])
                  ? dataPropComputed[`${item['tip']}_${item['master_id']}`]
                  : Number(
                      dataPropComputed[`${item['tip']}_${item['master_id']}`]
                    ).toFixed(3)
              "
            />
          </v-col>

          <v-col
            class="col-12"
            v-if="item['tip'] === '8' && item['birim'] === ''"
          >
            <modbus-item
              :input-data="
                dataPropComputed[`${item['tip']}_${item['master_id']}_`]
              "
              :indicator-data="item"
              v-if="item['input_ui'] === '3'"
            />
            <input-value-indicator
              v-else-if="
                dataPropComputed.hasOwnProperty(
                  `${item['tip']}_${item['master_id']}_`
                )
              "
              :indicator-data="item"
              :input-data="
                isNaN(dataPropComputed[`${item['tip']}_${item['master_id']}_`])
                  ? dataPropComputed[`${item['tip']}_${item['master_id']}_`]
                  : Number(
                      dataPropComputed[`${item['tip']}_${item['master_id']}_`]
                    ).toFixed(3)
              "
            />

            <input-value-indicator
              v-else-if="
                dataPropComputed.hasOwnProperty(
                  `${item['tip']}_${item['master_id']}_${item['birim']}`
                )
              "
              :indicator-data="item"
              :input-data="
                isNaN(
                  dataPropComputed[
                    `${item['tip']}_${item['master_id']}_${item['birim']}`
                  ]
                )
                  ? dataPropComputed[
                      `${item['tip']}_${item['master_id']}_${item['birim']}`
                    ]
                  : Number(
                      dataPropComputed[
                        `${item['tip']}_${item['master_id']}_${item['birim']}`
                      ]
                    ).toFixed(3)
              "
            />
          </v-col>

          <v-col
            class="col-12"
            v-if="item['tip'] === '8' && item['birim'] !== ''"
          >
            <input-value-indicator
              :indicator-data="item"
              :input-data="
                isNaN(
                  dataPropComputed[
                    `${item['tip']}_${item['master_id']}_${item['birim']}`
                  ]
                )
                  ? dataPropComputed[
                      `${item['tip']}_${item['master_id']}_${item['birim']}`
                    ]
                  : Number(
                      dataPropComputed[
                        `${item['tip']}_${item['master_id']}_${item['birim']}`
                      ]
                    ).toFixed(3)
              "
            />
          </v-col>
        </v-card>
      </grid-item>
    </grid-layout>
  </v-card>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import slideSwitch from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/ListSetupItems/slideSwitch";
import pushButton from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/ListSetupItems/pushButton";
import inputIndicator from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/ListSetupItems/inputIndicator";
import inputValueIndicator from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/ListSetupItems/inputValueIndicator";
import modbusListItem from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/ListSetupItems/modbusListItem";
/*eslint-disable*/
export default {
  name: "ListSetup",
  components: {
    "slide-switch": slideSwitch,
    "push-button": pushButton,
    "input-indicator": inputIndicator,
    "input-value-indicator": inputValueIndicator,
    "modbus-item": modbusListItem,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  props: {
    listSetup: {
      type: Object,
      required: true
    },
    dataProperty: {
      type: Object,
      required: true
    },
    updatedDataProperty: {
      type: Object,
      required: false
    },
    genislikWidth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      listSetupData: [],
      show_wrap: true,
      columnNum: null,
      defaultDataProperty: {}
    };
  },
  computed: {
    getListSetupFields: function() {
      //Add a key-value pair to the object for switches.
      //This is going to work both for getting the full object and true/false value when switch detected.
      return this.listSetup["comps"];
    },
    getCols: function() {
      return this.listSetup["cols"];
    },
    getDataProperty: function() {
      return this.dataProperty;
    },
    /*
     * Fires when updatedData property changes.
     * Adds updatedData object to default dataProperty object & updates
     * Important: Spread operator does not work for some reason...
     * */
    dataPropComputed: function() {
      console.log("COMPUTED UPDATED PROP", this.updatedDataProperty);
      console.log("COMPUTED DEFAULT PROP", this.defaultDataProperty);
      let updated = this.defaultDataProperty;
      const { Datas: updatedData, ModemId } = this.updatedDataProperty;
      if (ModemId === this.dataProperty["Id"]) {
        if (Object.keys(this.updatedDataProperty).length > 0) {
          Object.keys(updatedData).forEach(k => {
            if (updated.hasOwnProperty(k)) {
              updated[k] = updatedData[k];
            } else {
              const [tip, master] = k.split("_");
              if (tip === "3") {
                updated[`${tip}_${master}`] = updatedData[k];
              }
            }
          });
        }
      }

      console.log("DATA UPDATED VALUE", updated);
      return updated;
    },
    computeColumn: {
      get: function() {
        return this.genislikWidth;
      },
      set: function(v) {
        if (v <= 6) {
          this.show_wrap = false;
          this.columnNum = 12;
        } else {
          this.show_wrap = true;
        }
      }
    }
  },
  created() {
    this.defaultDataProperty = this.getDataProperty["Datas"];
    console.warn("Updated Data Prop", this.updatedDataProperty);
    console.log(
      "List setup data prop:: ",
      this.dataProperty,
      this.listSetupData
    );
  }
};
</script>

<style scoped></style>
