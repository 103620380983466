import tweenFunctions from "./easings";
let svgAnimationPlugin = {};
import colorNames from "./colorNames";

svgAnimationPlugin.install = function(Vue) {
  /*eslint-disable*/
  let svgElement = null;
  let startTime = new Date();
  let configurations = {};
  let isTranslateAnimation = false;

  let isStyleAnimation = false;
  let styleAttributeName = "";

  const getHighResolutionTimeStamp = () => {
    return window.performance.now
      ? performance.now() + performance.timeOrigin
      : Date.now();
  };


  Vue.prototype.xAnim = (...args) => {
    let svgElement = null;
    const checkType = {
      arr: a => Array.isArray(a),
      obj: a => typeof a === "object",
      str: a => typeof a === "string",
      fnc: a => typeof a === "function",
      und: a => typeof a === "undefined",
      hex: a => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(a),
      rgb: a => /^rgb/.test(a),
      hsl: a => /^hsl/.test(a)
    };

    const elementSetupObject = {
      attributes: {},
      styles: {},
      easing: {},
      transform: {}
    };

    console.log("ARGSSSSS", typeof args[0]);
    /*eslint-disable*/
    if (checkType.str(args[0])) {
      svgElement = document.querySelector(args[0]);
    }else if (checkType.obj(args[0])) {
      svgElement = args[0];
    }


    const object = {
      attribute(...args) {
        /*eslint-disable*/
        if (checkType.str(args[0])) {
          const [attribute, value] = args;
          const startValue = svgElement.getAttribute(attribute);

          elementSetupObject["attributes"][attribute] = {
            startValue: parseInt(startValue),
            targetValue: parseInt(value),
            differenceValue: parseInt(value) - parseInt(startValue)
          };
        } else if (checkType.obj(args[0])) {
          Object.entries(args[0]).forEach(entry => {
            const [attribute, value] = entry;
            let startValue = svgElement.getAttribute(attribute);
            if (startValue === null) startValue = 0;

            elementSetupObject["attributes"][attribute] = {
              startValue: parseInt(startValue),
              targetValue: parseInt(value),
              differenceValue: parseInt(value) - parseInt(startValue)
            };
          });
        }

        return this;
      },
      transform(...args) {
        if (checkType.obj(args[0])) {
          //COMMENT: if arguments is an object. Check every entry and call callType function to determine the type
          Object.entries(args[0]).forEach(entry => {
            this.callType(entry);
          });
        } else if (checkType.str(args[0])) {
          console.log("TYPE IS STRING");
          //const [transformType, values] = args;
        }
        return this;
      },
      callType(entry) {
        //COMMENT: call appropriate function with the type of transform
        const [transformType, values] = entry;
        switch (transformType) {
          case "translate":
            this.parseTranslateValues(values);
            break;
          case "scale":
            break;
          case "rotate":
            break;
          case "matrix":
            break;
          default:
        }
      },
      parseTranslateValues(value) {
        //COMMENT: Setup translate object => targetValue;
        elementSetupObject["transform"]["translate"] = {
          startValue: { x: 0, y: 0 },
          differenceValue: { x: 0, y: 0 },
          targetValue: { x: 0, y: 0 }
        };
        //COMMENT: Check the value argument. It should be the form of (x,y)
        /*eslint-disable*/
        let [xTarget, yTarget] = value
          .substring(1, value.length - 1)
          .split(",")
          .map(Number);

        elementSetupObject["transform"]["translate"]["targetValue"][
          "x"
        ] = xTarget;
        elementSetupObject["transform"]["translate"]["targetValue"][
          "y"
        ] = yTarget;

        const regExpTransform = /(translate|matrix|rotate|skewX|skewY|scale)\(.*?\)/g;
        let transformStart = svgElement.getAttribute("transform");
        if (transformStart === null) {
          //COMMENT: Cannot found any transform that is binded to the element :: Set start value to x0 y0
          elementSetupObject["transform"]["translate"]["startValue"]["x"] = 0;
          elementSetupObject["transform"]["translate"]["startValue"]["y"] = 0;
          elementSetupObject["transform"]["translate"]["differenceValue"][
            "x"
          ] = xTarget;
          elementSetupObject["transform"]["translate"]["differenceValue"][
            "y"
          ] = yTarget;
        } else {
          //COMMENT: There is a transform on the element
          let [val] = transformStart.match(regExpTransform);
          if (val.includes("translate")) {
            let [xStart, yStart] = val
              .substring(val.indexOf("(") + 1, val.length - 1)
              .split(",")
              .map(Number);

            elementSetupObject["transform"]["translate"]["startValue"][
              "x"
            ] = xStart;
            elementSetupObject["transform"]["translate"]["startValue"][
              "y"
            ] = yStart;

            elementSetupObject["transform"]["translate"]["differenceValue"][
              "x"
            ] = xTarget - xStart;

            elementSetupObject["transform"]["translate"]["differenceValue"][
              "y"
            ] = yTarget - yStart;
          }
        }
        console.log(
          "Translate Values",
          elementSetupObject["transform"]["translate"]
        );
      },
      style(...args) {
        if (typeof args[0] === "object") {
          //loop through objects
          Object.entries(args[0]).forEach((entry, index) => {
            //## entry is style attribute;
            const [attribute, value] = entry;
            const startValue = svgElement.getAttribute(attribute);

            elementSetupObject["styles"][attribute] = {
              startValue: 0,
              targetValue: 0,
              differenceValue: 0
            };

            this.convertToRGBA({
              startValue: colorNames[startValue]
                ? colorNames[startValue]
                : startValue,
              targetValue: colorNames[value] ? colorNames[value] : value,
              attribute: attribute
            });
          });
        } else if (typeof args[0] === "string") {
          const [attribute, value] = args;
          const startValue = svgElement.getAttribute(attribute);

          //COMMENT: initialize styles[attribute] object here so that we can access it later.
          elementSetupObject["styles"][attribute] = {
            startValue: 0,
            targetValue: 0,
            differenceValue: 0
          };

          this.convertToRGBA({
            startValue: colorNames[startValue]
              ? colorNames[startValue]
              : startValue,
            targetValue: colorNames[value] ? colorNames[value] : value,
            attribute: attribute
          });
        }
        return this;
      },
      easing(...args) {
        const [easingFunction, duration] = args;

        elementSetupObject["easing"]["easingFunction"] = easingFunction;
        elementSetupObject["easing"]["duration"] = duration;

        return this;
      },
      apply() {
        if (Object.keys(elementSetupObject["easing"]).length === 0) {
          //COMMENT: # if there is no transition value,
          //COMMENT: just append elements without animation
          this.setAttributesWithoutAnimation(elementSetupObject);
        } else {
          //COMMENT: Call animation functions here
          //COMMENT: Setup. Functions that are going to handle attribute and style animations are going to be
          //COMMENT: two different functions. That way we don't run into any issues.
          //COMMENT: Check the length of both attribute object and styles object.
          if (Object.keys(elementSetupObject["attributes"]).length !== 0) {
            //COMMENT: Call the attribute animation function
            startTime = getHighResolutionTimeStamp();
            requestAnimationFrame(animateAttribute);
          }
          if (Object.keys(elementSetupObject["styles"]).length !== 0) {
            //COMMENT: Call the style animation function
            startTime = getHighResolutionTimeStamp();
            requestAnimationFrame(animateStyle);
            console.log("Style called");
          }
          if (Object.keys(elementSetupObject["transform"]).length !== 0) {
            //COMMENT: Call transform animation
            //TODO: add other transform animations after. Calculate using matrix values.
            startTime = getHighResolutionTimeStamp();
            requestAnimationFrame(animateTranslate);
            console.log("Transform called");
          }
        }
      },

      //COMMENT: Above functions are all util functions.
      //TODO: Put above functions to another file as util functions.
      setAttributesWithoutAnimation(data) {
        Object.entries(data).forEach(values => {
          const [ObjectNames, ValueObjects] = values;
          //COMMENT: Handle attributes and styles seperate.
          Object.entries(ValueObjects).forEach(valueObject => {
            const [attrName, attrObject] = valueObject;
            //svgElement.setAttribute(attrName, attrObject["targetValue"]);
            if (ObjectNames !== "easing" && ObjectNames === "attributes") {
              console.log("Attribute exists!", attrName);
              svgElement.setAttribute(attrName, attrObject["targetValue"]);
            } else if (ObjectNames !== "easing" && ObjectNames === "styles") {
              console.log("Styles exists!", attrName);
              //COMMENT: @defined: attrObject has startValue, targetValue and differenceValue
              let { targetValue } = attrObject;
              let hexValue = this.toHex(targetValue);
              svgElement.setAttribute(attrName, hexValue);
            } else if (
              ObjectNames !== "easing" &&
              ObjectNames === "transform" &&
              ValueObjects.hasOwnProperty("translate")
            ) {
              //COMMENT: check for a translate object
              let { x, y } = attrObject["targetValue"];
              console.log("Translate exists!", x, y);
              svgElement.setAttribute(
                "transform",
                attrName + "(" + x.toString() + "," + y.toString() + ")"
              );
            }
          });
        });
      },
      //COMMENT: Check startValue and targetValue. Are they hex or not ?
      //COMMENT: If start and target values are hex values then convert those values to -> rgb (rgba)
      //COMMENT: Set converted start and target values to object that initialized.
      //COMMENT: Lastly, set differenceValues by calculating target - start value.
      //COMMENT: If values are rgb, then extract red, green and blue values and then set.
      convertToRGBA(args) {
        /*eslint-disable*/
        var [redStart, greenStart, blueStart] = [];
        var [redTarget, greenTarget, blueTarget] = [];
        const { startValue, targetValue, attribute } = args;

        if (this.isHexColor(startValue)) {
          //COMMENT: convert to rgba here
          [redStart, greenStart, blueStart] = this.toRGBA(startValue);
          elementSetupObject["styles"][attribute]["startValue"] = {
            red: redStart,
            green: greenStart,
            blue: blueStart
          };
        } else {
          //COMMENT: split rgba string here
          [redStart, greenStart, blueStart] = this.splitRGBA(startValue);
          elementSetupObject["styles"][attribute]["startValue"] = {
            red: redStart,
            green: greenStart,
            blue: blueStart
          };
        }

        if (this.isHexColor(targetValue)) {
          //COMMENT: convert to rgba here
          [redTarget, greenTarget, blueTarget] = this.toRGBA(targetValue);
          elementSetupObject["styles"][attribute]["targetValue"] = {
            red: redTarget,
            green: greenTarget,
            blue: blueTarget
          };
        } else {
          //COMMENT: split rgba string here
          [redTarget, greenTarget, blueTarget] = this.splitRGBA(targetValue);
          elementSetupObject["styles"][attribute]["targetValue"] = {
            red: redTarget,
            green: greenTarget,
            blue: blueTarget
          };
        }

        //COMMENT: set values here
        elementSetupObject["styles"][attribute]["differenceValue"] = {
          red: redTarget - redStart,
          green: greenTarget - greenStart,
          blue: blueTarget - blueStart
        };

        console.log("Result", elementSetupObject);
      },

      //COMMENT:determine if string value is compatible hex string with # infront of it
      //COMMENT:Is hex or not: returns true if hex, false if not
      isHexColor(color) {
        return (
          color.length === 7 &&
          typeof color === "string" &&
          color.charAt(0) === "#"
        );
      },
      //COMMENT:convert the hex color string to rgb value.
      //COMMENT:returns as [red,green,blue]
      toRGBA(color) {
        return color
          .slice(1)
          .match(/..?/g)
          .map(value => {
            return parseInt(value, 16);
          });
      },
      //COMMENT: splits the rgba string as [red,green,blue]
      //TODO: add alpha support as well [red,green,blue,alpha]
      splitRGBA(color) {
        return color
          .replace(/[^\d,.]/g, "")
          .split(",")
          .map(value => {
            return parseInt(value);
          });
      },
      //COMMENT: converts rgb color to hex
      toHex(color) {
        //COMMENT: @params color is probably going to be targetValue={r:...,g:....,b:....}
        //COMMENT: convert values hexadecimal string & if length of those values is 1 then add a 0 to start
        let { red, green, blue } = color;
        red = red.toString(16);
        green = green.toString(16);
        blue = blue.toString(16);

        if (red.length === 1) red = "0" + red;
        if (green.length === 1) green = "0" + green;
        if (blue.length === 1) blue = "0" + blue;

        return "#" + red + green + blue;
      }
    };

    let animationLoop = time => {
      Object.entries(elementSetupObject["attributes"]).forEach(object => {
        let [attributeName, values] = object;
        values["targetValue"] = tweenFunctions[
          elementSetupObject["easing"]["easingFunction"]
        ](
          time,
          values["startValue"],
          values["differenceValue"],
          elementSetupObject["easing"]["duration"]
        );

        svgElement.setAttribute(
          attributeName,
          values["targetValue"].toString()
        );
      });
    };

    /*eslint-disable*/
    let translateLoop = time => {
      let xyHolder = { x: 0, y: 0 };

      Object.entries(
        elementSetupObject["transform"]["translate"]["targetValue"]
      ).forEach((entry, index) => {
        elementSetupObject["transform"]["translate"]["targetValue"][
          entry[0]
        ] = tweenFunctions[elementSetupObject["easing"]["easingFunction"]](
          time,
          elementSetupObject["transform"]["translate"]["startValue"][entry[0]],
          elementSetupObject["transform"]["translate"]["differenceValue"][
            entry[0]
          ],
          elementSetupObject["easing"]["duration"]
        );

        switch (entry[0]) {
          case "x":
            xyHolder.x =
              elementSetupObject["transform"]["translate"]["targetValue"][
                entry[0]
              ];
            break;
          case "y":
            xyHolder.y =
              elementSetupObject["transform"]["translate"]["targetValue"][
                entry[0]
              ];
            break;
        }
        if (index % 2 !== 0) {
          svgElement.setAttribute(
            "transform",
            joinThose(xyHolder.x, xyHolder.y)
          );
        }
      });
    };

    let styleLoop = time => {
      for (let attrName in elementSetupObject["styles"]) {
        for (let colorSpace in elementSetupObject["styles"][attrName][
          "targetValue"
        ]) {
          elementSetupObject["styles"][attrName]["targetValue"][
            colorSpace
          ] = tweenFunctions[elementSetupObject["easing"]["easingFunction"]](
            time,
            elementSetupObject["styles"][attrName]["startValue"][colorSpace],
            elementSetupObject["styles"][attrName]["differenceValue"][
              colorSpace
            ],
            elementSetupObject["easing"]["duration"]
          );

          let r = Math.round(
            elementSetupObject["styles"][attrName]["targetValue"]["red"]
          );
          let g = Math.round(
            elementSetupObject["styles"][attrName]["targetValue"]["green"]
          );
          let b = Math.round(
            elementSetupObject["styles"][attrName]["targetValue"]["blue"]
          );

          let hexVal = object.toHex({ red: r, green: g, blue: b });
          //console.log("Hexval", hexVal);
          svgElement.setAttribute(attrName, hexVal);
        }
      }
    };

    let joinThose = (a, b) => {
      return "translate(" + a + "," + b + ")";
    };

    let animateAttribute = () => {
      let time = getHighResolutionTimeStamp() - startTime;
      //console.log("CURRENT TIME",time);
      if (time < elementSetupObject["easing"]["duration"]) {
        animationLoop(time);
        requestAnimationFrame(animateAttribute);
      } else {
        time = elementSetupObject["easing"]["duration"];
        animationLoop(time);
        onAnimationStopped("attribute");
      }
    };

    let animateTranslate = () => {
      let time = getHighResolutionTimeStamp() - startTime;
      //console.log("CURRENT TIME",time);
      if (time < elementSetupObject["easing"]["duration"]) {
        translateLoop(time);
        requestAnimationFrame(animateTranslate);
      } else {
        time = elementSetupObject["easing"]["duration"];
        translateLoop(time);
        onAnimationStopped("translate");
      }
    };

    let animateStyle = () => {
      let time = getHighResolutionTimeStamp() - startTime;
      if (time < elementSetupObject["easing"]["duration"]) {
        styleLoop(time);
        requestAnimationFrame(animateStyle);
      } else {
        time = elementSetupObject["easing"]["duration"];
        styleLoop(time);
        onAnimationStopped("style");
      }
    };

    let onAnimationStopped = casing => {
      console.log("Animation finished");
      if (casing === "attribute") cancelAnimationFrame(animateAttribute);
      else if (casing === "style") cancelAnimationFrame(animateStyle);
      else if (casing === "translate") cancelAnimationFrame(animateTranslate);
    };

    return object;
  };

/*  Vue.prototype.selectElement = function(element) {
    //init object here
    svgElement = element;
    configurations = {
      style: {},
      attribute: {},
      easing: {},
      transform: {}
    };
    console.log("Element id SVG ANIM PLUGIN", configurations, svgElement);

    return {
      attribute: setAttribute,
      translate: setTranslate,
      style: setStyle,
      easing: easing,
      apply: apply
    };
  };

  /!*
   * set easing and duration configurations.
   * *!/
  let easing = function(easing, duration) {
    configurations.easing["easing"] = easing;
    configurations.easing["duration"] = duration;
    return {
      apply: apply
    };
  };

  /!*
   * set target, start and change configuration values for attributes like "height, width, x, y, cx, cy, etc..."
   * *!/
  let setAttribute = function(attribute, value) {
    configurations.attribute[attribute] = {
      start: {},
      target: {},
      change: {}
    };

    let startValue = parseInt(svgElement.getAttribute(attribute));

    configurations.attribute[attribute]["target"] = value;
    configurations.attribute[attribute]["start"] = startValue;
    configurations.attribute[attribute]["change"] = value - startValue;

    return this;
  };

  let setTranslate = function(x, y) {
    isTranslateAnimation = true;
    let type = 2;
    let startX = 0;
    let startY = 0;
    let svgTranslate = svgElement.transform.baseVal;

    configurations.transform["translate"] = {
      start: {
        x: startX,
        y: startY
      },
      target: {
        x: x,
        y: y
      },
      change: {
        x: x - startX,
        y: y - startY
      }
    };

    if (svgTranslate.length !== 0) {
      for (let i = 0; i < svgTranslate.length; i++) {
        if (svgTranslate[i].type === type) {
          startX = svgTranslate[i]["matrix"]["e"];
          startY = svgTranslate[i]["matrix"]["f"];

          configurations.transform["translate"]["start"]["x"] = startX;
          configurations.transform["translate"]["start"]["y"] = startY;

          configurations.transform["translate"]["change"]["x"] =
            x - configurations.transform["translate"]["start"]["x"];
          configurations.transform["translate"]["change"]["y"] =
            y - configurations.transform["translate"]["start"]["y"];
        }
      }
    }

    console.log("Config", configurations);

    return this;
  };

  let setStyle = function(attribute_name, color) {
    styleAttributeName = attribute_name;
    isStyleAnimation = true;
    //determine if color is rgba or hex value.
    //attribute_name => fill or stroke
    let rgbaArrayStart = [];
    let rgbaArrayTarget = [];

    configurations.style[attribute_name] = {
      start: { r: 0, g: 0, b: 0, a: 0 },
      target: { r: 0, g: 0, b: 0, a: 0 },
      change: { r: 0, g: 0, b: 0, a: 0 }
    };

    let currentColor = svgElement.getAttribute(attribute_name);
    console.log("Current Color", currentColor);

    let startRgbaSearch = currentColor.search("rgba");
    let startHexSearch = currentColor.search("#");

    if (startRgbaSearch === 0) {
      rgbaArrayStart = currentColor.replace(/[^\d,.]/g, "").split(",");
    } else if (startHexSearch === 0) {
      rgbaArrayStart = convertHexToRGBA(currentColor);
    }

    let rgbaSearch = color.search("rgba");
    let hexSearch = color.search("#");

    if (rgbaSearch === 0) {
      rgbaArrayTarget = color.replace(/[^\d,.]/g, "").split(",");
    } else if (hexSearch === 0) {
      //convert to rgba first
      rgbaArrayTarget = convertHexToRGBA(color);
    }


    Object.keys(configurations.style[attribute_name]["start"]).forEach(
      (key, index) => {
        configurations.style[attribute_name]["start"][key] = parseInt(
          rgbaArrayStart[index]
        );
        configurations.style[attribute_name]["target"][key] = parseInt(
          rgbaArrayTarget[index]
        );
        configurations.style[attribute_name]["change"][key] =
          parseInt(rgbaArrayTarget[index]) - parseInt(rgbaArrayStart[index]);
      }
    );

    console.log("FULL COLOR OBJECT", configurations);

    return this;
  };

  /!*
   * set startTime and call attribute animation to start.
   * *!/
  let apply = function() {
    startTime = new Date();
    startAnimation_attribute();
  };

  let startAnimation_attribute = function() {
    let time = new Date() - startTime;
    if (time < configurations.easing["duration"]) {
      loop(time);
      if (isTranslateAnimation) {
        loopTranslate(time);
      }
      if (isStyleAnimation) {
        loopStyle(time);
      }
      requestAnimationFrame(startAnimation_attribute);
    } else {
      time = configurations.easing["duration"];
      loop(time);
      if (isTranslateAnimation) {
        loopTranslate(time);
      }
      if (isStyleAnimation) {
        loopStyle(time);
      }
      onAnimationStopped();
    }
  };

  let loop = function(time) {
    for (let i in configurations.attribute) {
      //console.log("ATTR", configurations.attribute[i]);

      configurations.attribute[i]["target"] = tweenFunctions[
        configurations.easing["easing"]
      ](
        time,
        configurations.attribute[i]["start"],
        configurations.attribute[i]["change"],
        configurations.easing["duration"]
      );

      svgElement.setAttribute(i, configurations.attribute[i]["target"]);
    }
  };

  let loopStyle = function(time) {
    for (let i in configurations.style[styleAttributeName]["target"]) {
      configurations.style[styleAttributeName]["target"][i] = tweenFunctions[
        configurations.easing["easing"]
      ](
        time,
        configurations.style[styleAttributeName]["start"][i],
        configurations.style[styleAttributeName]["change"][i],
        configurations.easing["duration"]
      );

      let r = Math.round(
        configurations.style[styleAttributeName]["target"]["r"]
      ).toString();
      let g = Math.round(
        configurations.style[styleAttributeName]["target"]["g"]
      ).toString();
      let b = Math.round(
        configurations.style[styleAttributeName]["target"]["b"]
      ).toString();
      let a = Math.round(
        configurations.style[styleAttributeName]["target"]["a"]
      ).toString();
      //console.log("RGBA",r,g,b,a);
      if (a === "255") {
        a = "1";
      }

      svgElement.setAttribute(
        styleAttributeName,
        "rgba(" + r + "," + g + "," + b + "," + a + ")"
      );
    }
  };

  let loopTranslate = function(time) {
    let baseVal = svgElement.transform.baseVal;

    let svgRoot = document.getElementsByTagName("svg")[0];
    let translate = svgRoot.createSVGTransform();

    let translateObject = { x: 0, y: 0 };

    for (let i in configurations.transform.translate["target"]) {
      //x y
      configurations.transform.translate["target"][i] = tweenFunctions[
        configurations.easing["easing"]
      ](
        time,
        configurations.transform.translate["start"][i],
        configurations.transform.translate["change"][i],
        configurations.easing["duration"]
      );

      if (i === "x") {
        translateObject.x = configurations.transform.translate["target"][i];
      } else if (i === "y") {
        translateObject.y = configurations.transform.translate["target"][i];
      }
      translate.setTranslate(translateObject.x, translateObject.y);
      if (baseVal.length > 0) {
        svgElement.transform.baseVal.replaceItem(translate, 0);
      } else {
        svgElement.transform.baseVal.appendItem(translate);
      }
    }
  };

  /!*let transformFunctions = {
    translate: function() {},
    scale: function() {}
  };*!/

  let convertHexToRGBA = function(color) {
    let decimalData = color.slice(1).match(/..?/g);
    let rgbaArray = [];

    decimalData.forEach(item => {
      rgbaArray.push(parseInt(item, 16));
    });

    return rgbaArray;
  };

  let onAnimationStopped = function() {
    cancelAnimationFrame(startAnimation_attribute);
    isTranslateAnimation = false;
    isStyleAnimation = false;
    styleAttributeName = "";
  };*/
};

export default svgAnimationPlugin;
