<template>
  <div>
    <component
      :is="componentOBJ.compName"
      :dynamicProp="componentOBJ.compProp"
    ></component>

    <div v-if="isMapLoaded" class="modemPopover">
      <modem-g-popover
        key="Maps"
        @modemEvent="modemEventReceived"
        :pre-selected="modemLayout"
        button-color="teal lighten-2"
      >
        <template v-slot:location-button="{ item }">
          <v-btn
            color="red accent-2"
            @click="goToMarker(item)"
            icon
            small
            ><v-icon>location_on</v-icon></v-btn
          >
        </template>
      </modem-g-popover>
    </div>

    <map-dialog
      @closeDialogComponent="closeDialogEvent"
      :layout-data="mapDialogProp"
      :dialog-state="dialogState"
      v-if="dialogState"
    />

    <div id="gmap" ref="gmap">
      <div v-if="googleMap !== null">
        <map-marker
          v-for="marker in modemMarker"
          :key="marker.modem_id"
          :google-maps-instance="googleMap"
          :position="marker"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModemGroupPopover from "@/components/ModemGroupWizard/ModemGroupPopover";
import MapDialog from "../components/MapChildComponents/MapDialog";
import MapMarker from "../components/MapChildComponents/MapMarker";
import OverlayComponent from "../components/DialogComponent/OverlayLoader/OverlayComponent";
import store from "@/vuex_store/store_wrapper";
import gmaputil from "@/gmaputil";

export default {
  name: "Maps",
  components: {
    "map-dialog": MapDialog,
    "map-marker": MapMarker,
    "overlay-loader": OverlayComponent,
    "modem-g-popover": ModemGroupPopover
  },
  props: {
    allModemGroups: {
      type: Object,
      required: true
    },
    selectedModemsLayout: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      googleMap: null,
      markerPosition: {},
      unsubscribe: null,
      mapDialogProp: {},
      dialogState: false,
      clickedMarkerModem: null,

      mapDialog: {},
      componentOBJ: { compName: "", compProp: null },
      modemGroupData: {},
      arrayContainsModemCoords: [],
      isDataAvailable: false,
      isWizardOpen: false,
      show_list_box: false,
      center: { lat: 39.489566, lng: 34.435589 },
      markerCoordinates: [],
      latLngInstance: null,
      modemLayout: [],
      modemMarker: [],
      isMapLoaded: false
    };
  },
  computed: {
    getModemsLayout: function() {
      return this.selectedModemsLayout;
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(mutation => {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { command, data, layout } = JSON.parse(payload.data);
        if (command === "ListOfModemLayouts") {
          if (layout === null) {
            this.noDataOnMap();
            this.modemMarker = [];
            this.modemLayout = [];
          } else {
            this.modemLayout = layout;
            this.googleMapsUtilFunction(layout);
            this.fitBoundsOfCoords(layout);
            //dirty-fix
            if (this.clickedMarkerModem !== null) {
              this.mapDialogProp = layout.filter(obj => {
                return (
                  obj["Layout"]["modem_id"] === this.clickedMarkerModem && obj
                );
              })[0]; //Consists of Data & Layout objects
              console.log("Filtered::", this.mapDialogProp);
              this.dialogState = true;

              this.componentOBJ.compName = "overlay-loader";
              this.componentOBJ.compProp = false;
              this.clickedMarkerModem = null;
            }
          }
        } else if (command === "ModemLayout") {
          this.dialogState = true;
          this.mapDialogProp = layout;
          this.componentOBJ.compName = "";
          this.componentOBJ.compProp = false;
        } else if (command === "Registration") {
          if (data !== null) {
            //We registered a modem. Request a new modem list from socket.
            this.sendMessageToWS({
              type: "getModemsLayouts",
              payload: null
            });
          }
        } else if (command === "UnRegistration") {
          if (data !== null) {
            //We unregistered a modem. Request a new modem list from socket.
            this.sendMessageToWS({
              type: "getModemsLayouts",
              payload: null
            });
          }
        }
      }
    });
  },
  async mounted() {
    try {
      const google = await gmaputil();
      //init google maps.
      this.googleMap = new google.maps.Map(document.getElementById("gmap"), {
        center: this.center,
        zoom: 8
      });

      this.googleMap && this.sendMessageToWS(this.getModemsLayout);

      this.isMapLoaded = true;
    } catch (e) {
      console.log("Error Loading GMap");
    }
  },
  methods: {
    googleMapsUtilFunction: function(layout) {
      //deconstruct data from layout and map it to marker object that contains modem_id, modem_name, enlem, boylam
      this.modemMarker = layout.map(obj => {
        let { modem_id, modem_name } = obj["Layout"];
        let { Enlem, Boylam } = obj["Data"];
        this.markerPosition = { lat: Enlem, lng: Boylam };
        return {
          modem_id,
          modem_name,
          Enlem,
          Boylam
        };
      });
      // pan to last marker position.
      this.googleMap.panTo(this.markerPosition);
    },
    markerClicked: function(modem_id) {
      //dirty-fix
      /// When we send this request we are also registering the modem
      //{"type":"getModemLayout","payload":{"ModemId":modem_id}} when clicking a marker we send a getModemLayout request with modem_id
      //but this also registers a modem and creates a duplication. To fix this right now, we are setting @param:modem_id to a new variable
      //and sending a getModemsLayout request. When we receive a response, we use clickedMarkerModem variable to filter through modem layout
      //data and consequently we are passing filtered result to MapDialog.vue component.
      console.log("MARKER CLICKED FROM CHILD", modem_id);
      console.log(this.googleMap);
      this.clickedMarkerModem = modem_id;
      let req = { type: "getModemsLayouts", payload: null };
      this.sendMessageToWS(req);

      this.componentOBJ.compName = "overlay-loader";
      this.componentOBJ.compProp = true;
    },
    fitBoundsOfCoords: function(value) {
      let bounds = new window.google.maps.LatLngBounds();
      value.forEach(item => {
        let { Enlem, Boylam } = item.Data;
        bounds.extend({
          lat: Enlem !== 0 ? Enlem : this.center.lat,
          lng: Boylam !== 0 ? Boylam : this.center.lng
        });
      });

      this.googleMap.fitBounds(bounds);
      this.googleMap.setZoom(8);
    },
    closeDialogEvent: function(val) {
      console.log("Map Dialog Event Listener", val);
      this.dialogState = val;
    },
    noDataOnMap: function() {
      console.log("No Data On Map", this.googleMap);
      console.log("REF No Data", this.$refs.gmap);
      this.googleMap.setZoom(6);
      const center = {
        lat: this.center.lat,
        lng: this.center.lng
      };
      this.googleMap.setCenter(center);
      //this.googleMap.fitBounds(bounds);
    },
    modemEventReceived: function(v) {
      console.log("Modem event Maps", v);
      const { isSelected, modem_id } = v;
      if (isSelected) {
        //register
        this.sendMessageToWS({
          type: "registerModem",
          payload: { ModemId: modem_id }
        });
      } else {
        //unregister
        this.sendMessageToWS({
          type: "unregisterModem",
          payload: { ModemId: modem_id }
        });
      }
    },
    goToMarker: function(item) {
      console.log("Go To Marker", item);
      const { enlem, boylam } = item;
      const center = {
        lat: enlem,
        lng: boylam
      };
      this.googleMap.setCenter(center);
      this.googleMap.setZoom(8);
    }
  }
};
</script>

<style scoped>
#gmap {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 64px);
}

#mb_map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.modem_group_temp {
  right: 0;
  top: 56px;
  position: fixed;
  z-index: 2;
}
</style>
