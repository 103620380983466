<template>
  <div id="base_reports" style="height: 100%">
    <reports-sheet
      @ReportAlarmListScreen="ReportAlarmListScreen"
      @ValueReportDataResponse="ValueReportDataResponse"
      @ValueReportFileResponse="ValueReportFileResponse"
      @ReportAlarmListFile="ReportAlarmListFile"
    />
    <v-overlay :value="circular_loader">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <component
      :is="dynamicReportComponentName"
      :component-data="dynamicComponentProp"
    />
  </div>
</template>

<script>
import ReportsChart from "../components/ReportsBottomPanel/ReportsComponents/ReportsChart";
import ReportsListComponent from "../components/ReportsBottomPanel/ReportsComponents/ReportsListComponent";
import ReportsAlarmListComponent from "../components/ReportsBottomPanel/ReportsComponents/ReportsAlarmListComponent";
import ReportsBottomSheetPanel from "@/components/ReportsBottomPanel/ReportsBottomSheetPanel";
import axios from "axios";

export default {
  name: "Reports",
  data() {
    return {
      circular_loader: false,
      modemGroupData: {},
      showReportsList: false,
      showReportsChart: false,
      reportsListData: {},

      dynamicReportComponentName: "",
      dynamicComponentProp: {}
    };
  },
  components: {
    ReportsChart: ReportsChart,
    ReportsAlarmListComponent: ReportsAlarmListComponent,
    ReportsListComponent: ReportsListComponent,
    "reports-sheet": ReportsBottomSheetPanel
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    ReportAlarmListScreen: function(data) {
      console.log("Reports Alarm List Received", data);
      this.dynamicReportComponentName = "ReportsAlarmListComponent";
      this.dynamicComponentProp = data;
    },
    ValueReportDataResponse: function(data) {
      const { Type } = data;
      if (Type === 0) {
        this.dynamicReportComponentName = "ReportsListComponent";
        this.dynamicComponentProp = data;
      } else if (Type === 1) {
        this.dynamicReportComponentName = "ReportsChart";
        this.dynamicComponentProp = data;
      }
    },
    getPostType: function(data) {
      return process.env.NODE_ENV === "development"
        ? axios.post(process.env.VUE_APP_LOCAL_EXCEL_ENDPOINT, {
            fileName: data
          })
        : axios.post(`${process.env.VUE_APP_BASE_URL_PORT}${data}`, {
            responseType: "blob"
          });
    },
    ValueReportFileResponse: function(data) {
      this.getPostType(data)
        .then(response => {
          console.log("RESPONSE", response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error);
        });
    },
    ReportAlarmListFile: function(data) {
      console.log("FILENAME PATH", data);
      this.getPostType(data)
        .then(response => {
          console.log("RESPONSE", response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style>
.v-progress-circular {
  margin: 1rem;
}
</style>
