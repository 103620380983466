<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].pageBackground }">
    <v-main>
      <fixed-toolbar
        :toolbarAlarmData="getToolbarAlarms"
        :toolbar-socket-data="toolbarSocketData"
        v-if="
          tokenData !== undefined && Object.keys(toolbarSocketData).length > 0
        "
      />
      <router-view :key="$route.path" />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/router";
import Dashboard from "@/main_views/Dashboard";
import Stations from "@/main_views/Stations";
import Maps from "@/main_views/Maps";
import Reports from "@/main_views/Reports";
import DynamicTableComponent from "@/components/AppbarUtilityComponents/DynamicTableComponent";
import store from "@/vuex_store/store_wrapper";
const soundEffect = require("@/assets/alert.wav")
const logo = require("@/assets/x_logo.png")
export default {
  name: "App",
  components: {},
  data() {
    return {
      toolbarSocketData: {},
      toolbarLayout: {},
      toolbarAlarms: {},
      loginState: "",
      stat: null,
      access_token: "",
      unsubscribe: null,
      canPlayAudio: false,
      audioTimeout: null,
      tokenData: null,
      notificationPermission: null,
      audioMain: null
    };
  },
  computed: {
    getToolbarLayout: {
      set: function(value) {
        this.toolbarLayout = value;
      },
      get: function() {
        return this.toolbarLayout;
      }
    },
    getToolbarAlarms: {
      set: function(value) {
        this.toolbarAlarms = value;
      },
      get: function() {
        return this.toolbarAlarms;
      }
    },
    getToolbarSocketData: {
      set: function(value) {
        this.toolbarSocketData = value;
      },
      get: function() {
        return this.toolbarSocketData;
      }
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    ...mapGetters({
      //new getters =>
      getAccessToken: "storeLoginEvents/getAccessTokenLocalStorage",
      //login ve soket ile ilgili olan getter metodları
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected",
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getDashboardAlarms: "storeSocket/getDashboardAlarms"
    })
  },
  beforeDestroy() {
    this.getToolbarSocketData = [];
    this.unsubscribe && this.unsubscribe();
    this.audioTimeout && clearTimeout(this.audioTimeout);
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);

    this.updateAccessTokenFromStorage();
    this.tokenData = this.getAccessToken;
    console.log("Created app vue", this.getAccessToken);
    console.log("Created app socket message", this.getterSocketMessage);
    //auth message response is MainLayout.
    //TODO: Change here later
    if (this.getterSocketMessage) {
      console.log("Created Socket Message", this.getterSocketMessage);
      this.setData(this.getterSocketMessage);
    }
  },
  async mounted  () {
    this.notificationPermission = await Notification.requestPermission();
    this.audioMain =  new Audio(soundEffect);
  },
  watch: {
    getterIsSocketConnected: function(val) {
      console.log("STATE:", val);
      this.stat = val;
    },
    getterSocketMessage: function(val) {
      let data = JSON.parse(val.data);
      if (data.command === "MainLayout") {
        console.log("MAIN LAYOUT", JSON.parse(val.data));
        this.setData(val);
      } else {
        console.log("DO NOTHING");
      }
    }
  },
  methods: {
    showNofitication: function ({alarm_mesaj,modem_name}) {
      if (this.notificationPermission === 'granted'){
        console.log("Permission Granted")
        new Notification(`${modem_name} Alarm 🚨`, {
          //tag: modem_id,
          body: alarm_mesaj,
          icon:logo,
          image:logo
        });

      }else {
        console.log("Permission Denied")
      }
    },
    playAudio: async function () {
      if (this.canPlayAudio && this.audioMain !== null) {
        await this.audioMain.play();
        console.log("Sound Effect is", soundEffect)
      }
      this.audioTimeout = setTimeout(() => {
        this.canPlayAudio = false;
      },5000);
    },
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_open") {
        console.log("on Socket open", this.getterSocketMessage);

      }else if (type === 'storeSocket/on_socket_message') {
        let { data, command } = JSON.parse(payload.data);
        if (command === 'SingleAlarmData') {
          this.canPlayAudio = true;
          this.playAudio();
          const {alarm_mesaj, modem_id} = data;
          this.showNofitication(data);
          let dashboardAlarms = this.getDashboardAlarms;
          //console.log("SINGLE ALARM DATA OLD ALARMS => ", dashboardAlarms);
          let newDashboardAlarms = [];
          if (dashboardAlarms !== null || dashboardAlarms) {
            const isSameAlarm =
                dashboardAlarms.filter(v => v.alarm_mesaj === alarm_mesaj && v.modem_id === modem_id).length;
            if (isSameAlarm === 0) {
              newDashboardAlarms.push(data);
              newDashboardAlarms = [...newDashboardAlarms, ...dashboardAlarms]
              //console.log("Single Alarm İs Not the same", newDashboardAlarms);
            }else {
              newDashboardAlarms = dashboardAlarms.map(v => {
                if (v.alarm_mesaj === alarm_mesaj && v.modem_id === modem_id) {

                  console.log("Same Alarm Found => ", {...v, ...data});
                  return {
                    ...v,
                    ...data
                  }
                }else {
                  return v;
                }
              })
              //console.log("Single Alarm İs the same", newDashboardAlarms);
            }
          }else {
            newDashboardAlarms.push(data);
          }

          //console.log("SINGLE ALARM DATA NEW ALARMS => ", newDashboardAlarms);
          this.getToolbarAlarms = {
            count: newDashboardAlarms.length,
            alarms:newDashboardAlarms
          }
          this.actionSetDashboardAlarms(newDashboardAlarms);
        }
      } else if (type === "storeLoginEvents/removeCookieMute" ||type === "storeLoginEvents/setAccessToken") {
        this.tokenData = payload;
      }
    },
    ...mapActions({
      updateAccessTokenFromStorage:
        "storeLoginEvents/updateAccessTokenFromStorage",
      actionSetDashboardAlarms: "storeSocket/actionSetDashboardAlarms"
    }),
    addRoutesToRouter: function(menuitems) {
      let routes = [];
      /*eslint-disable*/
      menuitems.forEach(item => {
        const { label, target, action, icon, submenus } = item;
        if (submenus === null) {
          if (target === "Dashboard") {
            const ifResolve = router.resolve({ name: label });
            console.log("ROUTER RESOLVE", ifResolve);
            ifResolve.resolved.matched.length === 0 &&
              routes.push({
                path: `/${target}`,
                name: label,
                component: Dashboard,
                props: {
                  generalManagement: [
                    { type: "alarmStatus", payload: null },
                    { type: "closedAlarms", payload: null },
                    { type: "lowAntennaList", payload: null },
                    { type: "disconnectedModemList", payload: null },
                    { type: "disconnectedCardList", payload: null }
                  ]
                }
              });
          } else if (target === "stations") {
            const ifResolve = router.resolve({ name: label });
            console.log("ROUTER RESOLVE", ifResolve);
            ifResolve.resolved.matched.length === 0 &&
              routes.push({
                path: `/${target}`,
                name: label,
                component: Stations,
                props: {
                  selectedModemsLayout: {
                    type: "getModemsLayouts",
                    payload: null
                  },
                  allModemGroups: { type: "modemCategories", payload: null }
                }
              });
          } else if (target === "Maps") {
            const ifResolve = router.resolve({ name: label });
            console.log("ROUTER RESOLVE", ifResolve);
            ifResolve.resolved.matched.length === 0 &&
              routes.push({
                path: `/${target}`,
                name: label,
                component: Maps,
                props: {
                  //allModems: {"type":"getAllModems","payload":null},
                  selectedModemsLayout: {
                    type: "getModemsLayouts",
                    payload: null
                  },
                  allModemGroups: { type: "modemCategories", payload: null }
                }
              });
          } else if (target === "Reports") {
            const ifResolve = router.resolve({ name: label });
            console.log("ROUTER RESOLVE", ifResolve);
            ifResolve.resolved.matched.length === 0 &&
              routes.push({
                path: `/${target}`,
                name: label,
                component: Reports,
                props: {
                  modemGroups: { type: "modemCategories", payload: null }
                }
              });
          } else if (target === "admin") {
            const ifResolve = router.resolve({ name: label });
            console.log("ROUTER RESOLVE", ifResolve);
            ifResolve.resolved.matched.length === 0 &&
              routes.push({
                path: `/${target}`,
                name: label,
                component: DynamicTableComponent,
                props: {
                  socketRequest: {
                    type: "object",
                    payload: {
                      Target: target,
                      Action: action,
                      Data: {
                        Onlydata: false,
                        TargetId: "DynamicTable",
                        Limit: 10,
                        Page: 1,
                        Filters: []
                      }
                    }
                  }
                }
              });
          }
        } else {
          submenus.forEach(submenu => {
            const { label, target, action } = submenu;
            const ifResolve = router.resolve({ name: label });
            console.log("ROUTER RESOLVE", ifResolve);
            ifResolve.resolved.matched.length === 0 &&
              routes.push({
                path: `/${target}`,
                name: label,
                component: DynamicTableComponent,
                props: {
                  socketRequest: {
                    type: "object",
                    payload: {
                      Target: target,
                      Action: action,
                      Data: {
                        Onlydata: false,
                        TargetId: "DynamicTable",
                        Limit: 10,
                        Page: 1,
                        Filters: []
                      }
                    }
                  }
                }
              });
          });
        }
      });
      console.log("ROUTES ARRAY CREATED", routes);
      router.addRoutes(routes);
    },
    setData: function(socketData) {
      if (JSON.parse(socketData.data).command === "MainLayout") {
        //Deconstruct MainLayout data.
        const { layout, data: alarmData } = JSON.parse(socketData.data);
        //pass alarm data
        this.getToolbarAlarms = alarmData;
        //set alarms to the store here
        /*if (alarmData.alarms !== null) {
          alarmData.alarms.map(v);
        }*/
        this.actionSetDashboardAlarms(
          alarmData.alarms !== null ? alarmData.alarms : []
        );
        const { data, target } = layout;
        const { customlogo, havetoptoolbar, leftmenu, toptbdata } = data;
        const { identifier, menuitems } = leftmenu; //menu items contains menus
        const { title, usermenu, username } = toptbdata;

        this.addRoutesToRouter(menuitems);

        //pass menu data
        this.getToolbarSocketData = {
          target,
          customlogo,
          havetoptoolbar,
          identifier,
          menuitems,
          title,
          usermenu,
          username
        };
      }
    }
  }
};
</script>

<style>
svg text {
  font-family: "Roboto Condensed", sans-serif;
}
.v-application {
  font-family: "Fira Sans", sans-serif !important;
}

.custom_alert {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
}
</style>
