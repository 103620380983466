<template>
  <div>
    <div
      v-svg-dimens="{
        svgViewboxW: Number(svgComponents['en']),
        svgViewboxH: Number(svgComponents['boy']),
        getterSVGScaleInfo
      }"
      v-html="svgHtml"
    ></div>

    <component
      v-for="item in svgComponents['comps']"
      :key="item['id']"
      :is="item['type']"
      :svgItemObject="item"
      :data-property="dataProperty"
      :parentName="parentName"
      :modemID="modemId"
    />
  </div>
</template>

<script>
import { svgContainer } from "@/custom_directives/custom_directive";
import { mapGetters } from "vuex";
import jeneratorStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/jeneratorStd";
import slideSwitch from "@/components/ModemLayoutWizard/ModemLayoutSettings/slideSwitch";
import asansorStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/asansorStd";
import katBilgiGosterge from "@/components/ModemLayoutWizard/ModemLayoutSettings/katBilgiGosterge";
import yonBilgiStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yonBilgiStd";
import yuruyenTekliStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yuruyenTekliStd";
import yuruyenIkiliStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yuruyenIkiliStd";
import pushButton from "@/components/ModemLayoutWizard/ModemLayoutSettings/pushButton";
import groupBox from "@/components/ModemLayoutWizard/ModemLayoutSettings/groupBox";
import slideSwitchOffset from "@/components/ModemLayoutWizard/ModemLayoutSettings/slideSwitchOffset";
import dijitalGosterge from "@/components/ModemLayoutWizard/ModemLayoutSettings/dijitalGosterge";
import elemanEtiket from "@/components/ModemLayoutWizard/ModemLayoutSettings/elemanEtiket";
import cardViewInputOutput from "@/components/ModemLayoutWizard/ModemLayoutSettings/CardViewIO/cardViewInputOutput";
import flatorStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/flatorStd";
import drenaj2MotorStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/drenaj2MotorStd";
import pompaEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/pompaEtiketStd";
import alarmEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/alarmEtiketStd";
import yatayEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yatayEtiketStd";
import boxEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/boxEtiketStd";
import pushButtonModern from "@/components/ModemLayoutWizard/ModemLayoutSettings/pushButtonModern";
import switchModern from "@/components/ModemLayoutWizard/ModemLayoutSettings/switchModern";
import jeneratorPanel from "@/components/ModemLayoutWizard/ModemLayoutSettings/jeneratorPanel";
export default {
  name: "ScadaTab",
  components: {
    jeneratorStd: jeneratorStd,
    slideSwitch: slideSwitch,
    slideSwitchOffset: slideSwitchOffset,
    dijitalGosterge: dijitalGosterge,
    pushButton: pushButton,
    asansorStd: asansorStd,
    katBilgiGosterge: katBilgiGosterge,
    yonBilgiStd: yonBilgiStd,
    yuruyenIkiliStd: yuruyenIkiliStd,
    yuruyenTekliStd: yuruyenTekliStd,
    groupBox: groupBox,
    elemanEtiket: elemanEtiket,
    cardViewInputOutput: cardViewInputOutput,
    flatorStd: flatorStd,
    drenaj2MotorStd: drenaj2MotorStd,
    pompaEtiketStd: pompaEtiketStd,
    alarmEtiketStd: alarmEtiketStd,
    yatayEtiketStd: yatayEtiketStd,
    boxEtiketStd: boxEtiketStd,
    pushButtonModern: pushButtonModern,
    switchModern: switchModern,
    jeneratorPanel: jeneratorPanel
  },
  directives: {
    svgContainer
  },
  props: {
    svgHtml: {
      type: String,
      required: false
    },
    svgComponents: {
      type: Object,
      required: false
    },
    dataProperty: {
      type: Object,
      required: false
    },
    parentName: String,
    modemId: Number
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
      //getDashboardAlarms: "storeSocket/getDashboardAlarms"
    })
  },
  created() {},
  beforeDestroy() {
  }
};
</script>

<style scoped></style>
