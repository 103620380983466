<template>
  <v-menu
    v-model="popoverMenu"
    :close-on-content-click="false"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="preSelected.length"
        :value="preSelected.length"
        color="red accent-2"
        overlap
        left
      >
        <v-btn medium fab :color="buttonColor" dark v-bind="attrs" v-on="on">
          <v-icon>{{ iconName }}</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
      <v-card-title>Modem Listesi</v-card-title>
      <v-card-subtitle>Seçili Modem ({{ preSelected.length }})</v-card-subtitle>
      <v-divider></v-divider>
      <v-list flat>
        <v-list-item>
          <v-list-item-content>
            <v-autocomplete
              v-model.lazy="categoryModel"
              hide-details
              label="Modem seçiniz"
              :items="modemCategories"
              item-text="group_name"
              item-value="group_id"
              @change="setModemCategory"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card
          flat
          class="mx-auto modem_container"
          min-height="300"
          max-height="300"
          height="300"
          width="300"
        >
          <v-container v-if="noDataView">
            NO DATA
          </v-container>
          <v-list-item
            class="align-center"
            v-for="item in modemList"
            :key="item.modem_id"
          >
            <template v-slot:default="{ active }">
              <v-row no-gutters class="align-center">
                <v-list-item-action>
                  <v-checkbox
                    v-model="item['isSelected']"
                    :input-value="item['isSelected']"
                    @change="selectModem(item)"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.modem_name"
                  ></v-list-item-title>
                </v-list-item-content>
                <slot
                  v-if="item['isSelected']"
                  :item="item"
                  name="location-button"
                >
                </slot>
              </v-row>
            </template>
          </v-list-item>
        </v-card>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import store from "@/vuex_store/store_wrapper";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ModemGroupPopover",
  data() {
    return {
      popoverMenu: false,
      modemCategories: [],
      categoryModel: -1,
      modemList: [],
      isFirst: false,
      selectedLayouts: [],
      isLoading: false,
      nodDataTimeoutMs: 10000,
      timeout: null,
      noDataView: false,
      unsubscribe: null
    };
  },
  props: {
    preSelected: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    iconName: {
      type: String,
      required: false,
      default: "icon-icon-modem"
    },
    buttonColor: {
      type: String,
      required: false,
      default: "indigo"
    }
  },
  computed: {
    ...mapGetters({
      getSelectedModemGroup: "storeGridEvents/getSelectedModemGroup"
    })
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.getModemCategories();
    this.unsubscribe = store.subscribe(mutation => {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { command, data } = JSON.parse(payload.data);
        if (command === "ModemGroups") {
          if (data !== null) {
            this.modemCategories = data;
            let filteredModemGroup = data.filter(v => v.group_id === this.getSelectedModemGroup);
            this.categoryModel = filteredModemGroup.length > 0 ? filteredModemGroup[0].group_id : data[0].group_id;
            if (!this.isFirst) {
              this.setModemCategory(filteredModemGroup.length > 0 ? filteredModemGroup[0].group_id : data[0].group_id);
            }

          } else {
            this.modemCategories = [];
          }
        } else if (command === "Modems") {
          this.modemList =
            data !== null
              ? data.map(d => {
                  return {
                    ...d,
                    isSelected:
                      this.preSelected !== null
                        ? this.isPreSelected(d.modem_id)
                        : false
                  };
                })
              : ([], (this.noDataView = true));
          console.log("MODEM DATA POPOVER", this.modemList);
          this.timeout && clearTimeout(this.timeout);
          this.isLoading = false;
        }
      }
    });
  },
  methods: {
    ...mapActions({
      actionSetSelectedModemGroup: "storeGridEvents/actionSetSelectedModemGroup"
    }),
    getModemCategories: function() {
      const modemCategories = {
        type: "modemCategories",
        payload: null,
        targetID: "ModemG"
      };
      this.sendMessageToWS(modemCategories);
    },
    isPreSelected: function(id) {
      return this.preSelected.some(i => {
        const { Data } = i;
        const { Id } = Data;
        return Id === id;
      });
    },
    setModemCategory: function(id) {
      this.noDataView = false;
      this.timeout = setTimeout(this.timeoutNoData, this.nodDataTimeoutMs);
      this.isLoading = true;
      this.sendMessageToWS({
        type: "modemsOnCategory",
        payload: { GrupId: id }
      });
      this.actionSetSelectedModemGroup(id);
    },
    selectModem: function(v) {
      console.log("List Group Model", v.isSelected);
      this.$emit("modemEvent", v);
    },
    timeoutNoData: function() {
      this.isLoading = false;
      this.noDataView = true;
    }
  }
};
</script>

<style scoped>
.modem_container {
  overflow-y: scroll;
  max-height: 500px;
  min-height: 100px;
}
</style>
