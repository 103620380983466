<template>
  <div>
    <v-simple-table v-if="tableData.count !== 0" class="simpleTableClass">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-left"
              v-for="(name, index) in Headers"
              :key="index"
            >
              {{ name }}
            </th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in alarmData" :key="i">
          <td>{{ item["modem_name"] }}</td>
          <td>{{ item["tarih"] }}</td>
          <td>{{ item["alarm_mesaj"] }}</td>
          <td>{{ item["kapanma_tarihi"] !== undefined ? item["kapanma_tarihi"] : '------' }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <slot v-else name="no-data-view"></slot>
  </div>
</template>

<script>
import store from "@/vuex_store/store_wrapper";

export default {
  name: "AlarmList",
  props: {
    tableData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Headers: [
        "İstasyon Adı",
        "Oluşma Zamanı",
        "Açıklama",
        "Kapanma Tarihi"
      ],
      alarmData: [],
      unsubscribe: null
    };
  },
  created () {
    //this.count
    this.alarmData = this.tableData.alarms;

    this.unsubscribe = store.subscribe(({ payload, type } )=>  {
      if (type === 'storeSocket/on_set_dashboard_alarms') {
        console.log("ALARM LIST PAYLOAD IS", payload)
        this.alarmData = payload;
      }
    })
  },
  methods: {
    setAutoSize: function () {
      this.$nextTick(() => {
        this.$parent.autoSize();
      });
    }
  }
};
</script>

<style scoped></style>
