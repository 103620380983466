<template>
  <v-dialog v-model="dialogVis" persistent max-width="600px">
    <v-progress-linear
      v-if="isLoading"
      :indeterminate="isLoading"
      color="red darken-1"
      height="10"
    ></v-progress-linear>
    <v-app-bar
      v-if="Object.keys(modalLayoutData).length > 0"
      height="auto"
      :extended="false"
      class="indigo darken-3"
    >
      <v-col class="col-12">
        <v-row no-gutters class="align-self-center justify-space-between">
          <div class="d-flex align-self-center">
            <v-toolbar-title class="pl-2 white--text"
              ><v-icon color="white">{{ getToolbarIcon }}</v-icon>
              {{ getToolbarTitle }}</v-toolbar-title
            >
          </div>
          <v-spacer></v-spacer>
          <toolbar-buttons-component
            :is-speed-dial="false"
            event-name="toolbarEventLLModal"
            :button-props="getToolbarLayout['Elements']"
            @toolbarEventLLModal="toolbarButtonClick"
          />
        </v-row>
      </v-col>
    </v-app-bar>

    <v-row no-gutters>
      <v-tabs
        v-model="tab"
        background-color="indigo darken-3 "
        class="elevation-2 modalTabsCustom"
        dark
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="(tab, i) in getToolbarTabs" :key="i" :href="`#tab-${i}`">
          {{ tab.Label }}
        </v-tab>

        <v-tab-item
          v-for="(tab_item, i) in getToolbarTabs"
          :key="i"
          :value="'tab-' + i"
        >
          <component
            :is="getActionType"
            :tab-item="tab_item"
            v-model="dynamicModel"
            :dynamic-m="dynamicModel"
            :ref="getActionType"
            :modal-data="modalDataObject"
            :master-id="masterTableRowId"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>

    <v-dialog max-width="300px" v-model="dialogSave">
      <v-card>
        <v-card-title>
          Kaydetme işlemi ?
        </v-card-title>
        <v-card-text>
          Kaydetmek istediğinize emin misiniz ?
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn text class="green--text accent-4" @click="saveForm"
            >Evet</v-btn
          >
          <v-btn text class="red--text accent-4" @click="cancelForm"
            >İptal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import yeni_linked_list from "@/components/LinkedList/forms/yeni_linked_list";
import duzenle_linked_list from "@/components/LinkedList/forms/duzenle_linked_list";
import incele_linked_list from "@/components/LinkedList/forms/incele_linked_list";
import ToolbarButtonsComponent from "@/components/AppbarUtilityComponents/ToolbarButtonsComponent";

export default {
  name: "LinkedListPopupModal",
  components: {
    yeni: yeni_linked_list,
    duzenle: duzenle_linked_list,
    incele: incele_linked_list,
    ToolbarButtonsComponent
  },
  props: {
    dialogVis: {
      type: Boolean,
      required: true,
      default: false
    },
    request: {
      type: Object,
      required: true
    },
    sourceTableName: {
      type: String,
      required: true
    },
    masterTableName: {
      type: String,
      required: true
    },
    masterTableRowId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      tab: null,

      modalLayoutData: {},
      modalDataObject: {},

      dynamicModel: {},
      dialogSave: false,
      formData: [],

      toolbarTask: "",

      isLoading: true
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    }),
    getActionType: function() {
      return this.request["payload"]["Action"];
    },

    /*modal appbar*/
    getToolbarIcon: function() {
      return this.modalLayoutData["icon"];
    },
    getToolbarTitle: function() {
      return this.modalLayoutData["title"];
    },
    getToolbarLayout: function() {
      return this.modalLayoutData["toolbar"];
    },
    getToolbarTabs: function() {
      return this.modalLayoutData["tabs"];
    }
    /*modal appbar*/
  },
  watch: {
    getterSocketMessage: function(response) {
      /*eslint-disable*/
      const { command, layout, message, data } = JSON.parse(response.data);

      if (command === "FormData" && layout["target"] === this.sourceTableName) {
        this.isLoading = false;
        console.log("Modal Response", JSON.parse(response.data));
        const { data: layout_data } = layout;
        this.modalLayoutData = layout_data;
        this.modalDataObject = data;
      }
    }
  },
  created() {
    console.log("Modal Comp Created", this.request);
    console.log("Modal Source Table => ", this.sourceTableName);
    console.log("Modal Master ID => ", this.masterTableName);
    console.log("Modal Master ROW ID => ", this.masterTableRowId);
    this.sendMessageToWS(this.request);
  },
  methods: {
    toolbarButtonClick: function(item) {
      const { Task } = item;
      this.toolbarTask = Task;
      switch (Task) {
        case "back":
          console.log("Modal Button Clicked", item);
          this.$emit("close-linked-dialog", {
            type: "Cancel",
            code: 0,
            text: ""
          });
          break;
        case "kaydet":
          console.log("Modal Button Clicked", item);
          this.savePopup(Task);
          break;
        case "guncelle":
          console.log("Duzenle Clicked");
          this.savePopup(Task);
          break;
      }
    },
    /*
     * Check if form is valid and convert model object to K,V
     * */
    savePopup: function() {
      console.log(
        "Ref",
        this.$refs[this.getActionType][0].checkValidation(),
        this.getActionType
      );
      if (this.$refs[this.getActionType][0].checkValidation()) {
        //check if task is new or edit.
        if (this.getActionType === "yeni") {
          this.formData = Object.entries(this.dynamicModel).map(
            ([key, value]) => {
              return {
                K: key,
                V: String(value)
              };
            }
          );
          //Push master id too
          this.formData.push({
            K: this.masterTableName,
            V: String(this.masterTableRowId)
          });

          this.dialogSave = true;
        } else if (this.getActionType === "duzenle") {
          //duzenle request
          this.formData = Object.entries(this.dynamicModel).map(
            ([key, value]) => {
              return {
                K: key,
                V: String(value)
              };
            }
          );
          console.log("Duzenle Model", this.formData);
          this.dialogSave = true;
        }
      }
    },
    saveForm: function() {
      const request = {
        type: "object",
        payload: {
          Target: this.sourceTableName,
          Action: this.toolbarTask,
          Data: {
            Datas: this.formData,
            TargetId: this.sourceTableName
          }
        }
      };
      console.log("Kontrol", request);
      //send request to socket
      this.sendMessageToWS(request);
      this.dialogSave = false;
    },
    cancelForm: function() {
      this.formData = [];
      this.dialogSave = false;
    }
  }
};
</script>

<style scoped></style>
