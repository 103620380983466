export var getSelectedOutputIndex = (outputArray, selectedIslem) => {
  let islemSelectedIndex = 0;
  outputArray.forEach((item, index) => {
    //islem model index 0 sadece alarm ise obje döndürüyor diğer indexler için ise sadece value
    if (selectedIslem.value === item.value || selectedIslem === item.value) {
      islemSelectedIndex = index;
    }
  });
  return islemSelectedIndex;
};

export const generateRandomKey = () => {
  let date = new Date();
  return (
    date.getFullYear().toString() +
    date.getMonth().toString() +
    date.getDate().toString() +
    date.getTime().toString()
  );
};
