<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="svgDimen"
      />
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12">
        <v-autocomplete
          v-model="currentItem['bagli_alan']"
          item-text="Text"
          item-value="Id"
          label="Bağlı Alan"
          :items="bagliAlanItems"
        />
      </v-col>
      <v-col class="col-6 px-1">
        <v-text-field
          label="Element Label"
          v-model="currentItem['elementLabel']"
          @input="changeTopLabelText"
        />
      </v-col>
      <v-col class="col-6 px-1">
        <v-text-field
          type="number"
          label="Element Font Size"
          v-model="labelTopFontSize"
          @input="changeTopLabelSize"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12">
        <v-text-field
          type="number"
          label="0/1 Değer Font Size"
          v-model="valueFontSize"
          @input="changeValueLabelSize"
        />
      </v-col>
      <v-col class="col-12">
        <v-text-field label="On Label" v-model="currentItem['onLabel']" />
      </v-col>
      <v-col class="col-12">
        <v-text-field label="Off Label" v-model="currentItem['offLabel']" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-autocomplete
        label="Animasyon Tipi (0 - 1 şeklinde düşünülmeli)"
        :items="animationTypes"
        v-model="currentItem['animation_type']"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changeSvgDimension,
  findBinaryValue
} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "flatorStd",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      bagliAlanItems: [],
      labelTopFontSize: 20,
      valueFontSize: 20,
      unsubscribe: null,
      flatorAnimValue: "0",
      // 0 - 1 şeklinde düşünülmeli
      animationTypes: ["Kırmızı-Yeşil", "Yeşil-Kırmızı"],
      onColor: "#24C263",
      offColor: "#FA5151"
    };
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    },
    getBaseGroupId: function() {
      return document
        .getElementById(this.currentItem["id"])
        .getElementsByTagName("svg")[0];
    },
    getTopLabel: function() {
      const svgBase = this.getBaseGroupId;
      return svgBase.querySelector("#flator_label");
    },
    getBottomLabel: function() {
      const svgBase = this.getBaseGroupId;
      return svgBase.querySelector("#flator_durum_text");
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    this.parentName === "ModemTabLayout" &&
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
  },
  methods: {
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          const { TargetID, Elements } = data;
          const { id } = this.currentItem;
          if (id === TargetID) {
            console.log("Modem Inputlar", data);
            this.bagliAlanItems = Object.keys(Elements).map(key => {
              return {
                Text: Elements[key],
                Id: key
              };
            });
          }
        } else if (command === "ProcessData") {
          const { ModemId, Datas } = data;
          //console.log("DATA Yon Bilgi Yukarı => ", data);
          if (ModemId === this.modemID) {
            this.setScadaData(ModemId, Datas);
          }
        }
      }
    },
    setScadaData: function(ModemId, Datas) {
      if (Object.keys(Datas).length > 0) {
        const { bagli_alan } = this.svgItemObject;
        const [tip, master, bit] = bagli_alan.split("_");
        if (Datas.hasOwnProperty(`${tip}_${master}`)) {
          this.flatorAnimValue = findBinaryValue(
            Datas[`${tip}_${master}`],
            bit
          );
          this.flatorAnim();
        }
      }
    },
    flatorAnim: function() {
      const { id,animation_type, onLabel, offLabel } = this.svgItemObject;
      switch (animation_type) {
        case "Kırmızı-Yeşil":
          this.kirmiziYesilAnim(id, onLabel, offLabel);
          break;
        case "Yeşil-Kırmızı":
          this.yesilKirmiziAnim(id, onLabel, offLabel);
          break;
      }
    },
    kirmiziYesilAnim: function(id, onLabel, offLabel) {
      const base = document.getElementById(id);
      const bottomLabel = base.querySelector("#flator_durum_text");
      const bottomLabelTspan = bottomLabel.getElementsByTagName("tspan")[0];
      const flatorIconGroup = base.querySelector("#flator_icon");
      const flatorIcon = base.querySelector("#flator_icon_p2");
      if (this.flatorAnimValue === "0") {
        //kırmızı anim //text = flatör kapalı, color = red
        bottomLabelTspan.innerHTML = offLabel;
        flatorIcon.setAttribute("style",`transition: .2s ease; fill: ${this.offColor}`);
        flatorIconGroup.setAttribute("style",`transform: rotate(15deg); transform-origin: center; transition: .2s ease;`);
      }else {
        //yesil anim //text = flatör açık, color = green
        bottomLabelTspan.innerHTML = onLabel;
        flatorIcon.setAttribute("style",`transition: .2s ease; fill: ${this.onColor}`);
        flatorIconGroup.setAttribute("style",`transform: rotate(0deg); transform-origin: center; transition: .2s ease;`);
      }
    },
    yesilKirmiziAnim: function(id, onLabel, offLabel) {
      const base = document.getElementById(id);
      const bottomLabel = base.querySelector("#flator_durum_text");
      const bottomLabelTspan = bottomLabel.getElementsByTagName("tspan")[0];
      const flatorIconGroup = base.querySelector("#flator_icon");
      const flatorIcon = base.querySelector("#flator_icon_p2");
      if (this.flatorAnimValue === "0") {
        //yesil anim //text = flatör açık, color = green
        bottomLabelTspan.innerHTML = onLabel;
        flatorIcon.setAttribute("style",`transition: .2s ease; fill: ${this.onColor}`);
        flatorIconGroup.setAttribute("style",`transform: rotate(0deg); transform-origin: center; transition: .2s ease;`);
      }else {
        //kirmizi anim //text = flatör kapalı, color = red
        bottomLabelTspan.innerHTML = offLabel;
        flatorIcon.setAttribute("style",`transition: .2s ease; fill: ${this.offColor}`);
        flatorIconGroup.setAttribute("style",`transform: rotate(15deg); transform-origin: center; transition: .2s ease;`);
      }
    },
    getBagliAlanInputs: function() {
      let getModemIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "1"
        }
      };
      this.sendMessageToWS(getModemIOList);
    },
    changeTopLabelSize: function() {
      console.log("Change Font Size", this.labelTopFontSize);
      this.getTopLabel.setAttribute("font-size", String(this.labelTopFontSize));
    },
    changeValueLabelSize: function() {
      this.getBottomLabel.setAttribute("font-size", String(this.valueFontSize));
    },
    changeTopLabelText: function(v) {
      const tspan = this.getTopLabel.getElementsByTagName("tspan")[0];
      tspan.innerHTML = v;
    }
  }
};
</script>

<style scoped></style>
