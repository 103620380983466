<template>
  <v-menu
    v-model="hiddenItemsMenu"
    :close-on-content-click="false"
    bottom
    center
    :offset-y="true"
    min-width="300"
  >
    <template v-slot:activator="{ on }">
      <v-btn text icon :color="themeOptions['iconColor']" dark v-on="on">
        <v-badge
          :content="hiddenGridItems.length"
          :value="hiddenGridItems.length"
          :overlap="true"
          color="warning"
          :left="true"
        >
          <template v-slot:badge>{{ hiddenGridItems.length }}</template>
          <v-icon :large="true">mdi-eye-settings</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card outlined :color="themeOptions['cardColor']">
      <v-list-item>
        <v-list-item-title>Gizlenen Elemanlar</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <!-- list here -->
        <v-chip-group
          class="pl-2"
          v-if="hiddenGridItems.length > 0"
          column
          active-class="primary--text"
          dark
        >
          <!-- Send Events @click -->
          <v-chip
            close
            @click:close="chipCloseEvent(item, index)"
            v-for="(item, index) in hiddenGridItems"
            :key="item['label']"
            :color="item['color']"
            text-color="white"
          >
            <v-icon left>
              {{ item["icon"] }}
            </v-icon>
            <span style="color:white">{{ item["label"] }}</span>
          </v-chip>
        </v-chip-group>
        <div
          v-text="'Henüz gizlenmiş bir eleman yok.'"
          v-else
          class="text-center ma-2 no-hidden-text"
        ></div>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="hiddenItemsMenu = false">Kapat</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "ToolbarMenuComponent",
  data() {
    return {
      hiddenItemsMenu: false,
      hiddenGridItems: []
    };
  },
  props: {
    themeOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          iconColor: "toolbarIcons",
          cardColor: "popupMenuColor"
        };
      }
    }
  },
  created() {
    //Subscribe to store actions. update hiddenGridItems array accordingly
    //add unsubscribe
    store.subscribeAction(action => {
      if (action.type === "storeGridEvents/actionHideItem") {
        const { item } = action.payload;
        this.hiddenGridItems.push(item);
      } else if (action.type === "storeGridEvents/actionShowItem") {
        const { index } = action.payload;
        this.hiddenGridItems.splice(index, 1);
      }
    });
  },
  methods: {
    ...mapActions({
      actionShowItem: "storeGridEvents/actionShowItem"
    }),
    chipCloseEvent: function(item, index) {
      console.log("Chip close ", item);
      this.actionShowItem({
        item: item,
        index: index
      });
    }
  }
};
</script>

<style scoped>
.popupMenuColor.theme--dark.v-sheet--outlined {
  border: thin solid rgba(255, 255, 255, 0.12) !important;
}
</style>
