<template>
  <div>
    <v-card flat v-for="set in componentData['Datasets']" :key="set['Legend']">
      <v-card v-if="set['ChartType'] === 'Line'" elevation="4" class="ma-2">
        <scatter-chart
          :x-labels="componentData['XLabels']"
          :y-labels="componentData['YLabels']"
          :chart-data="set"
        />
      </v-card>
      <v-card v-if="set['ChartType'] === 'Line'" elevation="4" class="ma-2">
        <line-chart :chart-data="set" />
      </v-card>
      <v-card v-if="set['ChartType'] === 'Bar'" elevation="4" class="ma-2">
        <bar-chart :chart-data="set" />
      </v-card>
    </v-card>
  </div>
</template>
<script>
import LineChart from "./ChartComponents/LineChart";
import BarChart from "./ChartComponents/BarChart";
import ScatterChart from "@/components/ReportsBottomPanel/ReportsComponents/ChartComponents/ScatterChart";

export default {
  components: {
    "bar-chart": BarChart,
    "line-chart": LineChart,
    ScatterChart
  },
  props: {
    componentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  created() {
    console.log("Chart DATASET", this.listData);
  },
  mounted() {}
};
</script>

<style scoped></style>
