<template>
  <div>
    <v-form ref="childForm" lazy-validation v-model="valid">
      <v-autocomplete
        class="body-2"
        :items="allModemsArray"
        item-text="modem_name"
        item-value="modem_id"
        v-model="modemModel"
        :label="labelAlarmModem"
        required
        outlined
        @input="modemSelect"
        :readonly="readOnly"
        :rules="ruleBinary"
      ></v-autocomplete>
      <div v-for="(item_main, main_key) in mainRulesArray" :key="main_key">
        <v-row
          v-if="modemModel !== null"
          id="kosul_grup"
          align-center
          no-gutters
          style="flex-wrap: nowrap; display: flex; align-items: center;"
        >
          <v-col class="pr-1 pl-1" cols="4" sm="4">
            <p v-if="main_key === 0" class="text-left body-2 ma-0">
              {{ single_header }}
            </p>
            <p v-else class="text-left body-2 ma-0">{{ multiple_header }}</p>
          </v-col>
          <v-col class="pr-1 pl-1" cols="4" sm="4">
            <v-select
              class="body-2"
              dense
              :items="kuralAllOr"
              item-text="text"
              item-value="value"
              label="Seçiniz"
              required
              outlined
              v-model="item_main.ia"
              :rules="ruleBinary"
              :readonly="readOnly"
            ></v-select>
          </v-col>
          <v-col class="pr-1 pl-1" cols="4" sm="4">
            <v-select
              class="body-2"
              dense
              :items="kuralTrueFalse"
              item-text="text"
              item-value="value"
              label="Seçiniz"
              required
              outlined
              v-model="item_main.it"
              :rules="ruleBinary"
              :readonly="readOnly"
            ></v-select>
          </v-col>
        </v-row>

        <div
          class="mt-4 mb-4"
          v-for="(item, childIndex) in item_main.srt"
          :key="childIndex"
        >
          <!--<p>{{ item }}</p>-->
          <v-row
            id="child_grup"
            align-center
            no-gutters
            style="flex-wrap: nowrap; display: flex; align-items: center;"
          >
            <v-col class="pr-1 pl-1 ">
              <v-select
                ref="inputRef"
                :items="inputArray"
                class="body-2"
                dense
                item-text="text"
                item-value="value"
                label="girdi"
                v-model="item['n']"
                required
                outlined
                :rules="ruleBinary"
                :readonly="readOnly"
              ></v-select>
            </v-col>
            <v-col class="pr-1 pl-1">
              <v-text-field
                class="body-2"
                dense
                type="number"
                label="değer"
                required
                outlined
                v-model="item['nv']"
                :rules="ruleBinary"
                :readonly="readOnly"
              ></v-text-field>
            </v-col>
            <v-col class="pr-1 pl-1 col-4">
              <v-select
                class="body-2"
                dense
                :items.sync="kuralDegerBuyukKucuk"
                item-text="text"
                item-value="value"
                label="şart"
                v-model="item['ns']"
                :rules="ruleBinary"
                required
                outlined
                :readonly="readOnly"
              ></v-select>
            </v-col>
            <v-btn
              :disabled="readOnly"
              class="remove_row_btn"
              md="auto"
              color="primary"
              depressed
              icon
              @click="kosulSatiriSil(childIndex, item, main_key)"
            >
              <v-icon>clear</v-icon>
            </v-btn>
          </v-row>
        </div>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              depressed
              icon
              @click="kosulSatiriEkle(main_key)"
              :disabled="readOnly"
            >
              <v-icon>add_circle</v-icon>
            </v-btn>
          </template>
          <span>Yeni koşul satırı ekle</span>
        </v-tooltip>
      </div>
      <v-row no-gutters justify="end">
        <!-- Yeni koşul grubu ekle butonu -->
        <v-btn
          :disabled="readOnly"
          class="float-right green--text darken-3 text-capitalize"
          md="auto"
          text
          normal
          @click="kosulGrubuEkle"
          >Yeni koşul grubu ekle</v-btn
        >
      </v-row>
      <v-row no-gutters justify="end">
        <v-autocomplete
          :readonly="readOnly"
          ref="islemRef"
          id="islem_selection"
          class="body-2 col-sm-6 mt-4"
          dense
          :items="outputArray"
          item-text="text"
          item-value="value"
          label="Yapılacak işlem"
          required
          outlined
          multiple
          v-model="islemModel"
          :rules="ruleBinary"
        ></v-autocomplete>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
//import { generateRandomKey } from "./alarmwiz_events";

export default {
  name: "AlarmWizard",
  props: {
    configObject: {
      type: Object,
      required: true
    },
    fieldName: {
      type: String,
      required: false
    },
    actionType: {
      type: String,
      required: false
    },
    stickyObject: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      valid: true,
      labelAlarmModem: "Alarmın kurulacağı modemi seçiniz.",
      modemModel: null,
      allModemsArray: [],
      ruleBinary: [v => !!v || "Bir seçim yapmalısınız."],
      kuralAllOr: [
        { value: "all", text: "hepsi" },
        { value: "or", text: "herhangi biri" }
      ],
      isAllModel: "",
      kuralTrueFalse: [
        { value: "true", text: "doğruysa" },
        { value: "false", text: "yanlışsa" }
      ],
      isTrueModel: "",
      kuralDegerBuyukKucuk: [
        { value: "0", text: "değerinden büyükse" },
        { value: "1", text: "değerinden büyük veya eşitse" },
        { value: "2", text: "değerine eşitse" },
        { value: "3", text: "değerine eşit değilse" },
        { value: "4", text: "değerinden küçükse" },
        { value: "5", text: "değerinden küçük veya eşitse" }
      ],
      single_header: "Eğer aşağıdaki şartlardan",
      multiple_header: "ve eğer aşağıdaki şartlardan",
      conditionGroupArray: [],
      childRulesObject: {},
      mainRulesObject: {},
      mainRulesArray: [],
      childRulesArray: [],
      inputArray: [],
      outputArray: [],
      islemModel: []
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    }),
    getAlarmConfig: function() {
      return { ...this.configObject };
    },
    getAlarmConfigKeysLen: function() {
      return Object.keys(this.getAlarmConfig).length;
    }
  },
  created() {
    this.sendMessageToWS({ type: "getAllModems", payload: null });
  },
  watch: {
    getterSocketMessage: function(value) {
      //bütün modemler
      if (JSON.parse(value.data).command === "ModemComboList") {
        this.allModemsArray = JSON.parse(value.data).data;
        if (this.actionType === "duzenle") {
          //alarm düzenleniyorsa
          this.setAlarmConfigLayout(this.getAlarmConfig);
        }
      } else if (JSON.parse(value.data).command === "ModemInputList") {
        console.log("Modem Inputs", JSON.parse(value.data).data);
        const inputObj = JSON.parse(value.data).data;
        this.inputArray = Object.entries(inputObj).map(([key, value]) => {
          return {
            value: key,
            text: value
          };
        });
      } else if (JSON.parse(value.data).command === "ModemOutputList") {
        console.log("Modem Outputs", JSON.parse(value.data).data);
        const outputObj = JSON.parse(value.data).data;
        this.outputArray = Object.entries(outputObj).map(([key, value]) => {
          return {
            value: key,
            text: value
          };
        });
      }
    }
  },
  methods: {
    setRule(item) {
      console.log("itemmm", item);
      if (item === undefined || item.value === "") {
        return [v => !!v || "Bir seçim yapmalısınız."];
      }
    },
    modemSelect: function() {
      this.resetValues();
      this.sendMessageToWS({
        type: "getModemInputs",
        payload: { ModemId: this.modemModel }
      });
      this.sendMessageToWS({
        type: "getModemOutputs",
        payload: { ModemId: this.modemModel }
      });
    },
    setAlarmConfigLayout: function(config) {
      this.mainRulesArray = [];
      console.log("config", config);
      const { modemid, rules, islem } = config;
      this.modemModel = parseInt(modemid[0]);
      this.islemModel = islem.split("@");

      this.mainRulesArray = Object.keys(rules).map(key => {
        const { ia, it, srt } = rules[key];
        return {
          mainKey: key,
          ia: ia,
          it: it,
          srt: this.convertArray(srt)
        };
      });

      this.sendMessageToWS({
        type: "getModemInputs",
        payload: { ModemId: this.modemModel }
      });
      this.sendMessageToWS({
        type: "getModemOutputs",
        payload: { ModemId: this.modemModel }
      });
    },
    convertArray: function(srt) {
      return Object.keys(srt).map(key => {
        const { n, nv, ns } = srt[key];
        return {
          childKey: key,
          n: n,
          nv: nv,
          ns: ns
        };
      });
    },
    kosulSatiriSil: function(childIndex, childItem, mainIndex) {
      this.mainRulesArray[mainIndex]["srt"].splice(childIndex, 1);
      console.log("Silindi", this.mainRulesArray);
    },
    kosulSatiriEkle: function(mainIndex) {
      this.mainRulesArray[mainIndex]["srt"].push({
        childKey: uuidv4(),
        n: "",
        nv: "",
        ns: ""
      });
    },
    kosulGrubuEkle: function() {
      this.mainRulesArray.push({
        mainKey: uuidv4(),
        ia: "",
        it: "",
        srt: []
      });
    },
    //reset alarm wizard values if modem reselected
    resetValues: function() {
      this.mainRulesArray = [];
      this.islemModel = [];
      this.mainRulesArray.push({
        mainKey: uuidv4(),
        ia: "",
        it: "",
        srt: []
      });
    },
    checkWizardValidation: function() {
      console.log("Main Rules Array", this.valid);
      console.log("CHILD FORM VALIDATE", this.$refs.childForm.validate());
      //const checkForms = val => val.validate() === true;
      return this.$refs.childForm.validate();
    },
    getWizardData: function() {
      let rules = {};
      this.mainRulesArray.forEach(main_item => {
        let { mainKey, ia, it, srt } = main_item;
        rules[mainKey] = {
          ia: ia,
          it: it,
          srt: this.createSRT(srt)
        };
      });

      let alarmWizardFinalObject = {
        modemid: [this.modemModel.toString()],
        rules: rules,
        islem: this.islemModel.join("@")
      };
      console.log("SON DATA => ", alarmWizardFinalObject);
      if (this.actionType === "duzenle") {
        this.stickyObject[this.fieldName] = JSON.stringify(
          alarmWizardFinalObject
        );
        this.stickyObject["modem_id"] = this.modemModel;
      } else {
        this.configObject[this.fieldName] = JSON.stringify(
          alarmWizardFinalObject
        );
        this.configObject["modem_id"] = this.modemModel;
      }
    },
    createSRT: function(srt) {
      let srtIO = {};
      srt.forEach(srt_item => {
        let { childKey, n, nv, ns } = srt_item;
        srtIO[childKey] = {
          n: n,
          nv: nv,
          ns: ns
        };
      });
      return srtIO;
    },
    getChildCount: function() {
      let ChildCount = 0;
      this.mainRulesArray.forEach(item => {
        if (item["srt"].length !== 0) {
          ChildCount = item["srt"].length;
        } else {
          ChildCount = 0;
        }
      });
      return ChildCount;
    }
  }
};
</script>

<style scoped>
.remove_row_btn {
  top: -14px;
}
</style>
