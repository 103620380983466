<template>
  <v-snackbar
    :color="snackbarColor"
    :timeout="timeout"
    v-model="snackbar"
    class="snackbar"
  >
    {{ snackbarMessage }}
    <v-btn color="white" text @click="snackbar = false">
      Kapat
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "CustomSnackbar",
  props: {
    dynamicProp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      timeout: 2000
    };
  },
  computed: {
    getSnackbarObject: function() {
      return { ...this.dynamicProp };
    }
  },
  watch: {
    dynamicProp(value) {
      this.snackbar = value.snackbarMDL;
      this.snackbarMessage = value.snackbarMSG;
      this.snackbarColor = value.snackbarColor;
      this.timeout = value.snackbarTimeout;
    }
  },

  created() {
    console.log("SHOW", this.dynamicProp);
    this.snackbar = this.dynamicProp.snackbarMDL;
    this.snackbarMessage = this.dynamicProp.snackbarMSG;
    this.snackbarColor = this.dynamicProp.snackbarColor;
    this.timeout = this.dynamicProp.snackbarTimeout;
  }
};
</script>

<style scoped>
.v-snack__content {
  justify-content: center !important;
}
</style>
