<template>
  <v-card flat color="gridItemBackground">
    <v-simple-table
      v-if="updatedAlarmData.length > 0"
      :dense="false"
      :fixed-header="false"
      :height="300"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Modem İsmi</th>
            <th class="text-left">Alarm Mesaj</th>
            <th class="text-left">Tarih</th>
            <th class="text-left">Kapanma Tarihi</th>
          </tr>
        </thead>
        <tbody v-if="updatedAlarmData.length > 0">
          <tr
            :class="i === 0 && 'currentAlarm'"
            v-for="(item, i) in updatedAlarmData"
            :key="i"
          >
            <td>{{ item.modem_name }}</td>
            <td>{{ item.alarm_mesaj }}</td>
            <td>{{ item.tarih }}</td>
            <td>{{ item.kapanma_tarihi }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else class="row no-gutters justify-center">
      <div class="align-center">
        <v-img
            class="my-6"
            width="100%"
          :aspect-ratio="16 / 9"
          contain
          style=" opacity: .7; "
          :src="require('@/assets/placeholders/no_alarm_placeholder_img.svg')"
        />
        <p style="font-size: 24px; font-weight: 500; opacity: .4;" class="py-4">
          Henüz oluşan bir alarm yok...
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SingleAlarm",
  props: {
    updatedAlarmData: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
@keyframes alarm_anim {
  from {
    background: #ffffff;
  }
  to {
    background: #ef3944;
  }
}

.currentAlarm {
  animation-name: alarm_anim;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: 6;
  animation-fill-mode: none;
  animation-play-state: running;
}
</style>
