<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Başlık"
        v-model="currentItem['baslik']"
        @input="changeLabel"
      />
    </v-row>
    <!-- Satır / Sütun -->
    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-text-field
          label="Sütun"
          type="number"
          v-model="currentItem['cols']"
          @input="changeColRow"
        />
      </v-col>
      <v-col class="col-6 pl-1"
        ><v-text-field
          label="Satır"
          type="number"
          v-model="currentItem['rows']"
          @input="changeColRow"
        />
      </v-col>
    </v-row>
    <!-- Satır / Sütun -->

    <!-- Arkaplan dikdörtgen renk -->
    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-menu
          ref="cpRef"
          v-model="colorMenuNormalArkaplan"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Arkaplan"
              prepend-icon="event"
              readonly
              v-on="on"
              v-model="currentItem['arkaplan_renk']"
            >
              <v-icon slot="prepend" class="oneLabelIcon">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['arkaplan_renk']"
            @input="changeNormalArkaplanColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
      <v-col class="col-6 pl-1">
        <v-menu
          ref="cpRef"
          v-model="colorMenuNormalYazi"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Arkaplan Yazı"
              prepend-icon="event"
              readonly
              v-on="on"
              v-model="currentItem['yazi_renk']"
            >
              <v-icon slot="prepend" class="oneLabelIcon">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['yazi_renk']"
            @input="changeNormalYaziColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>
    <!-- Arkaplan dikdörtgen renk -->

    <!-- LabelRenk -->
    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-menu
          ref="cpRef"
          v-model="colorMenuBaslikArkaplan"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Arkaplan"
              prepend-icon="event"
              readonly
              v-on="on"
              v-model="currentItem['baslik_arkaplan_renk']"
            >
              <v-icon slot="prepend" class="oneLabelIcon">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['baslik_arkaplan_renk']"
            @input="changeBaslikArkaplanColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
      <v-col class="col-6 pl-1">
        <v-menu
          ref="cpRef"
          v-model="colorMenuBaslikYazi"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Arkaplan Yazı"
              prepend-icon="event"
              readonly
              v-on="on"
              v-model="currentItem['baslik_yazi_renk']"
            >
              <v-icon slot="prepend" class="oneLabelIcon">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['baslik_yazi_renk']"
            @input="changeBaslikYaziColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="changeSizeOfSVG"
      />
    </v-row>

    <v-row no-gutters v-for="(item, i) in currentItem['alanlar']" :key="i">
      <v-col class="col-4 pa-1">
        <v-text-field
          label="Etiket"
          v-model="item['etiket']"
          @input="changeEtiket(i, item['etiket'])"
        />
      </v-col>
      <v-col class="col-4 pa-1">
        <v-textarea
          label="Birim"
          v-model="item['birim']"
          rows="1"
          @input="changeBirim(i, item['birim'])"
        ></v-textarea>
      </v-col>
      <v-col class="col-4 pa-1">
        <v-select
          item-text="text"
          v-model="item['field']"
          item-value="value"
          :items="ioListData"
          label="Bağlı Alan"
        />
      </v-col>
    </v-row>
    <!-- LabelRenk -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changeSvgDimension,
  getSVGChildElements,
  createTextGroup
} from "./svgComponentUtils/svgUtilFunctions";

export default {
  name: "groupBox",
  props: {
    /*currentItem: {
      type: Object,
      required: true
    },
    modemID: {
      type: Array,
      required: true
    }*/
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      ioListData: [],
      colorMenuNormalArkaplan: false,
      colorMenuNormalYazi: false,
      colorMenuBaslikArkaplan: false,
      colorMenuBaslikYazi: false
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    })
  },
  watch: {
    getterSocketMessage: function(value) {
      const {command , data} =JSON.parse(value.data);
      if (command === "ModemIOList") {
        const {TargetID} = data;
        const {id} = this.currentItem;
        if (TargetID === id) {
          this.ioListData = [];
          let dataObj = JSON.parse(value.data).data.Elements;
          this.ioListData = Object.keys(dataObj).map(key => {
            return {
              value: key,
              text: dataObj[key]
            };
          });
          console.log(this.ioListData);
        }
      }else if (command === "ProcessData") {
        const { ModemId, Datas } = data;
        if (ModemId === this.modemID && Object.keys(Datas).length > 0) {
          this.setScadaData(ModemId, Datas);
        }
      }
    }
  },
  created() {
    if (this.parentName !== "ModemTabLayout") {
      let requestIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "1@3@4@7"
        }
      };
      this.sendMessageToWS(requestIOList);
    }
  },
  mounted() {
    this.parentName === "ModemTabLayout" &&
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
  },
  methods: {
    setScadaData: function(Id, Datas) {
      console.log("Scada Ids Datas", Id);
      console.log("Scada Ids Datas", Datas);
      console.log("SVG Item Obj", this.svgItemObject);
      const { alanlar } = this.svgItemObject;

      for (const i in alanlar) {
        console.log("Alanlar", i);
        const { field } = alanlar[i];
        const [gbTip, gbMasterId] = field.split("_");
        Datas.hasOwnProperty(`${gbTip}_${gbMasterId}`) &&
          this.setGroupBoxValue(i, Datas[`${gbTip}_${gbMasterId}`]);
      }
    },
    setGroupBoxValue: function(index,data) {
      const {id} = this.svgItemObject;
      const gbGroup = document.getElementById(id);
      const gbSVG = gbGroup.getElementsByTagName('svg')[0];
      const gbNew = gbSVG.querySelector('#groupboxNew');
      const gbTextGroup = gbNew.querySelector('#groupboxTextGroup');
      const textItem = gbTextGroup.childNodes[index];
      const tspanValue = textItem.childNodes[1];
      console.log("GB TEXT", tspanValue )
      tspanValue.innerHTML = data;
    },
    changeSizeOfSVG: function() {
      changeSvgDimension(this.currentItem);
    },
    changeLabel: function() {
      const baslikText = getSVGChildElements(
        this.currentItem,
        "#gb_label_text"
      );
      const baslikBackground = getSVGChildElements(
        this.currentItem,
        "#gb_label_background"
      );
      const baslikTSpan = baslikText.querySelector("tspan");
      baslikTSpan.innerHTML = this.currentItem["baslik"];

      const { width } = baslikTSpan.getBBox();
      baslikBackground.setAttribute("width", `${width + 20}`);
    },
    changeNormalArkaplanColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      const baseRectangle = getSVGChildElements(
        this.currentItem,
        "#gb_base_background"
      );
      baseRectangle.setAttribute("fill", `#${substrColor}`);
      this.currentItem["arkaplan_renk"] = substrColor;
    },
    changeNormalYaziColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      const textGroup = getSVGChildElements(
        this.currentItem,
        "#groupboxTextGroup"
      );
      textGroup.setAttribute("fill", `#${substrColor}`);
      this.currentItem["yazi_renk"] = substrColor;
    },
    changeBaslikArkaplanColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      const baslikRect = getSVGChildElements(
        this.currentItem,
        "#gb_label_background"
      );
      baslikRect.setAttribute("fill", `#${substrColor}`);
      this.currentItem["baslik_arkaplan_renk"] = substrColor;
    },
    changeBaslikYaziColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      const baslikText = getSVGChildElements(
        this.currentItem,
        "#gb_label_text"
      );
      baslikText.setAttribute("fill", `#${substrColor}`);
      this.currentItem["baslik_yazi_renk"] = substrColor;
    },
    /*eslint-disable*/
    changeEtiket: function(index, label) {
      const textDataGroup = getSVGChildElements(
        this.currentItem,
        "#groupboxTextGroup"
      );
      const childTextNodes = textDataGroup.childNodes;
      childTextNodes[index].childNodes[0].innerHTML = label;
    },
    changeBirim: function(index, birim) {
      const textDataGroup = getSVGChildElements(
        this.currentItem,
        "#groupboxTextGroup"
      );
      const childTextNodes = textDataGroup.childNodes;
      childTextNodes[index].childNodes[2].innerHTML = birim;
    },

    changeColRow: function() {
      const satir = Number(this.currentItem["rows"]);
      const sutun = Number(this.currentItem["cols"]);
      this.currentItem["alanlar"].splice(0, this.currentItem["alanlar"].length);
      const groupElement = document.getElementById(this.currentItem["id"]);
      const svg = groupElement.querySelector("svg");
      const groupBoxBaseBackground = getSVGChildElements(
        this.currentItem,
        "#gb_base_background"
      );

      if (satir !== 0 && sutun !== 0) {
        let textGroup = getSVGChildElements(
          this.currentItem,
          "#groupboxTextGroup"
        );
        while (textGroup.firstChild) {
          textGroup.removeChild(textGroup.firstChild);
        }

        let fixedRow = 64.02;
        for (let i = 1; i <= satir; i++) {
          let fixedCol = 19;
          for (let j = 1; j <= sutun; j++) {
            const newText = createTextGroup(fixedCol, fixedRow);
            textGroup.appendChild(newText);
            this.currentItem["alanlar"].push({
              etiket: "Etiket",
              birim: "m<tspan style='baseline-shift:super'>3</tspan>",
              field: "0_1"
            });

            fixedCol = fixedCol + 138;
          }
          fixedRow = fixedRow + 37;
        }
        const { width, height } = textGroup.getBBox();
        groupBoxBaseBackground.setAttribute("width", `${width + 40}`);
        groupBoxBaseBackground.setAttribute("height", `${height + 60}`);
        svg.setAttribute("viewBox", `0 0 ${width + 40} ${height + 93}`);
        svg.setAttribute(
          "enable-background",
          `new 0 0 ${width + 40} ${height + 93}`
        );
      }
    }
  }
};
</script>

<style scoped></style>
