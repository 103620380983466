<template>
  <div>
    <i-o-list-edit-form
      v-if="showDialog"
      @dismissDialog="showDialog = false"
      :show-dialog="showDialog"
      :edited-item="editedItem"
      :bagli-alanlar="bagliAlanlar"
    />
    <i-o-list-new-form
      v-if="showNewDialog"
      @dismissNewDialog="showNewDialog = false"
      @saveNewDialog="newDialogSaved"
      :bagli-alanlar="bagliAlanlar"
      :show-dialog="showNewDialog"
      :last-item="
        ioListData.length === 0 ? 0 : ioListData[ioListData.length - 1]
      "
    />
    <v-row class="px-2 pt-4" no-gutters>
      <v-toolbar-title>Input/Output Listesi</v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="mx-2">
        <v-autocomplete
          style="max-width:200px !important;"
          hide-details
          dense
          label="Sütun"
          :items="['1', '2', '3', '4']"
          v-model="columnModel"
        />
      </div>
      <v-btn
        @click="showNewDialog = true"
        class="mx-2 text-capitalize white--text"
        color="green darken-1"
      >
        <v-icon left>add</v-icon>
        Yeni Eleman
      </v-btn>

      <v-btn class="mx-2 text-capitalize white--text" color="indigo darken-1">
        <v-icon left>add</v-icon>
        Tümünü Ekle
      </v-btn>
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12">
        <div id="base_grid" v-if="ioListData.length > 0">
          <grid-layout
            :layout="ioListData"
            :col-num="Number(columnModel)"
            autoSize
            :use-css-transforms="false"
            :vertical-compact="true"
            :row-height="16"
            :margin="[20, 20]"
          >
            <grid-item
              v-for="item in ioListData"
              v-bind:key="item.id"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :is-resizable="false"
              :id="item.id"
            >
              <v-card outlined class="py-2">
                <v-row class="align-center justify-space-between" no-gutters>
                  <v-col class="col-auto ml-2">
                    <span>{{ item.label }}</span>
                  </v-col>
                  <v-col class="col-auto">
                    <v-row class="mr-2" no-gutters>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-1"
                            @click="eventEditItem(item)"
                            v-on="on"
                            icon
                            color="indigo darken-1"
                          >
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Bu elemanı düzenle</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-1"
                            @click="eventDeleteItem(item)"
                            v-on="on"
                            icon
                            color="red darken-1"
                          >
                            <v-icon>delete_forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Bu elemanı kaldır</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </grid-item>
          </grid-layout>
        </div>
      </v-col>
    </v-row>
    <vue-json-pretty
      :highlightMouseoverNode="true"
      :showLength="true"
      :showLine="true"
      :showSelectController="true"
      :data="ioListData"
    ></vue-json-pretty>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import IOListEditForm from "@/components/EditorUtilityComponents/IOListEditForm";
import IOListNewForm from "@/components/EditorUtilityComponents/IOListNewForm";
import store from "@/vuex_store/store_wrapper";
export default {
  name: "IOListComponent",
  components: {
    IOListNewForm,
    IOListEditForm,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dynamicM: [Array, Object],
    actionType: String,
    modemID: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      ioListData: [],
      columnModel: "1",
      showDialog: false,
      showNewDialog: false,
      editedItem: {},
      unsubscribe: null,
      bagliAlanlar: []
    };
  },
  computed: {},
  watch: {
    //Bu bölümü güncelle
    ioListData: {
      deep: true,
      handler(value) {
        //console.log("IO List Data Changed", value);
        const data = {
          cols: String(this.columnModel),
          comps: value
        };
        this.dynamicM["list_config"] = encodeURIComponent(JSON.stringify(data));
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);

    const getModemIOList = {
      type: "getModemIOList",
      payload: { ModemId: parseInt(this.modemID), Secim: "1@2@3@4@5@6@7@@8" }
    };
    this.sendMessageToWS(getModemIOList);

    if (this.dynamicM["list_config"]) {
      const listConfig = JSON.parse(
        decodeURIComponent(this.dynamicM["list_config"])
      );
      console.log("IO List Config Var", listConfig);
      const { cols, comps } = listConfig;
      this.columnModel = cols;
      let xCounter = 0;
      //let yCounter = 0;
      this.ioListData = [];
      comps.forEach((v, i) => {
        console.log("X Counter", xCounter);
        this.ioListData.push({
          ...v,
          x: xCounter,
          y: 0,
          h: 2,
          w: 1,
          i
        });
        if (xCounter < Number(cols) - 1) {
          xCounter++;
        } else {
          xCounter = 0;
        }
      });
    } else {
      console.log("IO List Config Yok");
    }
  },
  methods: {
    storeHandler: function({ payload, type }) {
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          console.log("Vue Store Handler Modem IOList", data);
          this.bagliAlanlar = Object.entries(data["Elements"]).map(
            ([key, text]) => {
              return {
                Id: key,
                Text: text
              };
            }
          );
        }
      }
    },
    eventEditItem: function(item) {
      console.log("Editing Item", item);
      this.editedItem = item;
      this.showDialog = true;
    },
    eventDeleteItem: function(item) {
      console.log("Deleting Item", item);
      const { i } = item;
      this.ioListData.splice(i, 1);
      this.ioListData = this.ioListData.map((v, i) => {
        return {
          ...v,
          i
        };
      });
    },
    newDialogSaved: function(ioObject) {
      console.log("New Dialog Saved", ioObject);
      this.ioListData.push(ioObject);
      this.showNewDialog = false;
      this.reLayout();
    },
    reLayout: function() {
      let xCounter = 0;
      const comps = this.ioListData;
      this.ioListData = [];
      comps.forEach((v, i) => {
        console.log("X Counter", xCounter);
        this.ioListData.push({
          ...v,
          x: xCounter,
          y: 0,
          h: 2,
          w: 1,
          i
        });
        if (xCounter < Number(this.columnModel) - 1) {
          xCounter++;
        } else {
          xCounter = 0;
        }
      });
    }
  }
};
</script>

<style scoped>
.io-grid-item {
  background-color: #cbcbcb;
}
</style>
