const oneByOneDataset={
    valueToMod:1,
    itemWidth:12,
    itemHeight:12
};

const twoByTwoDataset = {
    valueToMod: 2,
    itemWidth: 6,
    itemHeight: 6
};

const threeByThreeDataset = {
    valueToMod: 3,
    itemWidth: 4,
    itemHeight: 4
};

const fourByFourDataset = {
    valueToMod: 4,
    itemWidth: 3,
    itemHeight: 3
};

export { oneByOneDataset, twoByTwoDataset, threeByThreeDataset,fourByFourDataset };
