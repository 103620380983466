import {
  threeByThreeDataset,
  twoByTwoDataset,
  fourByFourDataset,
  oneByOneDataset
} from "../../../gridlayout";

export var fabEvents = (buttonData, gridLayout) => {
  switch (buttonData) {
    case 1:
      gridLayout.forEach((item, index) => {
        item.x = 0;
        item.y = 0;
        item.w = oneByOneDataset.itemWidth;
        item.h = oneByOneDataset.itemHeight;

        if (index % oneByOneDataset.valueToMod === 0) {
          if (index > 0) {
            item.y = gridLayout[index - 1].y + 12;
          }
        } else {
          item.x += 12;
          item.y = gridLayout[index - 1].y;
        }
      });
      break;
    case 2:
      gridLayout.forEach((item, index) => {
        item.x = 0;
        item.y = 0;

        console.log("Two By Two", index);
        item.w = twoByTwoDataset.itemWidth;
        item.h = twoByTwoDataset.itemHeight;

        if (index % twoByTwoDataset.valueToMod === 0) {
          if (index > 0) {
            item.y = gridLayout[index - 2].y + 6;
          }
        } else {
          item.x += 6;
          item.y = gridLayout[index - 1].y;
        }
      });
      break;
    case 3:
      gridLayout.forEach((item, index) => {
        item.x = 0;
        item.y = 0;

        item.w = threeByThreeDataset.itemWidth;
        item.h = threeByThreeDataset.itemHeight;
        if (index % threeByThreeDataset.valueToMod === 0) {
          if (index > 0) {
            item.y = gridLayout[index - 3].y + 4;
          }
        } else {
          item.x = gridLayout[index - 1].x + 4;
          item.y = gridLayout[index - 1].y;
        }
      });

      break;
    case 4:
      gridLayout.forEach((item, index) => {
        item.x = 0;
        item.y = 0;

        item.w = fourByFourDataset.itemWidth;
        item.h = fourByFourDataset.itemHeight;
        if (index % fourByFourDataset.valueToMod === 0) {
          if (index > 0) {
            item.y = gridLayout[index - 4].y + 3;
          }
        } else {
          item.x = gridLayout[index - 1].x + 3;
          item.y = gridLayout[index - 1].y;
        }
      });
      break;
  }
  return gridLayout;
};
