<template>
  <v-row no-gutters>
    <template v-for="(field, f_index) in getFieldData['Fields']">
      <v-col v-columnMode="getFieldData['ColumnCount']" :key="f_index">
        <v-card class="pa-2" flat tile>
          <v-form ref="duzenleForm" lazy-validation v-model="valid">
            <div
              v-for="(field_item, f_item_index) in field"
              :key="f_item_index"
            >
              <v-text-field
                v-if="field_item['ElementType'] === 'text'"
                :label="field_item.Label"
                v-model="dynamicM[field_item.Field]"
                :rules="setRule(field_item, dynamicM[field_item.Field])"
              ></v-text-field>

              <v-autocomplete
                v-if="field_item['ElementType'] === 'combo' && field_item['Field'] !== 'modem_id'"
                :items="field_item['ComboItems']['Elements']"
                item-text="Text"
                item-value="Id"
                :label="field_item['Label']"
                :rules="setRule(field_item, dynamicM[field_item.Field])"
                :value="setSelectedField(field_item)"
                @input="inputAutoComplete(field_item, $event)"
              ></v-autocomplete>

              <v-autocomplete
                  v-if="
                  field_item['ElementType'] === 'combo' &&
                    field_item['Field'] === 'modem_id'
                "
                  :items="field_item['ComboItems']['Elements']"
                  item-text="Text"
                  item-value="Id"
                  :label="field_item['Label']"
                  :value="parseInt(masterId)"
                  readonly
                  :rules="setRule(field_item, dynamicM[field_item.Field])"
              ></v-autocomplete>

              <linked-list-table
                  v-if="field_item.ElementType === 'linkedTable'"
                  :field-item="field_item"
                  :master-table-selected-row="[dynamicM[field_item.CustomData.MasterId]]"
              />

              <erisilebilir-modemler
                  :id-property="dynamicM[field_item.Field]"
                  v-if="field_item.ElementType === 'ModemSelectList'" />

            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-responsive
        v-if="f_index === getFieldData['ColumnCount']"
        :key="`width-${f_index}`"
        width="100%"
      ></v-responsive>
    </template>
  </v-row>
</template>

<script>
import { setRuleData } from "@/form_stuff/set_form_rules";
import ModemPermissionsComp from "@/components/ErisilebilirModemler/ModemPermissionsComp";

export default {
  name: "duzenle_linked_list",
  data() {
    return {
      valid: true,
      dynamicModel: []
    };
  },
  components: {
    "erisilebilir-modemler": ModemPermissionsComp,
  },
  props: {
    tabItem: {
      type: Object,
      required: true
    },
    modalData: {
      type: Object,
      required: true
    },
    masterId: {
      required: false,
      type: [Array,String]
    },
    dynamicM: [Array, Object]
  },
  computed: {
    /*
     * tabItem return. Fieldları içeriyor.
     * */
    getFieldData: function() {
      return this.tabItem;
    },
    /*
     * modalData return. Fieldlar dataları içeriyor. düzenle ve incele için kullanılacak.
     * Yeni kısmında bağlı bir field varsa(hidden fieldlar gibi) o zaman kullanılacak.
     * */
    getModalData: function() {
      return this.modalData;
    }
  },
  mounted() {
    console.log("dynamic m", this.dynamicM);
  },
  created() {
    console.log("modalll", this.getModalData);
    Object.keys(this.getModalData).forEach(item => {
      this.dynamicM[item] = this.getModalData[item];
    });
  },
  methods: {
    setRule: function(v,fV) {
      return setRuleData(v,fV);
    },
    inputAutoComplete: function(item, data) {
      console.log("INPUT ", item);
      console.log("EV ", data);
      this.dynamicM[item.Field] = data;
      console.log(this.dynamicModel);
    },
    setSelectedField: function(item) {
      console.log("VALUE MODEL", item);
      return parseInt(this.dynamicM[item.Field]);
    },
    checkValidation: function() {
      const checkForms = val => val.validate() === true;
      console.log("Check", this.$refs.duzenleForm.every(checkForms));
      if (this.$refs.duzenleForm.every(checkForms)) {
        //{K: "", V: "" } oluştur.
        console.log("form vaaliddddd");

        return true;
      } else {
        console.log("Form  valid değil.");

        return false;
      }
    }
  }
};
</script>

<style scoped>
.duzenle_form {
  z-index: 0;
}
</style>
