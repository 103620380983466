<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-card-title class="pl-0 py-0">Etiket ayarları</v-card-title>
      <v-col class="col-12">
        <v-text-field
          label="Genişlik"
          v-model="currentItem['genislik']"
          @input="svgDimen"
        />
      </v-col>
      <v-col class="col-12">
        <v-text-field
          @input="changeCardLabel"
          label="Etiket"
          v-model="currentItem['cardLabel']"
        />
      </v-col>
      <v-col class="col-6">
        <color-picker
          label="Arkaplan On Renk"
          :color-data="currentItem['colorsOn']['colorCardBase']"
          coloring-type="fill"
          :field="{
            parent: 'colorsOn',
            child: 'colorCardBase',
            domObject: 'cardBaseRectangle'
          }"
          @colorSelected="colorSelected"
        />
      </v-col>
      <v-col class="col-6">
        <color-picker
          label="Arkaplan Off Renk"
          :color-data="currentItem['colorsOff']['colorCardBase']"
          coloring-type="fill"
          :field="{
            parent: 'colorsOff',
            child: 'colorCardBase'
          }"
          @colorSelected="colorSelected"
        />
      </v-col>
      <v-col class="col-12">
        <v-row no-gutters>
          <v-col class="col-6">
            <color-picker
              label="Etiket On Renk"
              :color-data="currentItem['colorsOn']['colorLabel']"
              coloring-type="fill"
              :field="{
                parent: 'colorsOn',
                child: 'colorLabel',
                domObject: 'cardLabel'
              }"
              @colorSelected="colorSelected"
            />
          </v-col>
          <v-col class="col-6">
            <color-picker
              label="Etiket Off Renk"
              :color-data="currentItem['colorsOff']['colorLabel']"
              :field="{
                parent: 'colorsOff',
                child: 'colorLabel'
              }"
              @colorSelected="colorSelected"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div>
      <v-row no-gutters>
        <v-card-title class="pl-0 py-0 col-12">Input ayarları</v-card-title>
      </v-row>
      <v-row no-gutters>
        <v-col class="col-12">
          <v-autocomplete
            hide-details
            label="İkon seçiniz"
            :items="iconList"
            item-value="path"
            item-text="key"
            v-model="iconModel"
            :auto-select-first="true"
            @change="selectIcon"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="col-6">
          <color-picker
            label="İkon On Renk"
            :color-data="currentItem['colorsOn']['colorInputIcon']"
            :field="{
              parent: 'colorsOn',
              child: 'colorInputIcon',
              domObject: 'cardIconGroup'
            }"
            @colorSelected="colorSelected"
          />
        </v-col>
        <v-col class="col-6">
          <color-picker
            label="İkon Off Renk"
            :color-data="currentItem['colorsOff']['colorInputIcon']"
            :field="{
              parent: 'colorsOff',
              child: 'colorInputIcon'
            }"
            @colorSelected="colorSelected"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-select
          :items="bagliAlanItems"
          label="Baglı Input Alanı"
          v-model="currentItem['input_bagli_alan']"
          item-text="Text"
          item-value="Id"
        />
      </v-row>
    </div>

    <div>
      <v-row no-gutters>
        <v-card-title class="pl-0 py-0 col-12">Output Ayarları</v-card-title>
      </v-row>
      <v-row no-gutters>
        <v-col class="col-6 px-1">
          <v-text-field label="1 Yazı" v-model="currentItem['outputTextOn']" />
        </v-col>
        <v-col class="col-6 px-1">
          <v-text-field label="0 Yazı" v-model="currentItem['outputTextOff']" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="col-6">
          <color-picker
            label="1 Yazı Renk"
            :color-data="currentItem['colorsOn']['colorOutputText']"
            :field="{
              parent: 'colorsOn',
              child: 'colorOutputText',
              domObject: 'cardOutputText'
            }"
            @colorSelected="colorSelected"
          />
        </v-col>
        <v-col class="col-6">
          <color-picker
            label="0 Yazı Renk"
            :color-data="currentItem['colorsOff']['colorOutputText']"
            :field="{
              parent: 'colorsOff',
              child: 'colorOutputText'
            }"
            @colorSelected="colorSelected"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-select
          :items="bagliAlanItems"
          label="Baglı Output Alanı"
          v-model="currentItem['output_bagli_alan']"
          item-text="Text"
          item-value="Id"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/ModemLayoutWizard/ModemLayoutSettings/CardViewIO/ColorPicker";
import {
  changeSvgDimension,
  ioCardIconPaths,
  findBinaryValue
} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import { mapGetters } from "vuex";

export default {
  name: "cardViewInputOutput",
  components: {
    "color-picker": ColorPicker
  },
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object,
    ioTimeout: null,
  },
  data() {
    return {
      svgDOMElements: {},
      iconList: {},
      iconModel: "aydinlatma",
      bagliAlanItems: [],
      inputValue: "0",
      outputValue: "0",
      isOutputClicked: false
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    },
    nudgeOn: function() {
      return `transition: all .5s cubic-bezier(.29,.83,.33,.83);
      fill: ${this.svgItemObject["colorsOn"]["colorSwitchNudge"]};
      transform: translateX(0)`;
    },
    nudgeOff: function() {
      return `transition: all .5s cubic-bezier(.29,.83,.33,.83);
      fill: ${this.svgItemObject["colorsOff"]["colorSwitchNudge"]};
      transform: translateX(-29px)`;
    }
  },
  watch: {
    getterSocketMessage: function(response) {
      const { command, data } = JSON.parse(response.data);
      if (command === "ModemIOList") {
        const { TargetID, Elements } = data;
        const { id } = this.currentItem;
        if (id === TargetID) {
          console.log("Modem Inputlar", data);
          this.bagliAlanItems = Object.keys(Elements).map(key => {
            return {
              Text: Elements[key],
              Id: key
            };
          });
        }
      } else if (command === "ProcessData") {
        console.log("DATA Card View => ", this.svgItemObject);
        const { ModemId, Datas } = data;
        if (ModemId === this.modemID) {
          this.setScadaData(ModemId, Datas);
        }
      }
    }
  },
  beforeDestroy () {
    this.ioTimeout && clearTimeout(this.ioTimeout);
  },
  created() {
    if (this.parentName !== "ModemTabLayout") {
      this.iconList = ioCardIconPaths.getIcons();
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    this.svgDOMElements = this.registerSVGObjects();
    this.parentName === "ModemTabLayout" &&
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
  },
  methods: {
    animationStrings: function() {
      return {
        nudgeOn: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                  fill: ${this.svgItemObject["colorsOn"]["colorSwitchNudge"]};
                   transform: translateX(0)`,
        nudgeOff: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                  fill: ${this.svgItemObject["colorsOff"]["colorSwitchNudge"]};
                   transform: translateX(-29px)`,
        labelOn: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                  fill: ${this.svgItemObject["colorsOn"]["colorLabel"]}`,
        labelOff: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                    fill: ${this.svgItemObject["colorsOff"]["colorLabel"]}`,
        iconOn: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                 fill: ${this.svgItemObject["colorsOn"]["colorInputIcon"]}`,
        iconOff: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                  fill: ${this.svgItemObject["colorsOff"]["colorInputIcon"]}`,
        baseOn: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                 fill: ${this.svgItemObject["colorsOn"]["colorCardBase"]}`,
        baseOff: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                  fill: ${this.svgItemObject["colorsOff"]["colorCardBase"]}`,
        switchBaseOn: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                       fill: ${this.svgItemObject["colorsOn"]["colorSwitchBase"]};
                       stroke: ${this.svgItemObject["colorsOn"]["colorSwitchNudge"]}`,
        switchBaseOff: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                       fill: ${this.svgItemObject["colorsOff"]["colorSwitchBase"]};
                       stroke: ${this.svgItemObject["colorsOff"]["colorSwitchNudge"]}`,
        outputTextOn: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                        fill: ${this.svgItemObject["colorsOn"]["colorOutputText"]};`,
        outputTextOff: `transition: all .5s cubic-bezier(.29,.83,.33,.83);
                        fill: ${this.svgItemObject["colorsOff"]["colorOutputText"]};`
      };
    },
    setScadaData: function(ModemId, Datas) {
      if (Object.keys(Datas).length > 0) {
        const { input_bagli_alan, output_bagli_alan } = this.svgItemObject;
        const [i_tip, i_master, i_bit] = input_bagli_alan.split("_");
        const [o_tip, o_master, o_bit] = output_bagli_alan.split("_");
        if (Datas.hasOwnProperty(`${i_tip}_${i_master}`)) {
          this.inputValue = findBinaryValue(
            Datas[`${i_tip}_${i_master}`],
            i_bit
          );
          this.inputAnim();
        }

        if (Datas.hasOwnProperty(`${o_tip}_${o_master}`)) {
          this.outputValue = findBinaryValue(
            Datas[`${i_tip}_${i_master}`],
            o_bit
          );
          this.isOutputClicked = false;
          this.ioTimeout && clearTimeout(this.ioTimeout);
          this.outputAnim();
        }
      }
    },
    inputAnim: function() {
      if (this.inputValue === "0") {
        this.setInputOff();
      } else {
        this.setInputOn();
      }
    },
    outputAnim: function() {
      if (this.outputValue === "0") {
        this.setOutputOff();
      } else {
        this.setOutputOn();
      }
    },
    setInputOn: function () {
      const {
        cardLabel,
        cardIconGroup,
        cardBaseRectangle
      } = this.svgDOMElements;
      cardLabel.setAttribute("style", this.animationStrings()["labelOn"]);
      cardIconGroup.setAttribute("style", this.animationStrings()["iconOn"]);
      cardBaseRectangle.setAttribute(
          "style",
          this.animationStrings()["baseOn"]
      );
    },
    setInputOff: function () {
      const {
        cardLabel,
        cardIconGroup,
        cardBaseRectangle
      } = this.svgDOMElements;
      cardLabel.setAttribute("style", this.animationStrings()["labelOff"]);
      cardIconGroup.setAttribute("style", this.animationStrings()["iconOff"]);
      cardBaseRectangle.setAttribute(
          "style",
          this.animationStrings()["baseOff"]
      );
    },
    setOutputOn: function() {
      const { outputTextOn } = this.svgItemObject;
      const { switchBase, switchNudge, cardOutputText } = this.svgDOMElements;
      const tspan = cardOutputText.getElementsByTagName("tspan")[0];
      tspan.innerHTML = outputTextOn;
      switchBase.setAttribute("style", this.animationStrings()["switchBaseOn"]);
      switchNudge.setAttribute("style", this.animationStrings()["nudgeOn"]);
      cardOutputText.setAttribute(
          "style",
          this.animationStrings()["outputTextOn"]
      );
    },
    setOutputOff: function() {
      const { outputTextOff } = this.svgItemObject;
      const { switchBase, switchNudge, cardOutputText } = this.svgDOMElements;
      const tspan = cardOutputText.getElementsByTagName("tspan")[0];
      tspan.innerHTML = outputTextOff;
      switchBase.setAttribute("style", this.animationStrings()["switchBaseOff"]);
      switchNudge.setAttribute("style", this.animationStrings()["nudgeOff"]);
      cardOutputText.setAttribute(
          "style",
          this.animationStrings()["outputTextOff"]
      );
    },
    getBagliAlanInputs: function() {
      let getModemIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "1@2@3@4"
        }
      };
      this.sendMessageToWS(getModemIOList);
    },
    colorSelected: function(param) {
      console.log("Color Received", param);
      const { value, field, type } = param;
      if (field.hasOwnProperty("domObject")) {
        if (field.hasOwnProperty("parent")) {
          // if parent exists then it's nested object
          const { parent, child } = field;
          this.currentItem[parent][child] = value;
        } else {
          // if parent does not exist then it's just key-value pair
          const { key } = field;
          this.currentItem[key] = value;
        }
        console.log("Card Label", field["domObject"]);
        this.svgDOMElements[field["domObject"]].setAttribute(type, `${value}`);
      } else {
        console.log("Dom Object değişmeyecek");
        if (field.hasOwnProperty("parent")) {
          // if parent exists then it's nested object
          const { parent, child } = field;
          this.currentItem[parent][child] = value;
        } else {
          // if parent does not exist then it's just key-value pair
          const { key } = field;
          this.currentItem[key] = value;
        }
      }
    },
    registerSVGObjects: function() {
      const { id } =
        this.parentName === "ModemTabLayout"
          ? this.svgItemObject
          : this.currentItem;
      const svgBase = document.getElementById(id);
      // Card Wrap Group
      const cardWrapGroup = svgBase.querySelector("#card_input_output_view");

      const cardBaseG = cardWrapGroup.querySelector("#card_base_rectangle_g");
      const cardBaseRectangle = cardBaseG.querySelector("#card_base_rectangle");

      const cardIOGroup = cardWrapGroup.querySelector("#card_group");
      const cardLabel = cardIOGroup.querySelector("#card_label");
      const cardIconGroup = cardIOGroup.querySelector("#card_input_icon");
      const vectorIcon = cardIconGroup.getElementsByTagName("path")[0];

      const cardOutputGroup = cardIOGroup.querySelector("#card_output_part");
      const cardOutputSwitch = cardOutputGroup.querySelector(
        "#card_output_switch"
      );
      const switchNudge = cardOutputSwitch.querySelector("#card_switch_nudge");
      const switchBase = cardOutputSwitch.querySelector("#card_switch_base");
      const switchWaitBase = cardOutputSwitch.querySelector(
        "#card_switch_wait_base"
      );
      this.parentName === "ModemTabLayout" &&
        cardOutputSwitch.addEventListener("click", this.switchClicked);

      const cardOutputText = cardOutputGroup.querySelector("#card_output_text");

      return {
        cardOutputText,
        cardOutputSwitch,
        cardLabel,
        cardIconGroup,
        cardBaseRectangle,
        switchBase,
        switchNudge,
        switchWaitBase,
        vectorIcon
      };
    },
    changeCardLabel: function() {
      const { cardLabel } = this.svgDOMElements;
      const tspan = cardLabel.getElementsByTagName("tspan")[0];
      tspan.innerHTML = this.currentItem["cardLabel"];
    },
    selectIcon: function(icon) {
      console.log("Icon Selected", icon);
      const { vectorIcon } = this.svgDOMElements;
      vectorIcon.setAttribute("d", icon);
    },
    switchClicked: function() {
      if (!this.isOutputClicked) {
        this.isOutputClicked = true;
        console.log("Switch Clicked");
        const { outputTimeout, output_bagli_alan } = this.svgItemObject;
        const [tip,master_id,bit] = output_bagli_alan.split('_');
        const { switchWaitBase } = this.svgDOMElements;
        switchWaitBase.setAttribute(
            "style",
            "transition: .5s ease; opacity: 0.5;"
        );
        const setOutput = {
          type: "setModemOutput",
          payload: {
            ModemId: this.modemID,
            Target: `${tip}_${master_id}_${bit}`,
            Value: this.outputValue === "1" ? 0 : 1
          }
        };
        this.sendMessageToWS(setOutput);
        this.ioTimeout = setTimeout(this.switchTimeout, Number(outputTimeout));
      }
    },
    switchTimeout: function() {
      if (this.outputValue === '0') {
        this.setOutputOff();
      }else {
        this.setOutputOn();
      }
      if (this.inputValue === '0') {
        this.setInputOff();
      }else {
        this.setInputOn();
      }

      const { switchWaitBase } = this.svgDOMElements;
      switchWaitBase.setAttribute("style", "transition: .5s ease; opacity: 0;");
    }
  }
};
</script>

<style scoped></style>
