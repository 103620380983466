<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="svgDimen"
      />
    </v-row>
    <v-row no-gutters>
      <v-autocomplete
        label="Bağlı Alan"
        v-model="currentItem['bagli_alan']"
        :items="bagliAlanItems"
        item-text="Text"
        item-value="Id"
        clearable
      />
    </v-row>
    <v-row no-gutters>
      <v-col class="col-4">
        <v-text-field
          label="Etiket Yazı"
          v-model="currentItem['etiketText']"
          @input="changeLabel"
        />
      </v-col>
      <v-col class="col-4 px-2">
        <v-text-field
          label="Etiket Yazı Boyutu"
          v-model="currentItem['etiketFontSize']"
          @input="changeLabelFontSize"
        />
      </v-col>
      <v-col class="col-4">
        <v-menu
          ref="cpRef"
          v-model="colorMenuEtiketRenk"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Etiket Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['etiketColor']"
            >
              <v-icon :color="`#${currentItem['etiketColor']}`" slot="prepend">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['etiketColor']"
            @input="changeEtiketColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-4">
        <v-menu
          ref="cpRef"
          v-model="colorMenuBaseRenk"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Arkaplan Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['baseArkaplan']"
            >
              <v-icon :color="`#${currentItem['baseArkaplan']}`" slot="prepend">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['baseArkaplan']"
            @input="changeArkaplanColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
      <v-col class="col-4">
        <v-menu
          ref="cpRef"
          v-model="colorMenuBaseStrokeRenk"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Arkaplan Çerçeve Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['baseCerceve']"
            >
              <v-icon :color="`#${currentItem['baseCerceve']}`" slot="prepend">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['baseCerceve']"
            @input="changeArkaplanStrokeColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
      <v-col class="col-4">
        <v-menu
          ref="cpRef"
          v-model="colorMenuValueRenk"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Değer Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['degerTextColor']"
            >
              <v-icon
                :color="`#${currentItem['degerTextColor']}`"
                slot="prepend"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['degerTextColor']"
            @input="changeValueColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-4">
        <v-text-field
          label="Birim"
          v-model="currentItem['birimText']"
          @input="changeBirimText"
        />
      </v-col>
      <v-col class="col-4 px-2">
        <v-text-field
          label="Birim Yazı Boyutu"
          v-model="currentItem['birimFontSize']"
          @input="changeBirimFontSize"
        />
      </v-col>
      <v-col class="col-4">
        <v-menu
          ref="cpRef"
          v-model="colorMenuBirimRenk"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Birim Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['birimTextColor']"
            >
              <v-icon
                :color="`#${currentItem['birimTextColor']}`"
                slot="prepend"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['birimTextColor']"
            @input="changeBirimColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { changeSvgDimension } from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "dijitalGosterge",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      bagliAlanItems: [],
      svgDOMElements: {},
      colorMenuEtiketRenk: false,
      colorMenuBaseRenk: false,
      colorMenuBaseStrokeRenk: false,
      colorMenuValueRenk: false,
      colorMenuBirimRenk: false,
      dijitalGostergeValue: "0",
      unsubscribe: null
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected",
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    this.registerSVGObject();
    this.parentName === "ModemTabLayout" &&
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
  },
  methods: {
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          const { TargetID, Elements } = data;
          const { id } = this.currentItem;
          if (id === TargetID) {
            console.log("Modem Inputlar", data);
            this.bagliAlanItems = Object.keys(Elements).map(key => {
              return {
                Text: Elements[key],
                Id: key
              };
            });
          }
        } else if (command === "ProcessData") {
          const { ModemId, Datas } = data;
          //console.log("DATA Pompa Etiket => ", data);
          if (ModemId === this.modemID) {
            this.setScadaData(ModemId, Datas);
          }
        }
      }
    },
    setScadaData: function(ModemId, Datas) {
      if (Object.keys(Datas).length > 0) {
        const { bagli_alan } = this.svgItemObject;
        const [tip, master, birim] = bagli_alan.split("_");
        //type 8 => modbus control. Data key is tip_masterid_. _ at the end.
        if (tip === "8") {
          if (Datas.hasOwnProperty(`${tip}_${master}_`)) {
            this.dijitalGostergeValue = Datas[`${tip}_${master}_`];
          }
        } else {
          if (Datas.hasOwnProperty(`${tip}_${master}`)) {
            this.dijitalGostergeValue = Datas[`${tip}_${master}`];
          } else if (Datas.hasOwnProperty(`${tip}_${master}_${birim}`)) {
            this.dijitalGostergeValue = Datas[`${tip}_${master}_${birim}`];
          }
        }
        this.setDijitalGostere();
      }
    },
    setDijitalGostere: function() {
      const { gostergeText } = this.svgDOMElements;
      const tspan = gostergeText.getElementsByTagName("tspan")[0];
      tspan.innerHTML = this.dijitalGostergeValue;
    },
    registerSVGObject: function() {
      const { id } =
        this.parentName === "ModemTabLayout"
          ? this.svgItemObject
          : this.currentItem;
      const svgBase = document.getElementById(id);
      const svgG = svgBase.querySelector("#digital_gosterge_tekli");
      const gostergeBaseRect = svgG.querySelector("#digital_gosterge_base");
      const gostergeBirimText = svgG.querySelector("#digital_gosterge_birim");
      const gostergeText = svgG.querySelector("#digital_gosterge_text");
      const gostergeEtiket = svgG.querySelector("#digital_gosterge_etiket");
      this.svgDOMElements = {
        gostergeBaseRect,
        gostergeBirimText,
        gostergeText,
        gostergeEtiket
      };
    },
    changeLabel: function() {
      const { gostergeEtiket } = this.svgDOMElements;
      const tspan = gostergeEtiket.getElementsByTagName("tspan")[0];
      tspan.innerHTML = this.currentItem["etiketText"];
    },
    changeLabelFontSize: function() {
      const { gostergeEtiket } = this.svgDOMElements;
      gostergeEtiket.setAttribute(
        "font-size",
        this.currentItem["etiketFontSize"]
      );
    },
    changeEtiketColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["etiketColor"] = substrColor;
      const { gostergeEtiket } = this.svgDOMElements;
      gostergeEtiket.setAttribute("fill", `#${substrColor}`);
    },
    changeArkaplanColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["baseArkaplan"] = substrColor;
      const { gostergeBaseRect } = this.svgDOMElements;
      gostergeBaseRect.setAttribute("fill", `#${substrColor}`);
    },
    changeArkaplanStrokeColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["baseCerceve"] = substrColor;
      const { gostergeBaseRect } = this.svgDOMElements;
      gostergeBaseRect.setAttribute("stroke", `#${substrColor}`);
    },
    changeValueColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["degerTextColor"] = substrColor;
      const { gostergeText } = this.svgDOMElements;
      gostergeText.setAttribute("fill", `#${substrColor}`);
    },
    changeBirimColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["birimTextColor"] = substrColor;
      const { gostergeBirimText } = this.svgDOMElements;
      gostergeBirimText.setAttribute("fill", `#${substrColor}`);
    },
    changeBirimText: function() {
      const { gostergeBirimText } = this.svgDOMElements;
      const tspan = gostergeBirimText.getElementsByTagName("tspan")[0];
      tspan.innerHTML = this.currentItem["birimText"];
    },
    changeBirimFontSize: function() {
      const { gostergeBirimText } = this.svgDOMElements;
      gostergeBirimText.setAttribute(
        "font-size",
        this.currentItem["birimFontSize"]
      );
    },
    getBagliAlanInputs: function() {
      let requestIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "3@4@5@6@7@8"
        }
      };
      this.sendMessageToWS(requestIOList);
    }
  }
};
</script>

<style scoped></style>
