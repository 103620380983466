<template>
  <v-row class="justify-center" no-gutters>
    <v-col class="col-6">
      <v-btn
          block
          style="font-size: 12px !important;"
        color="primary"
        @click="sendOutputPushButton"
        :disabled="loadingState"
        :loading="loadingState"
        >{{ pbData["label"] }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "pushButton",
  data() {
    return {
      loadingState: false,
      pushButtonStatus: "",
      pbTimeout: null,
      setOutput: {
        type: "setModemOutput",
        payload: { Target: "", Value: 0, ModemId: 0 }
      }
    };
  },
  props: {
    pbData: {
      type: Object,
      required: true
    },
    modemId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  watch: {
    getterSocketMessage: function(value) {
      const { command, data } = JSON.parse(value.data);
      if (command === "ProcessData") {
        this.updateData(data);
      }
    }
  },
  beforeDestroy() {
    this.pbTimeout && clearTimeout(this.pbTimeout);
  },
  created() {},
  methods: {
    updateData: function(data) {
      const { Datas, ModemId } = data;
      if (ModemId === this.modemId) {
        const { tip, master_id } = this.pbData;
        if (Datas.hasOwnProperty(`${tip}_${master_id}`)) {
          console.log(
            "UPDATED PUSHBUTTON DATA HAS PROPERTY",
            ModemId,
            this.modemId
          );
          this.pbTimeout && clearTimeout(this.pbTimeout);
          this.loadingState = false;
          this.pushButtonStatus = "";
        }
      }
    },
    funTimeout: function() {
      this.pushButtonStatus = "";
      this.loadingState = false;
    },
    sendOutputPushButton: function() {
      const { bit, master_id, tip, birim } = this.pbData;
      this.pushButtonStatus = "Gönderiliyor...";
      this.loadingState = true;
      this.setOutput["payload"]["Value"] = 1;
      this.setOutput["payload"]["ModemId"] = this.modemId;
      this.setOutput["payload"]["Target"] = `${tip}_${master_id}_${bit}`;
      this.sendMessageToWS(this.setOutput);
      console.log("Push Button Timeout", this.setOutput);
      this.pbTimeout = setTimeout(this.funTimeout, Number(birim) * 1000);
    }
  }
};
</script>

<style scoped></style>
