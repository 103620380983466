<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="svgDimen"
      />
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12">
        <v-autocomplete
          v-model="currentItem['seviye_bagli_alan']"
          item-text="Text"
          item-value="Id"
          label="Bağlı Alan"
          :items="bagliAlanItems"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changeSvgDimension,
} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "drenaj2MotorStd",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      bagliAlanItems: [],
      unsubscribe: null,
      drenajSeviyeValue: "0"
    };
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    },
    getBaseGroupId: function() {
      return document
        .getElementById(this.currentItem["id"])
        .getElementsByTagName("svg")[0];
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    this.parentName === "ModemTabLayout" &&
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
  },
  methods: {
    getBagliAlanInputs: function() {
      let getModemIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "3"
        }
      };
      this.sendMessageToWS(getModemIOList);
    },
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          const { TargetID, Elements } = data;
          const { id } = this.currentItem;
          if (id === TargetID) {
            console.log("Modem Inputlar", data);
            this.bagliAlanItems = Object.keys(Elements).map(key => {
              return {
                Text: Elements[key],
                Id: key
              };
            });
          }
        } else if (command === "ProcessData") {
          const { ModemId, Datas } = data;
          //console.log("DATA Yon Bilgi Yukarı => ", data);
          if (ModemId === this.modemID) {
            this.setScadaData(ModemId, Datas);
          }
        }
      }
    },
    setScadaData: function(ModemId, Datas) {
      if (Object.keys(Datas).length > 0) {
        const { seviye_bagli_alan } = this.svgItemObject;
        const [tip, master, birim] = seviye_bagli_alan.split("_");
        if (Datas.hasOwnProperty(`${tip}_${master}`)) {
          //console.log(`Drenaj Object Birimsiz: ${tip}_${master}`);
          this.drenajSeviyeValue = Datas[`${tip}_${master}`];
          this.drenajAnim();
        }else if (Datas.hasOwnProperty(`${tip}_${master}_${birim}`)) {
          //console.log(`Drenaj Object Birimli: ${tip}_${master}_${birim}`);
          this.drenajSeviyeValue = Datas[`${tip}_${master}_${birim}`];
          this.drenajAnim();
        }
      }
    },
    drenajAnim: function () {
      const {id} = this.svgItemObject;
      const svg_base = document.getElementById(id);
      const su_seviye = svg_base.querySelector("#drenaj_kuyu_seviye");
      const gosterge_yukseklik = svg_base.querySelector("#drenaj_seviye_rect");
      const gosterge_yukseklik_arrow = svg_base.querySelector("#drenaj_seviye_arrow");
      const maxH = 576;
      const defH = 100;
      const v = Number(this.drenajSeviyeValue) * maxH;
      su_seviye.setAttribute("style",`height: ${v}px; transform: translateY(${defH - v}px) ;transition: .5s ease;`);
      gosterge_yukseklik.setAttribute("style",`height: ${maxH - v}px; transition: .5s ease;`);
      gosterge_yukseklik_arrow.setAttribute("style",`transform: translateY(${defH - v + 10}px); transition: .5s ease;`);
    }
  }
};
</script>

<style scoped></style>
