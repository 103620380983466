<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Kapat
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <custom-dialog-alert
      v-if="formDialogProp.dialogModel"
      @dismiss="dismiss"
      @save="save"
      event-negative="dismiss"
      event-positive="save"
      :dynamic-prop="formDialogProp"
    />
    <form-toolbar
      @toolbarEvent="toolbarEvent"
      :form-toolbar="getToolbar"
      :icon-and-title="getIconAndTitle"
    />
    <v-row no-gutters>
      <v-tabs
        show-arrows
        v-model="tab"
        background-color="indigo darken-3 "
        class="elevation-2"
        dark
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          v-for="(tab, i) in getTabs"
          :key="`key_${i}}`"
          :href="`#tab${i}`"
        >
          {{ tab.Label === "" ? getIconAndTitle.title : tab.Label }}
        </v-tab>

        <v-tab-item
          v-for="(tab, i) in getTabs"
          :key="`tab_item_${tab['Label']}`"
          :value="`tab${i}`"
        >
          <v-row no-gutters>
            <duzenle-form
              :sticky-data="formData"
              :ref="`form_tab`"
              :label="tab['Label']"
              :fields="tab"
            />
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import CustomDialogAlert from "@/components/DialogComponent/CustomDialogAlert";
import FormToolbar from "@/components/AppbarUtilityComponents/Forms/FormToolbar";
import DuzenleForm from "@/components/AppbarUtilityComponents/Forms/DuzenleForm";

export default {
  name: "duzenle",
  components: {
    CustomDialogAlert,
    FormToolbar,
    DuzenleForm
  },
  props: {
    formLayout: {
      type: Object,
      required: false
    },
    formData: {
      type: Object,
      required: false
    },
    socketRequest: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      overlay: false,
      tab: null,
      formDialogProp: {
        dialogModel: false,
        dialogTitle: "Kayıt işlemi",
        dialogMessage: "Bu kaydı silmek istediğinize emin misiniz",
        textPButton: "Evet",
        textNButton: "İptal",
        customRequest: "",
        from: "FormYeni"
      },
      validFormData: {},
      snackbarMessage: "",
      snackbar: false
    };
  },
  computed: {
    getTarget: function() {
      return this.formLayout.target;
    },
    getToolbar: function() {
      return this.formLayout.data.toolbar;
    },
    getIconAndTitle: function() {
      return {
        icon: this.formLayout.data.icon,
        title: this.formLayout.data.title
      };
    },
    getTabs: function() {
      return this.formLayout.data.tabs;
    }
  },
  beforeDestroy() {
    console.log("BEFORE DESTROY Duzenle FORM");
  },
  created() {
    console.log("Socket Request Form", this.socketRequest);
    console.log("Created Sticku", this.formData["data_labels"]);
    if (this.formData.hasOwnProperty("data_labels")) {
      if (this.formData["data_labels"] !== "") {
        console.log("Here", this.formData["data_labels"]);
        this.formData["data_labels"] = JSON.parse(
          decodeURIComponent(this.formData["data_labels"])
        );
      } else {
        this.formData["data_labels"] = {};
      }
    }
  },
  methods: {
    /*
     * Events that received from FormToolbar.
     * payload is describing the type of action that we are gonna take.
     * Such as if payload is 'back' then go back etc...
     * */
    toolbarEvent: function(payload) {
      console.log("Toolbar Event", payload);
      console.log("Refs", this.$refs, this.tab);
      const refName = `form_${this.tab}`;
      console.log("Ref name", refName);

      if (payload === "back") {
        this.$emit("backPressed");
      } else {
        /*
         * Call validateForm function of the form component
         * */
        /*
         * This is important. Sometimes we have columns of forms,
         *  so we need to validate each form using every function
         * */
        const isAllFormsValid = this.$refs["form_tab"].every(item => {
          console.log("Validation", item.validateForm());
          return item.validateForm();
        });
        console.log("Is All Forms Valid", isAllFormsValid);
        // Every form is valid & process data
        if (isAllFormsValid) {
          let allFormData = {};
          // Call getFormValues function in each form component
          // and add them to an object using spread operator
          this.$refs["form_tab"].forEach(item => {
            //console.log("FORM VALUES,", item.getFormValues());
            allFormData = { ...item.getFormValues(), ...allFormData };
          });
          // Convert object to K,V format
          this.validFormData = Object.entries(allFormData).map(
            ([key, value]) => {
              return {
                K: key,
                V: value.toString()
              };
            }
          );

          //Show dialog.
          console.log("Form Values gathered", this.validFormData);
          this.formDialogProp.dialogMessage = `${this.getIconAndTitle.title} bilgilerini kaydetmek istediğinize emin misiniz ?`;
          this.formDialogProp.customRequest = "";
          this.formDialogProp.dialogModel = true;
        }
      }
    },
    formValid: function(values) {
      console.log("FORM IS VALID AND VALUES", values);
      this.validFormData = Object.entries(values).map(([key, value]) => {
        return {
          K: key,
          V: value.toString()
        };
      });
      console.log("K V TYPE", this.validFormData);
      this.formDialogProp.dialogMessage = `${this.getIconAndTitle.title} bilgilerini kaydetmek istediğinize emin misiniz ?`;
      this.formDialogProp.customRequest = "";
      this.formDialogProp.dialogModel = true;
    },
    /*
     * CustomAlert Dialog Form Save Dialog events, dismiss & save
     * */
    dismiss: function() {
      this.formDialogProp.dialogModel = false;
    },
    save: function(v) {
      console.log("Save Dialog", v);
      const { Data, Target } = this.socketRequest;
      const { TargetId } = Data;
      const request = {
        type: "object",
        payload: {
          Target,
          Action: "guncelle",
          Data: {
            Datas: this.validFormData,
            TargetId
          }
        }
      };
      console.log("Request", JSON.stringify(request));
      this.sendMessageToWS(request);
      this.overlay = true;
    }
  }
};
</script>

<style scoped></style>
