const state = {
  itemObject_Resized: {},
  currentRoute: {},
  drawerState: false,
  selectedModemGroup: -1,
  gridType: "DYNAMIC"
};

const getters = {
  getItemObject_Resized: state => {
    return state.itemObject_Resized;
  },
  getCurrentRoute: state => {
    return state.currentRoute;
  },
  getDrawerState: state => {
    return state.drawerState;
  },
  getSelectedModemGroup: state => {
    return state.selectedModemGroup;
  },
  getGridType: state => {
    return state.gridType;
  }
};

const mutations = {
  setItemObject_Resized: (state, obj) => {
    state.itemObject_Resized = obj;
  },
  setCurrentRoute: (state, data) => {
    state.currentRoute = data;
  },
  setDrawerState: (state, v) => {
    state.drawerState = v;
  },
  setSelectedModemGroup: (state, v) => {
    state.selectedModemGroup = v;
  }
};

const actions = {
  actionSetItemObject_Resized({ commit }, obj) {
    commit("setItemObject_Resized", obj);
  },

  //Add mutations to this actions #######
  actionHideItem(commit, obj) {
    console.log("Hide item called in store", obj);
  },

  actionShowItem(commit, obj) {
    console.log("Show item called in store", obj);
  },
  //Add mutations to this actions #######

  actionSetCurrentRoute({ commit }, route) {
    console.log("Current route is ", route);
    commit("setCurrentRoute", route);
  },

  actionSetDrawerState({ commit }, state) {
    console.log("Action Received Drawer", state);
    commit("setDrawerState", state);
  },
  actionSetSelectedModemGroup({ commit }, state) {
    commit("setSelectedModemGroup", state);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
