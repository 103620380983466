<template>
  <v-layout justify-center>
    <v-dialog
      v-model="dialogState"
      fullscreen
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card class="mx-auto" tile>
        <modem-tab-layout
          @mapDialogStatus="mapDialogStatusEvent"
          :hasCloseButton="true"
          :propData="getModemData"
          :propLayout="getModemLayout"
          :modemName="getModemLayout.modem_name"
          :genislik_w="12"
          :modem-data="getLayoutDataProp"
        />
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModemTabLayout from "../GridComponent/ModemGridNew/ModemTabLayout";

export default {
  name: "MapDialog",
  components: {
    "modem-tab-layout": ModemTabLayout
  },
  props: {
    layoutData: {
      type: Object,
      required: true
    },
    dialogState: Boolean
  },
  data() {
    return {
      unsubscribe: null,
      dialog_status: false,
      tab: null
    };
  },
  computed: {
    getLayoutDataProp: function() {
      const { Data, Layout } = this.layoutData;
      const { Guncelleme } = Data;
      const { listsetup, modem_id, modem_name } = Layout;
      return {
        x: 0,
        y: 0,
        h: 12,
        w: 12,
        i: modem_id,
        modem_id: modem_id,
        modem_name: modem_name,
        son_guncelleme: Guncelleme,
        list_setup: listsetup,
        Layout: Layout,
        Data: Data
      };
    },

    getModemData: function() {
      return this.layoutData.Data;
    },
    getModemLayout: function() {
      return this.layoutData.Layout;
    },
    getGridListSetup: function() {
      return JSON.parse(decodeURIComponent(this.layoutData.Layout.listsetup));
    },
    getModemInfoText: function() {
      return this.layoutData.Layout["modemyazi"];
    }
  },
  created() {
    console.log("map dialog çalıştı", this.getLayoutDataProp);
  },
  methods: {
    mapDialogStatusEvent: function(val) {
      this.$emit("closeDialogComponent", val);
    }
  }
};
</script>

<style scoped>
.chip_date {
  position: absolute !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.photo_btn {
  position: fixed !important;
  bottom: 0 !important;
  left: 2% !important;
  transform: translateX(-50%) !important;
}

@media screen and (max-width: 960px) {
  .photo_btn {
    left: 10px !important;
  }
}
</style>
