<template>
  <v-bottom-sheet persistent max-width="550" hide-overlay v-model="bottomSheet">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip left>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            fab
            fixed
            right
            bottom
            color="red accent-3"
            large
            dark
            v-bind="attrs"
            v-on="{ ...on, ...onTooltip }"
          >
            <v-icon>addchart</v-icon>
          </v-btn>
        </template>
        <span>{{
          bottomSheet
            ? "Raporlama Sihirbazını kapat"
            : "Raporlama Sihirbazını aç"
        }}</span>
      </v-tooltip>
    </template>
    <v-sheet elevation="0" class="text-center">
      <v-app-bar elevation="0" flat rounded>
        <v-icon class="mr-2">auto_fix_high</v-icon
        ><v-toolbar-title>Raporlama Sihirbazı</v-toolbar-title>
        <v-spacer />
        <v-btn @click="bottomSheet = !bottomSheet" icon fab
          ><v-icon>close</v-icon></v-btn
        >
      </v-app-bar>

      <v-tabs
        hide-slider
        v-model="tab"
        background-color="#e25b5b"
        centered
        dark
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#olcum-tab">
          <v-icon class="pr-1">mdi-chart-areaspline</v-icon> Ölçüm Raporları
        </v-tab>

        <v-tab href="#alarm-tab">
          <v-icon class="pr-1">table_chart</v-icon> Alarm Raporları
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="olcum-tab" value="olcum-tab">
          <v-card flat>
            <OlcumRaporlarPanel
              :deger-array="degerArray"
              :stations-array="stationArray"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item key="alarm-tab" value="alarm-tab">
          <v-card flat>
            <AlarmRaporlarPanel :stations-array="stationArray" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import AlarmRaporlarPanel from "@/components/ReportsBottomPanel/PanelComponents/AlarmRaporlarPanel";
import OlcumRaporlarPanel from "@/components/ReportsBottomPanel/PanelComponents/OlcumRaporlarPanel";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "ReportsBottomSheetPanel",
  components: {
    AlarmRaporlarPanel,
    OlcumRaporlarPanel
  },
  props: {},
  data() {
    return {
      tab: null,
      bottomSheet: true,
      unsubscribe: null,
      stationArray: [],
      degerArray: []
    };
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(mutation => {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { command, data } = JSON.parse(payload.data);
        if (command === "ReportAlarmListScreen") {
          //Alarm List Event Called from AlarmRaporlar Panel
          this.$emit("ReportAlarmListScreen", data);
          this.bottomSheet = false;
        } else if (command === "ReportAlarmListFile") {
          //Alarm File Event
          this.$emit("ReportAlarmListFile", data);
          this.bottomSheet = false;
        } else if (command === "ValueReportDataResponse") {
          // Olcum Reports Table or Chart Response
          this.$emit("ValueReportDataResponse", data);
          this.bottomSheet = false;
        } else if (command === "ValueReportFileResponse") {
          // Olcum Reports File Response
          this.$emit("ValueReportFileResponse", data);
          this.bottomSheet = false;
        } else if (command === "ModemListOfUser") {
          const { Elements } = data;
          this.stationArray = Elements;
          console.log("istasyonlar Store Bottom Sheet", this.stationArray);
        } else if (command === "ReportModemValueFields") {
          console.log("STORE OLCUM PANEL", data);
          this.degerArray = Object.entries(data).map(([key, value]) => {
            return {
              Text: value,
              Id: key
            };
          });
        }
      } else if (type === "storeGridEvents/setDrawerState") {
        console.log("Bottom Sheet D", payload);
        this.bottomSheet = !payload;
      }
    });
    this.sendMessageToWS({ type: "getModemListOfUser", payload: null });
  }
};
</script>

<style scoped></style>
