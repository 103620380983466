<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="svgDimen"
      />
    </v-row>

    <v-row no-gutters>
      <v-autocomplete
        label="Animasyon Tip"
        :items="animationTypes"
        v-model="currentItem['animation_type']"
      />
    </v-row>

    <v-row no-gutters>
      <v-autocomplete
        label="Kabin Kapı Bağlı Alan"
        :items="bagliAlanItems"
        item-value="Id"
        item-text="Text"
        v-model="currentItem['kabin_kapi_bagli_alan']"
        clearable
      >
      </v-autocomplete>
    </v-row>
  </div>
</template>

<script>
import {
  changeSvgDimension,
  findBinaryValue
} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import { mapGetters } from "vuex";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "asansorStd",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      bagliAlanItems: [],
      kabinKapiValue: "0",
      animationTypes: ["Kapalı-Açık", "Açık-Kapalı"],
      unsubscribe: null
    };
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    this.parentName === "ModemTabLayout" &&
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
  },
  methods: {
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          const { TargetID, Elements } = data;
          const { id } = this.currentItem;
          if (id === TargetID) {
            console.log("Modem Inputlar", data);
            this.bagliAlanItems = Object.keys(Elements).map(key => {
              return {
                Text: Elements[key],
                Id: key
              };
            });
          }
        } else if (command === "ProcessData") {
          const { ModemId, Datas } = data;
          //console.log("DATA Asansör => ", data);
          if (ModemId === this.modemID) {
            this.setScadaData(ModemId, Datas);
          }
        }
      }
    },
    setScadaData: function(ModemId, Datas) {
      if (Object.keys(Datas).length > 0) {
        const { kabin_kapi_bagli_alan } = this.svgItemObject;
        const [tip, master, bit] = kabin_kapi_bagli_alan.split("_");
        if (Datas.hasOwnProperty(`${tip}_${master}`)) {
          //console.log("Asansör Kabin Datas", Datas[`${tip}_${master}`] === "");
          //console.log("Asansör Kabin Tip MasterId Bit", tip, master, bit);

          if (Datas[`${tip}_${master}`] === "") {
            this.closeDoors();
            return;
          }

          this.kabinKapiValue = findBinaryValue(Datas[`${tip}_${master}`], bit);
          console.log("Asansör Kabin Value", this.kabinKapiValue);
          this.kapiAnim();
        }
      }
    },
    kapiAnim: function () {
      const { id, animation_type } = this.svgItemObject;
      switch (animation_type) {
        case "Kapalı-Açık":
          this.kapaliAcikAnim(id);
          break;
        case "Açık-Kapalı":
          this.acikKapaliAnim(id);
          break;
      }
    },
    kapaliAcikAnim: function (id) {
      const svgGroup = document.getElementById(`${id}`);
      const asansorTekGroup = svgGroup.querySelector("#asansor_tek_std");
      const kapiWrapG = asansorTekGroup.querySelector("#asansor_tek");

      const kapiSag = kapiWrapG.querySelector("#kapi_sag");
      const kapiSagRect = kapiSag.getElementsByTagName("rect")[0];
      const kapiSagStroke = kapiSag.getElementsByTagName("rect")[1];

      const kapiSol = kapiWrapG.querySelector("#kapi_sol");
      const kapiSolRect = kapiSol.getElementsByTagName("rect")[0];
      const kapiSolStroke = kapiSol.getElementsByTagName("rect")[1];

      const defs = svgGroup.getElementsByTagName("defs")[0];
      const kapiFilterSag = defs.getElementsByTagName("filter")[0];
      const kapiFilterSol = defs.getElementsByTagName("filter")[1];

      kapiFilterSag.setAttribute("width", "200");
      kapiFilterSag.setAttribute("x", "265.430");
      kapiFilterSol.setAttribute("width", "194.73");

      if (this.kabinKapiValue === "0") {
        //kapalı
        const kapiSagCSS = `width:194.73px;transform:translateX(-194.73px);transition:1.2s ease-in-out`;
        const kapiSolCSS = `width:194.73px;transition:1.2s ease-in-out`;

        kapiSagRect.setAttribute("style", kapiSagCSS);
        kapiSagStroke.setAttribute("style", kapiSagCSS);
        /*kapiFilterSag.setAttribute("width", "200");
        kapiFilterSag.setAttribute("x", "265.430");*/

        kapiSolRect.setAttribute("style", kapiSolCSS);
        kapiSolStroke.setAttribute("style", kapiSolCSS);
        /*kapiFilterSol.setAttribute("width", "194.73");*/
      }else if (this.kabinKapiValue === "1") {
        //açık
        const kapiSagCSS = `width:1px;transform:translateX(0px);transition:1.2s ease-in-out`;
        const kapiSolCSS = `width:1px;transition:1.2s ease-in-out`;

        kapiSagRect.setAttribute("style", kapiSagCSS);
        kapiSagStroke.setAttribute("style", kapiSagCSS);
        /*kapiFilterSag.setAttribute("width", "1");
        kapiFilterSag.setAttribute("x", "462.167");*/

        kapiSolRect.setAttribute("style", kapiSolCSS);
        kapiSolStroke.setAttribute("style", kapiSolCSS);
        /*kapiFilterSol.setAttribute("width", "1");*/
      }

    },
    acikKapaliAnim: function (id) {
      const svgGroup = document.getElementById(`${id}`);
      const asansorTekGroup = svgGroup.querySelector("#asansor_tek_std");
      const kapiWrapG = asansorTekGroup.querySelector("#asansor_tek");

      const kapiSag = kapiWrapG.querySelector("#kapi_sag");
      const kapiSagRect = kapiSag.getElementsByTagName("rect")[0];
      const kapiSagStroke = kapiSag.getElementsByTagName("rect")[1];

      const kapiSol = kapiWrapG.querySelector("#kapi_sol");
      const kapiSolRect = kapiSol.getElementsByTagName("rect")[0];
      const kapiSolStroke = kapiSol.getElementsByTagName("rect")[1];

      const defs = svgGroup.getElementsByTagName("defs")[0];
      const kapiFilterSag = defs.getElementsByTagName("filter")[0];
      const kapiFilterSol = defs.getElementsByTagName("filter")[1];

      kapiFilterSag.setAttribute("width", "200");
      kapiFilterSag.setAttribute("x", "265.430");
      kapiFilterSol.setAttribute("width", "194.73");


      if (this.kabinKapiValue === "0") {
        //açık
        const kapiSagCSS = `width:1px;transform:translateX(0px);transition:1.2s ease-in-out`;
        const kapiSolCSS = `width:1px;transition:1.2s ease-in-out`;

        kapiSagRect.setAttribute("style", kapiSagCSS);
        kapiSagStroke.setAttribute("style", kapiSagCSS);
        /*kapiFilterSag.setAttribute("width", "1");
        kapiFilterSag.setAttribute("x", "462.167");*/

        kapiSolRect.setAttribute("style", kapiSolCSS);
        kapiSolStroke.setAttribute("style", kapiSolCSS);
        /*kapiFilterSol.setAttribute("width", "1");*/
      }else if (this.kabinKapiValue === "1") {
        //kapalı
        const kapiSagCSS = `width:194.73px;transform:translateX(-194.73px);transition:1.2s ease-in-out`;
        const kapiSolCSS = `width:194.73px;transition:1.2s ease-in-out`;

        kapiSagRect.setAttribute("style", kapiSagCSS);
        kapiSagStroke.setAttribute("style", kapiSagCSS);
        /*kapiFilterSag.setAttribute("width", "200");
        kapiFilterSag.setAttribute("x", "265.430");*/

        kapiSolRect.setAttribute("style", kapiSolCSS);
        kapiSolStroke.setAttribute("style", kapiSolCSS);
        /*kapiFilterSol.setAttribute("width", "194.73");*/
      }

    },
    closeDoors: function () {
      const { id } = this.svgItemObject;
      const svgGroup = document.getElementById(`${id}`);
      const asansorTekGroup = svgGroup.querySelector("#asansor_tek_std");
      const kapiWrapG = asansorTekGroup.querySelector("#asansor_tek");

      const kapiSag = kapiWrapG.querySelector("#kapi_sag");
      const kapiSagRect = kapiSag.getElementsByTagName("rect")[0];
      const kapiSagStroke = kapiSag.getElementsByTagName("rect")[1];

      const kapiSol = kapiWrapG.querySelector("#kapi_sol");
      const kapiSolRect = kapiSol.getElementsByTagName("rect")[0];
      const kapiSolStroke = kapiSol.getElementsByTagName("rect")[1];

      const defs = svgGroup.getElementsByTagName("defs")[0];
      const kapiFilterSag = defs.getElementsByTagName("filter")[0];
      const kapiFilterSol = defs.getElementsByTagName("filter")[1];

      kapiFilterSag.setAttribute("width", "200");
      kapiFilterSag.setAttribute("x", "265.430");
      kapiFilterSol.setAttribute("width", "194.73");

      const kapiSagCSS = `width:194.73px;transform:translateX(-194.73px);transition:1.2s ease-in-out`;
      const kapiSolCSS = `width:194.73px;transition:1.2s ease-in-out`;

      kapiSagRect.setAttribute("style", kapiSagCSS);
      kapiSagStroke.setAttribute("style", kapiSagCSS);

      kapiSolRect.setAttribute("style", kapiSolCSS);
      kapiSolStroke.setAttribute("style", kapiSolCSS);
    },
/*    kapiAnim: function() {
      const { id } = this.svgItemObject;
      const svgGroup = document.getElementById(`${id}`);
      const asansorTekGroup = svgGroup.querySelector("#asansor_tek_std");
      const kapiWrapG = asansorTekGroup.querySelector("#asansor_tek");

      const kapiSag = kapiWrapG.querySelector("#kapi_sag");
      const kapiSagRect = kapiSag.getElementsByTagName("rect")[0];
      const kapiSagStroke = kapiSag.getElementsByTagName("rect")[1];

      const kapiSol = kapiWrapG.querySelector("#kapi_sol");
      const kapiSolRect = kapiSol.getElementsByTagName("rect")[0];
      const kapiSolStroke = kapiSol.getElementsByTagName("rect")[1];

      const defs = svgGroup.getElementsByTagName("defs")[0];
      const kapiFilterSag = defs.getElementsByTagName("filter")[0];
      const kapiFilterSol = defs.getElementsByTagName("filter")[1];

      kapiFilterSag.setAttribute("width", "200");
      kapiFilterSag.setAttribute("x", "265.430");
      kapiFilterSol.setAttribute("width", "194.73");

      if (this.asansorKapiValue === "1") {
        const kapiSagCSS = `width:194.73px;transform:translateX(-194.73px);transition:1.2s ease-in-out`;
        const kapiSolCSS = `width:194.73px;transition:1.2s ease-in-out`;

        kapiSagRect.setAttribute("style", kapiSagCSS);
        kapiSagStroke.setAttribute("style", kapiSagCSS);
        /!*kapiFilterSag.setAttribute("width", "200");
        kapiFilterSag.setAttribute("x", "265.430");*!/

        kapiSolRect.setAttribute("style", kapiSolCSS);
        kapiSolStroke.setAttribute("style", kapiSolCSS);
        /!*kapiFilterSol.setAttribute("width", "194.73");*!/
      } else {
        const kapiSagCSS = `width:1px;transform:translateX(0px);transition:1.2s ease-in-out`;
        const kapiSolCSS = `width:1px;transition:1.2s ease-in-out`;

        kapiSagRect.setAttribute("style", kapiSagCSS);
        kapiSagStroke.setAttribute("style", kapiSagCSS);
        /!*kapiFilterSag.setAttribute("width", "1");
        kapiFilterSag.setAttribute("x", "462.167");*!/

        kapiSolRect.setAttribute("style", kapiSolCSS);
        kapiSolStroke.setAttribute("style", kapiSolCSS);
        /!*kapiFilterSol.setAttribute("width", "1");*!/
      }
    },*/
    getBagliAlanInputs: function() {
      let getModemIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "1"
        }
      };
      this.sendMessageToWS(getModemIOList);
    }
  }
};
</script>

<style scoped></style>
