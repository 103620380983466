import axios from "axios";
const svgNamespace = "http://www.w3.org/2000/svg";

const ioCardIconPaths = {
  getIcons: () => {
    return [
      {
        key: "aydinlatma",
        path:
          "M134.556 42.88V32H127.444V42.88C111.444 44.6578 99 58.2044 99 74.6311C99 78.5778 102.2 81.7778" +
          " 106.147 81.7778H116.778C116.778 89.6356 123.142 96 131 96C138.858 96 145.222 89.6356 145.222" +
          " 81.7778H155.853C159.8 81.7778 163 78.5778 163 74.6311C163 58.2044 150.556 44.6578 134.556 42.88ZM131" +
          " 74.6667H106.111C106.111 60.9422 117.276 49.7778 131 49.7778C144.724 49.7778 155.889 60.9422 155.889 74.6667H131Z"
      },
      {
        key: "build",
        path:
          "M162.162 84.3106L135.729 57.7172C138.343 50.9958 136.891 43.1054 131.372 37.5529C125.562 31.7082 116.848 30.5393 109.876 33.7539L122.367 46.32L113.653 55.0871L100.872 42.5209C97.386 49.5346 98.8384 58.3016 104.648 64.1463C110.167 69.6988 118.01 71.16 124.691 68.5299L151.124 95.1233C152.286 96.2922 154.029 96.2922 155.19 95.1233L161.871 88.4019C163.324 87.2329 163.324 85.1873 162.162 84.3106Z"
      }
    ];
  }
};

const getSvgPropertyData = {
  jeneratorPanel: id => {
    return {
      id,
      type: "jeneratorPanel",
      genislik: "400",
      orginal_genislik: 1000,
      auto_ready: "",
      alarm: "",
      warning: "",
      service_request: "",
      fuel: "",
      alarm_list: "",
      test: "",
      run: "",
      auto: "",
      off: "",
      durum: 54,
      calisma_durum: "",
      mod: "",
      genset_l1: "",
      genset_l2: "",
      genset_l3: "",
      sebeke_l1: "",
      sebeke_l2: "",
      sebeke_l3: "",
      aku_voltaj: "",
      calisma_saati: "",
      motor_hararet: "",
      yag_basinc: "",
      yag_hararet: "",
      yakit_seviye: "",
      auto_ready_animation: "",
      alarm_animation: "",
      warning_animation: "",
      service_request_animation: "",
      birimler: {
        genset_l1: "V",
        genset_l2: "V",
        genset_l3: "V",
        sebeke_l1: "V",
        sebeke_l2: "V",
        sebeke_l3: "V",
        aku_voltaj: "V",
        calisma_saati: "h",
        motor_hararet: "°C",
        yag_basinc: "bar",
        yag_hararet: "°C",
        yakit_seviye: "%"
      }
    };
  },
  switchModern: id => {
    return {
      id,
      type: "switchModern",
      genislik: "400",
      orginal_genislik: 1000,
      elementLabel: "Switch Etiket",
      animation_type: "Gri-Yeşil",
      bagli_alan: "0_1",
      etiketFontSize: 24,
      timeoutMS: "5000",
      spinner_color: "#DBB06F",
      zeroColor: "#535563",
      oneColor: "#55D278"
    };
  },
  pushButtonModern: id => {
    return {
      id,
      type: "pushButtonModern",
      genislik: "400",
      orginal_genislik: 1000,
      elementLabel: "Push Button Etiket",
      animation_type: "Gri-Yeşil",
      bagli_alan: "0_1",
      etiketFontSize: 28,
      timeoutMS: "5000",
      spinner_color: "#5070E2",
      oneColor: "",
      zeroColor: ""
    };
  },
  boxEtiketStd: id => {
    return {
      id,
      type: "boxEtiketStd",
      genislik: "400",
      orginal_genislik: 1000,
      elementLabel: "Box Etiket",
      onColor: "49D389",
      offColor: "FC4263",
      animation_type: "Kırmızı-Yeşil",
      selected_icon: 0,
      bagli_alan: "0_1",
      etiketFontSize: 28
    };
  },
  yatayEtiketStd: id => {
    return {
      id,
      type: "yatayEtiketStd",
      genislik: "400",
      orginal_genislik: 1000,
      elementLabel: "Input Etiket",
      onColor: "49D389",
      offColor: "FC4263",
      animation_type: "Kırmızı-Yeşil",
      selected_icon: 0,
      bagli_alan: "0_1",
      etiketFontSize: 28
    };
  },
  alarmEtiketStd: id => {
    return {
      id,
      type: "alarmEtiketStd",
      genislik: "400",
      orginal_genislik: 1000,
      elementLabel: "Alarm Etiket",
      onColor: "49D389",
      offColor: "FC4263",
      animation_type: "Kırmızı-Yeşil",
      bagli_alan: "0_1",
      etiketFontSize: 28
    };
  },
  drenaj2MotorStd: id => {
    return {
      id,
      type: "drenaj2MotorStd",
      path: "rounded_label.js",
      genislik: "400",
      orginal_genislik: 1000,
      seviye_bagli_alan: "0_1"
    };
  },
  flatorStd: id => {
    return {
      id: id,
      type: "flatorStd",
      path: "rounded_label.js",
      genislik: "400",
      orginal_genislik: 1000,
      elementLabel: "Flator",
      onColor: "49D389",
      offColor: "DCDCDC",
      onLabel: "Açık",
      offLabel: "Kapalı",
      animation_type: "Kırmızı-Yeşil",
      bagli_alan: "0_1"
    };
  },
  cardViewInputOutput: id => {
    return {
      id: id,
      type: "cardViewInputOutput",
      path: "",
      baslik: "Kart Gösterge (Input/Output)",
      genislik: "400",
      orginal_genislik: 1000,
      colorsOn: {
        colorOutputText: "#000000",
        //Nudge and border are goint to be using the same color
        colorSwitchNudge: "#3ADA8D",
        colorSwitchBase: "#A0E5C4",
        colorLabel: "#000000",
        colorCardBase: "#FFFFFF",
        colorInputIcon: "#F6D049"
      },
      colorsOff: {
        colorOutputText: "#FFFFFF",
        //Nudge and border are goint to be using the same color
        colorSwitchNudge: "#EB4B55",
        colorSwitchBase: "#F29EA3",
        colorLabel: "#FFFFFF",
        colorCardBase: "#23305C",
        colorInputIcon: "#DDDDDD"
      },
      outputTextOn: "On",
      outputTextOff: "Off",
      cardLabel: "Kart Gösterge",
      //icon should be changeable
      iconName: "aydinlatma",
      input_bagli_alan: "0_1",
      output_bagli_alan: "0_1",
      outputTimeout: "5000"
    };
  },
  elemanEtiket: id => {
    return {
      id: id,
      type: "elemanEtiket",
      path: "",
      baslik: "Etiket Elemanı",
      genislik: "400",
      orginal_genislik: 1000,
      etiketText: "Etiket Eleman",
      etiketColor: "FFFFFF",
      baseBgColor: "3A55B6",
      baseStrokeColor: "253262"
    };
  },
  dijitalGosterge: id => {
    return {
      id: id,
      type: "dijitalGosterge",
      path: "rounded_label.js",
      baslik: "Dijital Gösterge",
      genislik: "400",
      orginal_genislik: 1000,
      baseArkaplan: "000000",
      baseCerceve: "48D18F",
      degerTextColor: "48D18F",
      birimTextColor: "FFFFFF",
      etiketColor: "000000",
      bagli_alan: "0_1",
      etiketText: "Etiket",
      etiketFontSize: "20",
      birimText: "br",
      birimFontSize: "14"
    };
  },
  slideSwitchOffset: id => {
    return {
      id: id,
      type: "slideSwitchOffset",
      path: "rounded_label.js",
      baslik: "Slide Switch Offset",
      genislik: "400",
      orginal_genislik: 1000,
      oneColor: "3DD77A",
      zeroColor: "CFCFCF",
      labelColor: "000000",
      oneLabel: "Açık",
      zeroLabel: "Kapalı",
      bagli_alan: "",
      etiketFontSize: "20",
      timeoutMS: "5000"
    };
  },
  katBilgiGosterge: id => {
    return {
      id: id,
      type: "katBilgiGosterge",
      path: "kat_bilgi_std.js",
      baslik: "Kat Bilgi Gösterge",
      genislik: "400",
      orginal_genislik: 1000,
      bagli_alan: "0_1"
    };
  },
  asansorStd: id => {
    return {
      id: id,
      type: "asansorStd",
      path: "asansor_standart.js",
      baslik: "Asansör Standart",
      genislik: "400",
      orginal_genislik: 1000,
      kabin_kapi_bagli_alan: "0_1",
      animation_type: "Kapalı-Açık"
    };
  },
  yuruyenIkiliStd: id => {
    return {
      id: id,
      type: "yuruyenIkiliStd",
      path: "yuruyen_ikili.js",
      baslik: "Yürüyen Merdiven İkili",
      genislik: "400",
      orginal_genislik: 1000
    };
  },
  yuruyenTekliStd: id => {
    return {
      id: id,
      type: "yuruyenTekliStd",
      path: "yuruyen_tekli.js",
      baslik: "Yürüyen Merdiven Tek",
      genislik: "400",
      orginal_genislik: 1000
    };
  },
  yonBilgiStd: id => {
    return {
      id: id,
      type: "yonBilgiStd",
      path: "yon_bilgi_std.js",
      baslik: "Yön Bilgi Gösterge",
      genislik: "400",
      orginal_genislik: 1000,
      elementLabel: "Etiket",
      onColor: "49D389",
      offColor: "DCDCDC",
      animation_type: "Kırmızı-Yeşil",
      icon_type: "Aşağı",
      etiketFontSize: 24,
      bagli_alan: "0_1"
    };
  },
  jeneratorStd: id => {
    return {
      id: id,
      type: "jeneratorStd",
      path: "jenerator_std.js",
      baslik: "Jeneratör Test",
      genislik: "400",
      orginal_genislik: 1000,
      auto_ready: "",
      alarm: "",
      warning: "",
      service_request: "",
      fuel: "",
      alarm_list: "",
      test: "",
      run: "",
      auto: "",
      off: "",
      durum: 54,
      calisma_durum: ""
    };
  },
  slideSwitch: id => {
    return {
      id: id,
      type: "slideSwitch",
      path: "slide_switch.js",
      genislik: "250",
      bagli_alan: "0_1",
      bit: "1",
      orginal_genislik: 1000,
      onLabel: "Açık",
      offLabel: "Kapalı",
      onLabelColor: "3c63c8",
      offLabelColor: "40c4c2",
      set_bagli_alan: "0_1",
      set_bit: "1",
      offValue: "0",
      onValue: "1"
    };
  },
  pushButton: id => {
    return {
      id: id,
      type: "pushButton",
      path: "pushbutton.js",
      baslik: "Push Button",
      genislik: "250",
      orginal_genislik: 1000,
      bagli_alan: "2_80_3_30",
      buttonOnColor: "7ED3B0",
      buttonOffColor: "CFCFCF",
      fixedLabel: "Etiket",
      fixedLabelColor: "273B81",
      buttonOnStrokeColor: "1C7B53",
      onValue: "1",
      offValue: "0",
      timeoutMS: "5000"
    };
  },
  groupBox: id => {
    const data = {
      id: id,
      type: "groupBox",
      path: "groupbox.js",
      baslik: "Gruplanmış Veri",
      cols: 3,
      rows: 3,
      genislik: "180",
      orginal_genislik: 480,
      arkaplan_renk: "1D49A2",
      yazi_renk: "FCFCFC",
      baslik_arkaplan_renk: "40C4C2",
      baslik_yazi_renk: "333333",
      label_default_width: 124,
      label_default_height: 33,
      alanlar: [
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        },
        {
          etiket: "Etiket",
          birim: "m<tspan style='baseline-shift:super'>3</tspan>",
          field: "0_1"
        }
      ]
    };
    return initGroupBox(data, false);
  },
  roundedLabel: id => {
    return {
      id: id,
      type: "roundedLabel",
      path: "rounded_label.js",
      etiket: "Etiket",
      birim: "birim",
      arkaplan: "BFBFBF",
      yazi: "333333",
      kenar: "333333",
      genislik: "200",
      bagli_alan: "0_1",
      orginal_genislik: 1000
    };
  },
  depoStd: id => {
    return {
      id: id,
      type: "depoStd",
      path: "depo_std.js",
      genislik: "1000",
      orginal_genislik: 1000,
      statik_seviye: "0_1",
      dinamik_seviye: "3_36",
      dinamik_seviye2: "3_36",
      have_border: "2",
      have_debimetre: "2"
    };
  },
  pompa2li: id => {
    return {
      id: id,
      type: "pompa2li",
      path: "pompa2li.js",
      genislik: "1200",
      orginal_genislik: 1000,
      depo_statik: "0_3",
      depo_dinamik: "6_75",
      check_valve_field1: "6_58",
      check_valve_bit1: "3",
      akis_field1: "6_56",
      akis_bit1: "1",
      check_valve_field2: "6_66",
      check_valve_bit2: "5",
      akis_field2: "6_66",
      akis_bit2: "3"
    };
  },
  kuyuStd: id => {
    return {
      id: id,
      type: "kuyuStd",
      path: "kuyu_std.js",
      genislik: "1100",
      orginal_genislik: 1000,
      statik_seviye: "0_3",
      dinamik_seviye: "6_14",
      depo_statik: "0_4",
      depo_dinamik: "6_15",
      check_valve_field: "6_2",
      check_valve_bit: "1",
      akis_field: "6_2",
      akis_bit: "1"
    };
  },
  pompaTek: id => {
    return {
      id: id,
      type: "pompaTek",
      path: "pompa_tek.js",
      genislik: "1100",
      orginal_genislik: 1000,
      depo_statik: "0_3",
      depo_dinamik: "6_253",
      check_valve_field1: "6_240",
      check_valve_bit1: "1",
      akis_field1: "6_240",
      akis_bit1: "1"
    };
  },
  pompaUclu: id => {
    return {
      id: id,
      path: "pompa_uclu.js",
      genislik: "1150",
      orginal_genislik: 1000,
      depo_statik: "0_3",
      depo_dinamik: "6_50",
      check_valve_field1: "6_24",
      check_valve_bit1: "3",
      akis_field1: "6_22",
      akis_bit1: "1",
      check_valve_field2: "6_34",
      check_valve_bit2: "5",
      akis_field2: "6_32",
      akis_bit2: "3",
      check_valve_field3: "6_46",
      check_valve_bit3: "5",
      akis_field3: "6_44",
      akis_bit3: "3"
    };
  },
  deiceModule: id => {
    return {
      id: id,
      type: "deiceModule",
      path: "deice_module.js",
      genislik: "900",
      orginal_genislik: 1000,
      hareket: "1_133_1",
      rezistans: "10_1",
      sensor: "10_2",
      sebeke: "10_3",
      durum: "10_4",
      hava: "10_5",
      yagis: "10_6"
    };
  }
};

//first setup: initial setup with alanlar data
const initGroupBox = data => {
  const {
    id,
    baslik,
    cols,
    rows,
    arkaplan_renk,
    baslik_arkaplan_renk,
    yazi_renk,
    baslik_yazi_renk
    //alanlar
  } = data;
  const groupElement = document.getElementById(id);
  const svg = groupElement.querySelector("svg");

  const groupBoxBaseBackground = svg.querySelector("#gb_base_background");
  groupBoxBaseBackground.setAttribute("fill", `#${arkaplan_renk}`);
  const groupBoxLabelBackground = svg.querySelector("#gb_label_background");
  groupBoxLabelBackground.setAttribute("fill", `#${baslik_arkaplan_renk}`);
  const groupBoxLabelText = svg.querySelector("#gb_label_text");
  groupBoxLabelText.setAttribute("fill", `#${baslik_yazi_renk}`);
  const labelTSpan = groupBoxLabelText.querySelector("tspan");
  labelTSpan.innerHTML = baslik;

  //text group -> text -> tspan tspan tspan
  const textGroup = svg.querySelector("#groupboxTextGroup");
  textGroup.setAttribute("fill", `#${yazi_renk}`);
  //clear text elements first
  if (textGroup.hasChildNodes()) {
    while (textGroup.firstChild) {
      textGroup.removeChild(textGroup.firstChild);
    }
  }

  let fixedRow = 64.02;
  for (let i = 1; i <= rows; i++) {
    let fixedCol = 19;
    for (let j = 1; j <= cols; j++) {
      const newText = createTextGroup(fixedCol, fixedRow);
      textGroup.appendChild(newText);
      fixedCol = fixedCol + 138;
    }
    fixedRow = fixedRow + 37;
  }
  const { width, height } = textGroup.getBBox();
  groupBoxBaseBackground.setAttribute("width", `${width + 40}`);
  groupBoxBaseBackground.setAttribute("height", `${height + 60}`);
  svg.setAttribute("viewBox", `0 0 ${width + 40} ${height + 93}`);
  svg.setAttribute("enable-background", `new 0 0 ${width + 40} ${height + 93}`);

  return data;
};

const createTextGroup = (x, y) => {
  const newTextGroup = document.createElementNS(svgNamespace, "text");
  newTextGroup.setAttribute("class", "data");
  newTextGroup.setAttribute("text-anchor", "start");
  newTextGroup.setAttribute("xml:space", "preserve");
  newTextGroup.setAttribute("style", "white-space: pre;");
  newTextGroup.setAttribute("letter-spacing", "0em");
  newTextGroup.setAttribute("font-family", "Fira Sans");
  newTextGroup.setAttribute("x", `${x}`);
  newTextGroup.setAttribute("y", `${y}`);

  const tspanEtiket = document.createElementNS(svgNamespace, "tspan");
  tspanEtiket.setAttribute("dx", "0");
  tspanEtiket.setAttribute("dy", "0");
  tspanEtiket.setAttribute("font-size", "12");
  tspanEtiket.setAttribute("class", "data_etiket");
  tspanEtiket.innerHTML = "Etiket";

  const tspanDeger = document.createElementNS(svgNamespace, "tspan");
  tspanDeger.setAttribute("dx", "4");
  tspanDeger.setAttribute("dy", "0");
  tspanDeger.setAttribute("font-size", "12");
  tspanDeger.setAttribute("class", "data_deger");
  tspanDeger.innerHTML = "000.00";

  const tspanBirim = document.createElementNS(svgNamespace, "tspan");
  tspanBirim.setAttribute("dx", "0");
  tspanBirim.setAttribute("dy", "2");
  tspanBirim.setAttribute("font-size", "10");
  tspanBirim.setAttribute("class", "data_deger");
  tspanBirim.innerHTML = "m<tspan style='baseline-shift:super'>3</tspan>";
  const elements = [tspanEtiket, tspanDeger, tspanBirim];
  newTextGroup.append(...elements);
  return newTextGroup;
};

/*
 * Get SVG Previews
 * */
const getSvgPreview = {
  jeneratorPanel: () => {
    return getSvg("jenerator_new_design.svg");
  },
  switchModern: () => {
    return getSvg("switch_modern.svg");
  },
  pushButtonModern: () => {
    return getSvg("pb_neu_modern.svg");
  },
  boxEtiketStd: () => {
    return getSvg("etiket_box.svg");
  },
  yatayEtiketStd: () => {
    return getSvg("input_etiket_horizontal.svg");
  },
  alarmEtiketStd: () => {
    return getSvg("alarm_etiket.svg");
  },
  drenaj2MotorStd: () => {
    return getSvg("drenaj_2_motor.svg");
  },
  katKapiStd: () => {
    return getSvg("kat_kapi_etiket.svg");
  },
  flatorStd: () => {
    return getSvg("flator_etiket.svg");
  },
  cardViewInputOutput: () => {
    return getSvg("card_input_output_view.svg");
  },
  elemanEtiket: () => {
    return getSvg("eleman_etiket.svg");
  },
  dijitalGosterge: () => {
    return getSvg("digital_gosterge_tekli.svg");
  },
  slideSwitchOffset: () => {
    return getSvg("switch-group-offset-label.svg");
  },
  hizmetDisiStd: () => {
    return getSvg("hizmet_disi_std.svg");
  },
  sebekeGostergeStd: () => {
    return getSvg("sebeke_std.svg");
  },
  katBilgiGosterge: () => {
    return getSvg("kat_bilgi_std.svg");
  },
  asansorStd: () => {
    return getSvg("asansor_std.svg");
  },
  yuruyenIkiliStd: () => {
    return getSvg("yuruyen_ikili_std.svg");
  },
  yuruyenTekliStd: () => {
    return getSvg("yuruyen_tekli_std.svg");
  },
  yonBilgiStd: () => {
    return getSvg("yon_bilgi_group.svg");
  },
  jeneratorStd: () => {
    return getSvg("jenerator_vector.svg");
  },
  slideSwitch: () => {
    return getSvg("slideSwitchNew.svg");
  },
  pushButton: () => {
    return getSvg("push_button_full.svg");
  },
  groupBox: () => {
    return getSvg("groupboxNew.svg");
  },
  roundedLabel: () => {
    return getSvg("textbox9.svg");
  },
  depoStd: () => {
    return getSvg("depo002.svg");
  },
  pompa2li: () => {
    return getSvg("pompa2001.svg");
  },
  kuyuStd: () => {
    return getSvg("kutu_v006.svg");
  },
  pompaTek: () => {
    return getSvg("pompa1002.svg");
  },
  pompaUclu: () => {
    return getSvg("pompa3002.svg");
  },
  deiceModule: () => {
    return getSvg("deice_v002.svg");
  }
};

const getSvg = async fileName => {
  let response = null;
  try {
    const postResponse =
      process.env.NODE_ENV === "development"
        ? await axios.post(process.env.VUE_APP_LOCAL_JSFILE_ENDPOINT, {
            fileName: fileName
          })
        : await axios.get(
            `${process.env.VUE_APP_SVG_FILE_BASE_URL}${fileName}`
          );
    response = await postResponse.data;
  } catch (e) {
    await console.log("Error getting svg preview", e);
  }
  return response;
};

/*
 * Change SVG dimensions. Transform and Scale.
 * */
const changeSvgDimension = currentItem => {
  const { id, orginal_genislik, genislik } = currentItem;

  const groupElement = document.getElementById(id);
  const translateX = groupElement.getCTM().e;
  const translateY = groupElement.getCTM().f;

  const newScale = Number(genislik) / Number(orginal_genislik);

  groupElement.setAttribute(
    "transform",
    `translate(${translateX},${translateY}) scale(${newScale})`
  );
};

//returns the inner svg element inside the svg <g> element.
const getSVGChildElements = (currentItem, elementID) => {
  const { id } = currentItem;
  const groupElement = document.getElementById(id);
  const svgInner = groupElement.getElementsByTagName("svg")[0];

  return svgInner.querySelector(elementID);
};

const findBinaryValue = (value, bit) => {
  const binaryArray = Number(value)
    .toString(2)
    .split("");
  const b = new Array(16 - binaryArray.length).fill("0").concat(binaryArray);
  //console.log("VALUE", value);
  //console.log("BINARY VALUES", b);
  return b[b.length - Number(bit)];
};

const getBitValue = (dataParam, svgItemObject) => {
  const { Datas } = dataParam;
  const [tip, master_id, bit] = svgItemObject["bagli_alan"].split("_");
  const data = Datas[`${tip}_${master_id}`];

  const binaryArray = Number(data)
    .toString(2)
    .split("");
  const padded = new Array(8 - binaryArray.length)
    .fill("0")
    .concat(binaryArray);

  return padded[padded.length - Number(bit)];
};

export {
  changeSvgDimension,
  getSVGChildElements,
  getSvgPreview,
  getSvgPropertyData,
  initGroupBox,
  createTextGroup,
  getBitValue,
  findBinaryValue,
  ioCardIconPaths
};
