<template>
  <!-- Upload Dialog -->
  <v-dialog persistent width="400" v-model="dialogModel">
    <v-card
        transition="scale-transition">
      <v-alert
          :value="alertExt"
          color="red lighten-1"
          dark
          dense
          icon="mdi-alert-circle"
          transition="scale-transition">
        Lütfen doğru uzantıya sahip bir dosya ekleyiniz.
      </v-alert>
      <div draggable="true" @dragenter="dragEnter" @dragover="dragOver" @drop="dropFile" @dragleave=dragLeave id="drop-block" class="upload_block py-4" @click="uploadClick">
        <v-row style="pointer-events: none" class="justify-center" no-gutters>
          <v-icon class="upload-icon" style="font-size: 72px !important;"
            >cloud_upload</v-icon
          >
        </v-row>
        <v-row style="pointer-events: none" class="justify-center" no-gutters>
          <p class="mb-0" v-if="typeof fileToUpload !== 'undefined'">Seçilen dosya: {{ fileToUpload["name"] }}</p>
        </v-row>
        <v-row  style="display: none; pointer-events: none;" class="justify-center" no-gutters>
          <v-file-input
            id="file_input"
            class="px-4"
            :accept="'.' + fieldItem['CustomData']['extension']"
            v-model="fileToUpload"
            chips
            small-chips
            show-size
            truncate-length="20"
          ></v-file-input>
        </v-row>
      </div>
      <v-row class="justify-center px-6 py-4 body-2" no-gutters>
        <p class="mb-0 text-center">
          Yüklemek istediğiniz dosyaları sürükleyip bırakın veya üstteki bulut
          simgesine tıklayın.
        </p>
      </v-row>
      <v-row no-gutters class="justify-end">
        <v-card-actions>
          <v-btn
            small
            :loading="dialogUploadBtnLoading"
            :disabled="dialogUploadBtnDisabled"
            color="#5072dd"
            class="ma-2 white--text"
            @click="uploadFile"
          >
            <v-icon left dark>
              mdi-cloud-upload
            </v-icon>
            Dosyayı yükle
          </v-btn>
          <v-btn
            @click="closeModal"
            small
            color="#F3415C"
            class="ma-2"
            text
          >
            İptal
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
  <!-- Upload Dialog -->
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "FileUploadPopup",
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dialogModel: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      alertExt: false,
      dialogUploadBtnLoading: false,
      dialogUploadBtnDisabled: true,
      fileToUpload: []
    };
  },
  computed: {
    ...mapGetters({
      //new getters =>
      getAccessToken: "storeLoginEvents/getAccessToken",
      //login ve soket ile ilgili olan getter metodları
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected",
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  watch: {
    fileToUpload: function(val) {
      console.log("File Value", val);
      if (typeof val !== "undefined") {
        this.dialogUploadBtnDisabled = false;
      }
    },
  },
  beforeDestroy() {
    const block = document.querySelector("#drop-block");
    block.removeEventListener("dragOver", this.dragOver);
    block.removeEventListener("dragEnter", this.dragEnter);
    block.removeEventListener("drop", this.dropFile);
  },
  created() {

  },
  mounted() {
  },
  methods: {
    dragOver: function (e) {
      e.preventDefault();
    },
    dragEnter: function (e) {
      e.preventDefault();
      e.target.classList.add("overClass");
    },
    dragLeave: function (e) {
      e.preventDefault();
      e.target.classList.remove("overClass");
    },
    dropFile: function (e) {
      e.preventDefault();
      const extension = e.dataTransfer.files[0]['name'].split(".").pop();
      if (extension === this.fieldItem['CustomData']['extension']) {
        this.fileToUpload = e.dataTransfer.files[0];
      }else {
        console.log("Farklı uzantı.");
        this.alertExt = true;
        setTimeout(() => {
          this.alertExt = false;
        },5000);
      }
      e.target.classList.remove("overClass");
    },
    uploadClick: function () {
      document.querySelector("#file_input").click();
    },
    closeModal: function () {
      this.$emit("close");
    },
    uploadFile: async function () {
      console.log("Token",this.getAccessToken);
      this.dialogUploadBtnLoading = true;
      this.dialogUploadBtnDisabled = true;

      const formData = new FormData();
      formData.append("token", this.getAccessToken);
      formData.append("islem", "uploadonly");
      formData.append("hedef", this.fieldItem['CustomData']['path']);
      //formData.append("id", 1);
      formData.append("uploadFile", this.fileToUpload);

      try {
        const response = await axios.post("https://telixconnect.net:3443/fileoperations",formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.$emit("upload-ok",await response);

        this.dialogUploadBtnLoading = false;
        this.dialogUploadBtnDisabled = false;
      } catch (e) {
        console.warn("Error occured while uploading", e);
        this.dialogUploadBtnLoading = false;
        this.dialogUploadBtnDisabled = false;
        this.$emit("upload-error",e);
      }

    }
  }
};
</script>

<style scoped>
.upload-icon {
  color: #5072dd !important;
}

.upload_block {
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.upload_block:hover {
  background: #e8eef6;
  transition: 0.2s ease-in-out;
}

.overClass{
  background: #e8eef6;
  animation: dragOverUpload 1.5s infinite;
}

@keyframes dragOverUpload {
  0% {
    background: #ffffff;
  }
  50% {
    background: #d3e0ff;
  }
  100% {
    background: #ffffff;
  }
}
</style>
