<script>
export default {
  name: "MapMarker",
  props: {
    position: {
      type: Object,
      required: true
    },
    googleMapsInstance: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      marker: null
    };
  },
  created() {},
  mounted() {
    console.log("POSITION PROP", this.position);
    console.log("GOOGLEMAPS PROP", this.googleMapsInstance);
    if (this.googleMapsInstance !== null) {
      const { Boylam, Enlem } = this.position;
      this.marker = new window.google.maps.Marker({
        position: { lat: Enlem, lng: Boylam },
        map: this.googleMapsInstance
      });
      this.marker.addListener("click", this.markerClickHandler.bind(this));
    }
  },
  beforeDestroy() {
    this.marker.setMap(null);
    window.google.maps.event.clearInstanceListeners(this.marker);
  },
    methods: {
      markerClickHandler: function () {
          //Send an action here...
          console.log("Clicked Marker",this.position);
          const {modem_id} = this.position;
          this.$parent.markerClicked(modem_id);
      }
    },
  render() {
    return null;
  }
};
</script>

<style scoped></style>
