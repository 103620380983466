<template>
  <v-form>
    <v-text-field
      label="Bileşen Etiket"
      v-model="formModel['component_label']"
    />
    <v-autocomplete
      label="Kabin Bağlı Alan"
      v-model="formModel['bagli_alan']"
      :items="bagliAlanlar"
      item-text="Text"
      item-value="Id"
    />
    <v-autocomplete
      label="Input Animasyon Tipi"
      v-model="formModel['animation_type']"
      :items="animationTypes"
    />
  </v-form>
</template>

<script>
export default {
  name: "ElevatorComponent",
  props: {
    bagliAlanlar: {
      type: Array,
      required: false
    },
    animationTypes: {
      type: Array,
      required: false
    },
    formModel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
