<template>
  <v-row no-gutters class="justify-space-between align-center">
    <div>
      <span class="white--text">Sayfa başına gösterim:</span>
      <v-select
        v-model="limit"
        @change="$emit(eventNames[0], limit)"
        class="mt-2"
        label="10"
        :items="pageLimit"
        hide-details
        solo
        dense
      />
    </div>
    <div class="align-self-center">
      <v-row no-gutters>
        <v-tooltip color="red darken-2" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="$emit(eventNames[1])"
              v-on="on"
              :disabled="pageNumber === 1"
              text
              icon
              color="white"
            >
              <v-icon>first_page</v-icon>
            </v-btn>
          </template>
          <span class="white--text">İlk Sayfa</span>
        </v-tooltip>
        <v-tooltip color="red darken-2" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="$emit(eventNames[2])"
              v-on="on"
              :disabled="pageNumber === 1"
              text
              icon
              color="white"
            >
              <v-icon>arrow_left</v-icon>
            </v-btn>
          </template>
          <span class="white--text">Geri</span>
        </v-tooltip>
        <span class="white--text" style="line-height: 48px;"
          >{{ totalPage === 0 ? "0" : pageNumber }} / {{ totalPage === 0 ? "0" : totalPage }}</span
        >
        <v-tooltip color="red darken-2" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="$emit(eventNames[3])"
              v-on="on"
              :disabled="pageNumber === totalPage || totalPage === 0"
              text
              icon
              color="white"
            >
              <v-icon>arrow_right</v-icon>
            </v-btn>
          </template>
          <span class="white--text">İleri</span>
        </v-tooltip>
        <v-tooltip color="red darken-2" bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="$emit(eventNames[4])"
              v-on="on"
              :disabled="pageNumber === totalPage || totalPage === 0"
              text
              icon
              color="white"
            >
              <v-icon>last_page</v-icon>
            </v-btn>
          </template>
          <span class="white--text">Son Sayfa</span>
        </v-tooltip>
      </v-row>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    totalPage: {
      type: Number,
      required: true,
      default: 0
    },
    pageNumber: {
      type: Number,
      required: true,
      default: 0
    },
    /*
     * Limit event, toFirst, toPrev, toNext, toLast
     * */
    eventNames: {
      type: Array,
      required: false,
      default: () => ["limitChanged", "toFirst", "toPrevious", "toNext", "toLast"]
    }
  },
  data() {
    return {
      pageLimit: [10, 20, 30, 40, 50, 100],
      limit: 10
    };
  },
  created() {}
};
</script>

<style scoped></style>
