<script>
import { Scatter } from "vue-chartjs";
export default {
  extends: Scatter,
  name: "ScatterChart",
  props: {
    chartData: {
      type: Object,
      required: false
    },
    xLabels: {
      type: String,
      required: false,
      default: () => "X Origin"
    },
    yLabels: {
      type: String,
      required: false,
      default: () => "Y Origin"
    }
  },
  mounted() {
    const { Legend, Values } = this.chartData;
    console.log("CHART DATA IS =>", this.chartData);

    const xyValues = Values.map(v => {
      return {
        x: v.Y1,
        y: v.Y2,
        date: new Date(Number(String(v.X).substring(0, 13))).toLocaleString("tr-TR"),
        ort: v.Y3
      };
    });

    console.log("Check xy", xyValues);

    const config = {
      /*labels: Values.map(v =>
        new Date(Number(String(v.X).substring(0, 13))).toLocaleString("tr-TR")
      ),*/
      datasets: [
        {
          fill: false,
          borderColor: "#244528",
          pointBackgroundColor: "#3b985d",
          label: Legend,
          data: xyValues
        }
      ]
    };

    const options = {
      title: {
        text: Legend
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.xLabels
            }
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.yLabels
            }
          }
        ]
      },
      tooltips: {
        enabled: false
      },
      plugins: {
        datalabels: {
          color: "#FFFFFF",
          borderColor: "#283a9b",
          backgroundColor: "#0a1134",
          align: "start",
          anchor: "center",
          borderRadius: 4,
          font: {
            weight: "bold"
          },
          padding: 6,
          formatter: x => {
            console.log("FORMATTER", x);
            return `${x.date}\nMax:${x.x}\nMin:${x.y}\nOrt:${x.ort}`;
          },
          listeners: {
            enter: function(context) {
              context.hovered = true;
              return true;
            },
            leave: function(context) {
              context.hovered = false;
              return true;
            },
          },
          opacity: function(context) {
            // Change the label text color based on our new `hovered` context value.
            return context.hovered ? 1 : .5;
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false
    };
    this.addPlugin({
      id: "chart-data-labels",
      beforeInit: chart => {
        console.log("INIT PLUGIN", chart);
      }
    });
    this.renderChart(config, options);
  }
};
</script>

<style scoped></style>
