<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
          label="Genişlik"
          v-model="currentItem['genislik']"
          @input="svgDimen"
      />
    </v-row>
  </div>
</template>

<script>
import {changeSvgDimension} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";

export default {
name: "yuruyenTekliStd",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  computed: {
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    },
  }
}
</script>

<style scoped>

</style>
