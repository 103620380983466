<template>
  <v-menu
    v-model="menu"
    max-width="800"
    min-width="500"
    :close-on-content-click="false"
    bottom
    center
    :offset-y="true"
  >
    <template v-slot:activator="{ on }">
      <v-btn text icon :color="themeOptions['iconColor']" dark v-on="on">
        <v-badge
          :content="toolbarAlarmData.count"
          :value="toolbarAlarmData.count"
          :overlap="true"
          color="error"
          :left="true"
        >
          <template v-slot:badge>{{ toolbarAlarmData.count }}</template>
          <v-icon :large="true">notifications</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card outlined :color="themeOptions['cardColor']">
      <v-list-item>
        <v-list-item-title>Alarm Bildirimleri</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <notification-table
          class="ml-4 mr-4"
          :toolbar-notification-data="toolbarAlarmData"
        />
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false">Kapat</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import NotificationsTable from "@/components/SimpleTableComp/NotificationsTable";

export default {
  name: "ToolbarMenuAlarms",
  components: {
    "notification-table": NotificationsTable
  },
  data() {
    return {
      menu: false
    };
  },
  props: {
    toolbarAlarmData: {
      type: Object,
      required: true
    },
    themeOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          iconColor: "toolbarIcons",
          cardColor: "popupMenuColor"
        };
      }
    }
  }
};
</script>

<style scoped>
.popupMenuColor.theme--dark.v-sheet--outlined {
  border: thin solid rgba(255, 255, 255, 0.12) !important;
}
</style>
