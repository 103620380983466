<template>
  <v-form>
    <v-text-field
      label="Bileşen Etiket"
      v-model="formModel['component_label']"
    />
    <v-autocomplete
      label="Bağlı Alan"
      v-model="formModel['bagli_alan']"
      :items="bagliAlanlar"
      item-text="Text"
      item-value="Id"
    />
    <v-text-field label="Input Etiket" v-model="formModel['input_label']" />
    <v-autocomplete
        label="İkon Tipi"
        v-model="formModel['icon_type']"
        :items="icons"
        item-value="icon"
        item-text="text"
    />
    <v-autocomplete
      label="Input Animasyon Tipi"
      v-model="formModel['animation_type']"
      :items="animationTypes"
    />
  </v-form>
</template>

<script>
export default {
  name: "EscalatorSingle",
  props: {
    bagliAlanlar: {
      type: Array,
      required: false
    },
    animationTypes: {
      type: Array,
      required: false
    },
    formModel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icons: [
        {
          icon: "asagi",
          text: "Aşağı",
        },
        {
          icon: "yukari",
          text: "Yukarı",
        },
      ],
    }
  }
};
</script>

<style scoped></style>
