<template>
  <v-form>
    <v-text-field
      label="Bileşen Etiket"
      v-model="formModel['component_label']"
    />
    <v-autocomplete
      multiple
      label="Bağlı Alan (Seçim Input 1 - Input 2 şeklindedir.)"
      v-model="formModel['bagli_alan']"
      :items="bagliAlanlar"
      item-text="Text"
      item-value="Id"
      :rules="[v => v.length === 2 || 'En az iki seçim yapmalısınız.']"
    />
    <v-combobox
        v-model="formModel['input_label']"
        hide-selected
        label="Input Etiket"
        multiple
        small-chips
        :rules="[v => v.length === 2 || 'İki etiket girişi yapmalısınız.']"
    >
    </v-combobox>
    <v-autocomplete
        label="İkon Tipi"
        v-model="formModel['icon_type']"
        :items="icons"
        item-value="icon"
        item-text="text"
        multiple
        :rules="[v => v.length === 2 || 'En az iki seçim yapmalısınız.']"
    />
    <v-combobox
      multiple
      label="Input Animasyon Tipi (Seçim Input 1 - Input 2 şeklindedir.)"
      v-model="formModel['animation_type']"
      :items="animationTypes"
      small-chips
      :rules="[v => v.length <= 2 || 'En az iki seçim yapmalısınız.']"
    />
  </v-form>
</template>

<script>
export default {
  name: "EscalatorDouble",
  props: {
    bagliAlanlar: {
      type: Array,
      required: false
    },
    animationTypes: {
      type: Array,
      required: false
    },
    formModel: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icons: [
        {
          icon: "asagi",
          text: "Aşağı",
        },
        {
          icon: "yukari",
          text: "Yukarı",
        },
      ],
    }
  }
};
</script>

<style scoped></style>
