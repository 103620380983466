<template>
  <div>
    <v-card elevation="0">
      <v-row class="align-center justify-space-between" no-gutters>
        <v-col class="col-auto">
          <v-card-text v-text="confData['camera_name']"> </v-card-text>
        </v-col>

        <v-col class="col-auto mr-4">
          <v-btn v-if="confData['havelivestream'] === 1" icon>
            <v-icon>live_tv</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="col-12">
          <iframe
            width="100%"
            height="400"
            :src="confData['uri']"
            title="Video"
            allowusermedia
            allowfullscreen
          ></iframe>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "IFrameComponent",
  props: {
    confData: {
      type: Object,
      required: true
    },
    mediaData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {}
};
</script>

<style scoped></style>
