import Vue from "vue";
import Router from "vue-router";
import store from "./vuex_store/store_wrapper";
import LoginView from "./main_views/LoginView";

Vue.use(Router);

// initial router with fixed routes inside
const initRouter = () =>
  new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/",
        name: "LoginView",
        component: LoginView
      }
    ]
  });

// Create a new router instance.
const router = initRouter();

// Reset the router with creating a new route instance.
// this is going to prevent routes from duplicating.
export function resetRouter() {
  const newRouter = initRouter();
  router.matcher = newRouter.matcher;
}

router.beforeEach((to, from, next) => {
  console.log("ROUTES: ", router);
  console.log(
    "States",
    store.getters["storeLoginEvents/getAccessTokenLocalStorage"]
  );
  console.log("Login Status", store.getters["storeLoginEvents/getLoginStatus"]);
  store.dispatch("storeGridEvents/actionSetDrawerState", false);
  store.dispatch("storeGridEvents/actionSetCurrentRoute", to);

  /*
   * set state of access token before each route.
   * */
  store.dispatch("storeLoginEvents/updateAccessTokenFromStorage");

  if (to.fullPath !== "/") {
    if (store.getters["storeLoginEvents/getAccessToken"] === null) {
      console.log("Access Token yok. Redirect To LoginView.vue");
      next("/");
    } else {
      console.log("Access Token var.", to);
      // if accessToken exists and if socket is not connected. Which emulates the page refresh or
      //switching to another route without using the navigation menu.
      if (!store.getters["storeSocket/getterIsSocketConnected"]) {
        const socket_message = {
          type: "authenticate",
          payload: { token: store.getters["storeLoginEvents/getAccessToken"] }
        };
        //Call global socket function of connectToSocket(...) with Vue.prototype because we are binding global socket
        //init to Vue.prototype (see => global_socket.js/line:8)
        Vue.prototype.connectToSocket(socket_message);
        console.log("CONNECTING SEND.");
      }
      next();
    }
  }

  next();
});

export default router;
