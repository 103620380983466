<template>
  <v-row no-gutters>
    <v-col class="col-4" v-if="actionType === 'incele'">
      <p>{{ readOnlyValue || field["Label"] }}</p>
      <p>
        {{
          dataValueModel[field["Field"]] === ""
            ? "-----"
            : dataValueModel[field["Field"]]
        }}
      </p>
    </v-col>
    <v-col v-if="actionType === 'yeni'" class="col-4">
      <!-- Etiket -->
      <p v-if="showText" @click="enableTextField">
        {{ textVal }}
      </p>
      <v-text-field
        hide-details
        class="mt-0 pt-0 pb-1"
        v-if="showTextField"
        @blur="clickOutside"
        :placeholder="textVal"
        autofocus
        v-model="dataLabelModel[field['CustomData']['id']]"
      />
      <!-- Deger -->
      <v-text-field label="Değer" v-model="dataValueModel[field['Field']]" />
    </v-col>
    <v-col v-if="actionType === 'duzenle'">
      <p v-if="showText" @click="enableTextField">
        {{ textVal }}
      </p>
      <v-text-field
        hide-details
        class="mt-0 pt-0 pb-1"
        v-if="showTextField"
        @blur="clickOutside"
        :placeholder="textVal"
        v-model="dataLabelModel[field['CustomData']['id']]"
        autofocus
      />
      <!-- Deger -->
      <v-text-field label="Değer" v-model="dataValueModel[field['Field']]" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LabelEditTextComponent",
  props: {
    dataLabelModel: {
      type: [Object, String],
      required: false
    },
    dataValueModel: {
      type: Object,
      required: false
    },
    field: {
      type: Object,
      required: false
    },
    readOnlyValue: {
      type: String,
      required: false
    },
    actionType: {
      type: String,
      required: false,
      default: "yeni"
    }
  },
  data() {
    return {
      showText: true,
      showTextField: false,
      textVal: "",
      customModel: {}
    };
  },
  created() {
    console.log("Data LABELS", this.dataLabelModel);

    if (this.actionType === "duzenle" || this.actionType === "yeni") {
      if (this.dataLabelModel[this.field["CustomData"]["id"]]) {
        this.textVal = this.dataLabelModel[this.field["CustomData"]["id"]];
      } else {
        this.textVal = this.field["Label"];
      }
      console.log(
        "Data Label Model",
        this.dataLabelModel[this.field["CustomData"]["id"]]
      );
    }
  },
  methods: {
    enableTextField: function() {
      console.log("Clicked");
      this.showText = false;
      this.showTextField = true;
    },
    clickOutside: function() {
      this.showText = true;
      this.showTextField = false;

      if (this.dataLabelModel[this.field["CustomData"]["id"]]) {
        this.textVal = this.dataLabelModel[this.field["CustomData"]["id"]];
      }
    }
  }
};
</script>

<style scoped></style>
