<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        Düzenle
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col class="col-12">
            <v-text-field label="Label" v-model="editedItem['label']" />
          </v-col>
          <v-col class="col-12">
            <v-autocomplete
              label="Bağlı Alan"
              :items="bagliAlanlar"
              item-value="Id"
              item-text="Text"
              v-model="bagliAlanModel"
              @input="changeBagliAlan"
            ></v-autocomplete>
          </v-col>
          <v-col class="col-12">
            <v-autocomplete
              v-if="editedItem['tip'] === '1' || editedItem['tip'] === '8'"
              label="Gösterge"
              :items="uiItems"
              item-text="Text"
              item-value="Id"
              v-model="editedItem['input_ui']"
            />
          </v-col>
          <v-col v-if="editedItem['tip'] === '8' && editedItem['input_ui'] === '4'" class="col-12">
            <v-text-field label="Birim Değer" v-model="editedItem['birim_text']" />
          </v-col>
          <v-row no-gutters>
            <v-col class="col-6 px-1">
              <v-text-field
                label="0 Değer Etiket"
                v-model="editedItem['zero_label']"
              >
                <v-icon
                  :color="'#' + editedItem['zero_color']"
                  slot="prepend"
                  class="zeroLabelIcon"
                >
                  mdi-label
                </v-icon>
              </v-text-field>
            </v-col>
            <v-col class="col-6 px-1">
              <v-text-field
                label="1 Değer Etiket"
                v-model="editedItem['one_label']"
              >
                <v-icon
                  :color="'#' + editedItem['one_color']"
                  slot="prepend"
                  class="oneLabelIcon"
                >
                  mdi-label
                </v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-6 px-1">
              <v-menu
                ref="cpRef"
                v-model="zeroColorMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-x
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="0 değeri renk"
                    readonly
                    v-on="on"
                    v-model="editedItem['zero_color']"
                  >
                    <v-icon
                      :color="'#' + editedItem['zero_color']"
                      slot="prepend"
                      class="zeroLabelIcon"
                    >
                      mdi-invert-colors
                    </v-icon>
                  </v-text-field>
                </template>
                <v-color-picker
                  v-model="editedItem['zero_color']"
                  @input="change0Color"
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col class="col-6 px-1">
              <v-menu
                ref="cpRef"
                v-model="oneColorMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-x
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="1 değeri renk"
                    readonly
                    v-on="on"
                    v-model="editedItem['one_color']"
                  >
                    <v-icon
                      :color="'#' + editedItem['one_color']"
                      slot="prepend"
                    >
                      mdi-invert-colors
                    </v-icon>
                  </v-text-field>
                </template>
                <v-color-picker
                  v-model="editedItem['one_color']"
                  @input="change1Color"
                ></v-color-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
        <v-divider class="py-2"></v-divider>
        <v-row no-gutters>
          <p class="ma-0"><span class="font-weight-medium text--black">Varsayılan tanımlar</span> (Tercihe göre renk ve
            etiket
            seçimleri varsayılan değerlerle de yapılabilir.)</p>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12">
            <v-autocomplete
                label="Tanımlanmış Renkler"
                :items="tanimlanmisRenkler"
                @input="changeColors"
                item-text="Text"
                item-value="colors"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12">
            <v-autocomplete
                label="Tanımlanmış Etiketler"
                :items="tanimlanmisEtiketler"
                @input="changeLabels"
                item-text="Text"
                item-value="labels"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('dismissDialog')">
          Kapat
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IOListEditForm",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    },
    bagliAlanlar: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tanimlanmisRenkler: [
        { Id: "0", colors: ["F84C4C", "31DC6B"], Text: "Kırmızı-Yeşil" },
        { Id: "1", colors: ["31DC6B", "F84C4C"], Text: "Yeşil-Kırmızı" },
        { Id: "2", colors: ["CFCFCF", "31DC6B"], Text: "Gri-Yeşil" },
        { Id: "3", colors: ["31DC6B", "CFCFCF"], Text: "Yeşil-Gri" }
      ],
      tanimlanmisEtiketler: [
        { Id: "0", labels: ["Yok", "Var"], Text: "Yok-Var" },
        { Id: "1", labels: ["Var", "Yok"], Text: "Var-Yok" },
        { Id: "2", labels: ["Devrede", "Devrede Değil"], Text: "Devrede-Devrede Değil" },
        { Id: "3", labels: ["Devrede Değil", "Devrede"], Text: "Devrede Değil-Devrede" },
        { Id: "4", labels: ["Kapalı", "Açık"], Text: "Kapalı-Açık" },
        { Id: "5", labels: ["Açık", "Kapalı"], Text: "Açık-Kapalı" },
        { Id: "6", labels: ["Çalışıyor", "Durdu"], Text: "Çalışıyor-Durdu" },
        { Id: "7", labels: ["Durdu", "Çalışıyor"], Text: "Durdu-Çalışıyor" },
        { Id: "8", labels: ["Normal", "Korumada"], Text: "Normal-Korumada" },
        { Id: "9", labels: ["Korumada", "Normal"], Text: "Korumada-Normal" },
      ],
      bagliAlanModel: "",
      uiItems: [
        { Id: "0", Text: "Grafik" },
        { Id: "1", Text: "Lamba" },
        { Id: "2", Text: "Custom" },
        { Id: "3", Text: "List" },
        { Id: "4", Text: "Text" },
        { Id: "5", Text: "Object" },
      ],
      zeroColorMenu: false,
      oneColorMenu: false
    };
  },
  created() {
    const { tip, master_id, bit, birim } = this.editedItem;
    switch (tip) {
      case "1":
        this.bagliAlanModel = `${tip}_${master_id}_${bit}`;
        break;
      case "2":
        this.bagliAlanModel = `${tip}_${master_id}_${bit}_${birim}`;
        break;
      case "3":
        this.bagliAlanModel = `${tip}_${master_id}_${birim}`;
        break;
      case "4":
        this.bagliAlanModel = `${tip}_${master_id}_${birim}`;
        break;
      case "8":
        this.bagliAlanModel = `${tip}_${master_id}_${birim}`;
        break;
    }
    console.log("Bagli Alan Model", this.bagliAlanModel);
  },
  methods: {
    changeLabels: function (v) {
      console.log("Labels Changed", v);
      this.editedItem["zero_label"] = v[0];
      this.editedItem["one_label"] = v[1];
    },
    changeColors: function(v) {
      console.log("Colors Changed", v);
      this.editedItem["zero_color"] = v[0];
      this.editedItem["one_color"] = v[1];
    },
    getHexValue: function(value) {
      return value.slice(1, value.length - 2).toLowerCase();
    },
    change0Color: function(v) {
      this.editedItem["zero_color"] = this.getHexValue(v);
    },
    change1Color: function(v) {
      this.editedItem["one_color"] = this.getHexValue(v);
    },
    changeBagliAlan: function(v) {
      console.log("Change Bagli Alan", v.split("_"));
      const s_field = v.split("_");
      switch (s_field[0]) {
        case "1":
          this.editedItem["tip"] = s_field[0];
          this.editedItem["master_id"] = s_field[1];
          this.editedItem["bit"] = s_field[2];
          this.editedItem["birim"] = "";
          break;
        case "2":
          this.editedItem["tip"] = s_field[0];
          this.editedItem["master_id"] = s_field[1];
          this.editedItem["bit"] = s_field[2];
          this.editedItem["birim"] = s_field[3];
          break;
        case "3":
          this.editedItem["tip"] = s_field[0];
          this.editedItem["master_id"] = s_field[1];
          this.editedItem["bit"] = "";
          this.editedItem["birim"] = s_field[2];
          break;
        case "4":
          this.editedItem["tip"] = s_field[0];
          this.editedItem["master_id"] = s_field[1];
          this.editedItem["bit"] = "";
          this.editedItem["birim"] = s_field[2];
          break;
        case "8":
          this.editedItem["tip"] = s_field[0];
          this.editedItem["master_id"] = s_field[1];
          this.editedItem["bit"] = "";
          this.editedItem["birim"] = s_field[2] === "" ? "" : s_field[2];
          break;
      }
    }
  }
};
</script>

<style scoped></style>
