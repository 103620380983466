<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Item to page: {{ editedItem.page }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Bileşen seç"
                  v-model="childItemModel['componentName']"
                  :items="mobileComponents"
                  item-text="label"
                  item-value="component"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <component
                  :is="childItemModel['componentName']"
                  :bagli-alanlar="bagliAlanItems"
                  :animation-types="animationTypes"
                  :form-model="childItemModel"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveDialog">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters class="mb-12">
      <v-col class="col-4">
        <v-text-field label="Sayfa Adı" v-model="pageName" />
        <v-btn @click="addNewPage">Yeni Sayfa Ekle</v-btn>
        <v-btn @click="addToScada">Scada Bileşenine Ekle</v-btn>
      </v-col>
      <v-col class="col-8">
        <v-list v-if="pages.length > 0">
          <v-list-item
            v-for="item in pages"
            :key="item.id"
            prepend-icon="mdi-folder"
            class="my-2"
          >
            <v-card no-gutters width="100%">
              <v-row class="px-4 py-4" no-gutters>
                <v-list-item-content>
                  <!--                  <v-list-item-title v-model="item.page" contenteditable="true" v-text="item.page"></v-list-item-title>-->
                  <v-text-field
                    label="Sayfa adı"
                    v-model="item.page"
                    dense
                    hide-details
                  />
                </v-list-item-content>
                <v-list-item-action class="mx-2">
                  <v-btn @click="addNewItem(item)" icon>
                    <v-icon color="green">mdi-plus</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action class="mx-2">
                  <v-btn @click="removeItem(item)" icon>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-row>
              <v-divider />
              <v-row class="mt-0" no-gutters>
                <v-list-item
                  v-for="child in item['components']"
                  :key="child.id"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="child.component_label"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="mx-2">
                    <v-btn @click="editChildItem(child)" icon>
                      <v-icon color="navy">mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action class="mx-2">
                    <v-btn @click="removeChildItem(item,child)" icon>
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-row>
            </v-card>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import store from "@/vuex_store/store_wrapper";
import ElevatorComponent from "@/components/ModemLayoutWizard/ModemLayoutSettings/MobileEditorForms/ElevatorComponent";
import EscalatorSingle from "@/components/ModemLayoutWizard/ModemLayoutSettings/MobileEditorForms/EscalatorSingle";
import EscalatorDouble from "@/components/ModemLayoutWizard/ModemLayoutSettings/MobileEditorForms/EscalatorDouble";
import HorizontalLabelComponent
  from "@/components/ModemLayoutWizard/ModemLayoutSettings/MobileEditorForms/HorizontalLabelComponent";
import PushButtonComponent
  from "@/components/ModemLayoutWizard/ModemLayoutSettings/MobileEditorForms/PushButtonComponent";
import SwitchComponent from "@/components/ModemLayoutWizard/ModemLayoutSettings/MobileEditorForms/SwitchComponent";
import DigitalIndicator from "@/components/ModemLayoutWizard/ModemLayoutSettings/MobileEditorForms/DigitalIndicator";

export default {
  name: "MobileEditor",
  components: {
    EscalatorSingle,
    ElevatorComponent,
    EscalatorDouble,
    HorizontalLabelComponent,
    PushButtonComponent,
    SwitchComponent,
    DigitalIndicator
  },
  props: {
    modemId: Array,
    mobileData: {
      required: false,
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      unsubscribe: null,
      pageName: "",
      pages: [],
      mobile: [],
      mobileComponents: [
        {
          label: "Asansör",
          component: "ElevatorComponent"
        },
        {
          label: "Yürüyen Merdiven Tekli",
          component: "EscalatorSingle"
        },
        {
          label: "Yürüyen Merdiven İkili",
          component: "EscalatorDouble"
        },
        {
          label: "Yatay Etiket",
          component: "HorizontalLabelComponent"
        },
        {
          label: "Push Button",
          component: "PushButtonComponent"
        },
        {
          label: "Switch",
          component: "SwitchComponent"
        },
        {
          label: "Dijital Gösterge",
          component: "DigitalIndicator"
        }
      ],
      dialog: false,
      editedItem: {},
      childItemModel: {
        input_label:"",
        icon_type: "",
        component_label: "",
        bagli_alan: [],
        componentName: "",
        animation_type: "",
        id: ""
      },
      bagliAlanItems: [],
      animationTypes: [
        "Kırmızı-Yeşil",
        "Yeşil-Kırmızı",
        "Gri-Yeşil",
        "Yeşil-Gri",
          "Açık-Kapalı",
        "Kapalı-Açık",
      ],
      colors: {
        kirmiziYesil: ["#FA5151", "#24C263"],
        yesilKirmizi: ["#24C263", "#FA5151"],
        yesilGri: ["#24C263", "#DCDCDC"],
        griYesil: ["#DCDCDC", "#24C263"]
      }
    };
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.pages = this.mobileData;
    console.log("MOBILE DATA", this.mobileData);
    this.unsubscribe = store.subscribe(this.storeHandler);
    console.log("MODEM ID => ", this.modemId);
    let getModemIOList = {
      type: "getModemIOList",
      payload: {
        ModemId: parseInt(this.modemId[0]),
        Secim: "1@2@3@4@5@6@7@8@9"
      }
    };
    this.sendMessageToWS(getModemIOList);
  },
  methods: {
    addNewPage: function() {
      this.pages.push({
        id: uuidv4(),
        page: this.pageName,
        components: []
      });
      this.$emit("addToLayout", this.pages);
    },
    addNewItem: function(item) {
      this.dialog = true;
      console.log("Edited Item", item);
      this.editedItem = item;
      this.$emit("addToLayout", this.pages);
    },
    removeItem: function(item) {
      const removedIndex = this.pages.findIndex(v => item.id === v.id);
      console.log("Removed Item Index", removedIndex);
      this.pages.splice(removedIndex, 1);
      this.$emit("addToLayout", this.pages);
    },
    editChildItem: function(child) {
      this.dialog = true;
      this.childItemModel = child;
      this.$emit("addToLayout", this.pages);
    },
    removeChildItem: function (parent,child) {
      const parentIndex = this.pages.findIndex(v => parent.id === v.id);
      console.log("Parent Index Is:", parentIndex)
      const childIndex = this.pages[parentIndex]['components'].findIndex(v => child.id === v.id);
      console.log("Removed Child Item Index", childIndex);
      this.pages[parentIndex]['components'].splice(childIndex, 1);
      this.$emit("addToLayout", this.pages);
    },
    closeDialog: function() {
      this.editedItem = {};
      this.childItemModel = {};
      this.dialog = false;
    },
    saveDialog: function() {
      this.pages = this.pages.map(v => {
        if (v.id === this.editedItem.id) {
          this.childItemModel["id"] = uuidv4();
          v.components.push(this.childItemModel);
          console.log("Adding the item to ", v);
        }
        return v;
      });
      console.log("Component Added To Pages", this.pages);
      this.dialog = false;
      this.childItemModel = {};
      this.editedItem = {};
      this.$emit("addToLayout", this.pages);
    },
    addToScada: function() {
      console.log("Add To Scada", this.pages);
      this.$emit("addToLayout", this.pages);
    },
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          const { Elements } = data;
          console.log("Modem Inputlar", data);
          this.bagliAlanItems = Object.keys(Elements).map(key => {
            return {
              Text: Elements[key],
              Id: key
            };
          });
        }
      }
    }
  }
};
</script>

<style scoped></style>
