<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="svgDimen"
      />
    </v-row>

    <v-row no-gutters>
      <v-col class="col-12">
        <v-menu
          ref="cpRef"
          v-model="colorMenuPBSpinner"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Spinner Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['spinner_color']"
            >
              <v-icon
                :color="`${currentItem['spinner_color']}`"
                slot="prepend"
                class="oneLabelIcon"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['spinner_color']"
            @input="changeSpinnerColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 px-1">
        <v-text-field
          type="number"
          @input="changeLabelSize"
          label="Etiket Yazı Büyüklüğü"
          v-model="currentItem['etiketFontSize']"
        />
      </v-col>
      <v-col class="col-6 px-1">
        <v-text-field
          @input="changeLabel"
          label="Button Etiket"
          v-model="currentItem['elementLabel']"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-autocomplete
        label="Animasyon Tipi (0 - 1 şeklinde düşünülmeli)"
        :items="animationTypes"
        v-model="currentItem['animation_type']"
      />
    </v-row>

    <v-row no-gutters>
      <v-autocomplete
        v-model="currentItem['bagli_alan']"
        item-text="Text"
        item-value="Id"
        label="Bağlı Alan"
        :items="bagliAlanItems"
      />
    </v-row>

    <v-row no-gutters>
      <v-text-field label="Timeout (MS)" v-model="currentItem['timeoutMS']" />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changeSvgDimension,
  findBinaryValue
} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "pushButtonModern",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      colorMenuPBSpinner: false,
      pushButtonValue: "0",
      animationTypes: [
        "Kırmızı-Yeşil",
        "Yeşil-Kırmızı",
        "Gri-Yeşil",
        "Yeşil-Gri"
      ],
      colors: {
        kirmiziYesil: ["#F34A4A", "#55D278"],
        yesilKirmizi: ["#55D278", "#F34A4A"],
        yesilGri: ["#55D278", "#b0b0b0"],
        griYesil: ["#b0b0b0", "#55D278"]
      },
      bagliAlanItems: [],
      unsubscribe: null,
      isButtonPressed: false,
      pushButtonTimeout: null
    };
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    },
    getBaseGroupId: function() {
      if (this.parentName === "ModemTabLayout") {
        return document
          .getElementById(this.svgItemObject["id"])
          .getElementsByTagName("svg")[0];
      } else {
        return document
          .getElementById(this.currentItem["id"])
          .getElementsByTagName("svg")[0];
      }
    },
    getEtiket: function() {
      const svgBase = this.getBaseGroupId;
      return svgBase.querySelector("#pb_label");
    },
    getSpinner: function() {
      const svgBase = this.getBaseGroupId;
      return svgBase.querySelector("#pb_spinner");
    },
    getClickGroup: function() {
      const svgBase = this.getBaseGroupId;
      return svgBase.querySelector("#pb_click_group");
    },
    getButtonBackground: function() {
      const svgBase = this.getBaseGroupId;
      const buttonGroup = svgBase.querySelector("#pb_colorful");
      return buttonGroup.getElementsByTagName("circle")[0];
    },
    getButtonOverlay: function() {
      const svgBase = this.getBaseGroupId;
      return svgBase.querySelector("#pb_overlay");
    }
  },
  beforeDestroy() {
/*    if (this.parentName === 'ModemTabLayout') {
      console.log("ERROR HERE REMOVING EVENT LISTENERS !")
      this.getClickGroup.removeEventListener("click", this.pbClickEvent);
    }*/
    this.unsubscribe && this.unsubscribe();
    this.pushButtonTimeout !== null && clearTimeout(this.pushButtonTimeout);
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    if (this.parentName === "ModemTabLayout") {
      this.getClickGroup.addEventListener("click", this.pbClickEvent, false);
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
      console.log("Push Button Data Çözülecek", this.dataProperty);
    }
  },
  methods: {
    pbClickEvent: function() {
      if (!this.isButtonPressed) {
        this.isButtonPressed = true;

        this.getButtonOverlay.setAttribute(
          "style",
          "fill-opacity:.75; transition: .45s ease-in-out"
        );
        this.getSpinner.classList.add("spin");
        console.log("Spinner Classlist", this.getSpinner.classList)

        const { bagli_alan, timeoutMS } = this.svgItemObject;
        const [tip, master_id, bit] = bagli_alan.split("_");
        const setOutput = {
          type: "setModemOutput",
          payload: {
            Target: `${tip}_${master_id}_${bit}`,
            Value: 1,
            ModemId: this.modemID
          }
        };

        console.log("Message Object is: ", setOutput);
        this.sendMessageToWS(setOutput);
        //Start Timeout after message
        this.pushButtonTimeout = setTimeout(this.pbTimeout, Number(timeoutMS));
      } else {
        //Cannot click the button
        console.log("Cannot click the button at this time");
      }
    },
    pbTimeout: function() {
      //Set button disabled state to false after timeout is done
      this.isButtonPressed = false;
      this.getButtonOverlay.setAttribute(
        "style",
        "fill-opacity:0; transition: .45s ease-in-out"
      );
      this.getSpinner.classList.remove("spin");
      console.log("Push Button New - Timeout Done !");
    },
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          const { TargetID, Elements } = data;
          const { id } = this.parentName === "ModemLayoutWizard" ? this.currentItem : this.svgItemObject;
          if (id === TargetID) {
            console.log("Modem Inputlar", data);
            this.bagliAlanItems = Object.keys(Elements).map(key => {
              return {
                Text: Elements[key],
                Id: key
              };
            });
          }
        } else if (command === "ProcessData") {
          const { ModemId, Datas } = data;
          if (ModemId === this.modemID) {
            console.log("Push Button Data Received !");
            this.setScadaData(ModemId, Datas);
          }
        }
      }
    },
    setScadaData: function(ModemId, Datas) {
      if (Object.keys(Datas).length > 0) {
        const { bagli_alan } = this.svgItemObject;
        const [tip, master, bit] = bagli_alan.split("_");
        if (Datas.hasOwnProperty(`${tip}_${master}`)) {
          console.log("PushButton Tip MasterId Bit", tip, master, bit);
          this.pushButtonValue = findBinaryValue(
            Datas[`${tip}_${master}`],
            bit
          );
          console.log("PushButton Value", this.pushButtonValue);
          this.buttonAnimFilter();
        }
      }
    },
    buttonAnimFilter: function() {
      const { animation_type } = this.svgItemObject;
      this.pushButtonTimeout && clearTimeout(this.pushButtonTimeout);
      this.isButtonPressed = false;
      this.getButtonOverlay.setAttribute("style","fill-opacity:0; transition: .4s ease-in-out;");
      this.getSpinner.classList.remove("spin");

      switch (animation_type) {
        case "Kırmızı-Yeşil":
          this.kirmiziYesilAnim();
          break;
        case "Yeşil-Kırmızı":
          this.yesilKirmiziAnim();
          break;
        case "Gri-Yeşil":
          this.griYesilAnim();
          break;
        case "Yeşil-Gri":
          this.yesilGriAnim();
          break;
      }
    },
    kirmiziYesilAnim: function() {
      if (this.pushButtonValue === "0") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.kirmiziYesil[0]}; transition: .4s ease-in-out;`
        );
      } else if (this.pushButtonValue === "1") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.kirmiziYesil[1]}; transition: .4s ease-in-out;`
        );
      }
    },
    yesilKirmiziAnim: function() {
      if (this.pushButtonValue === "0") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.yesilKirmizi[0]}; transition: .4s ease-in-out;`
        );
      } else if (this.pushButtonValue === "1") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.yesilKirmizi[1]}; transition: .4s ease-in-out;`
        );
      }
    },
    griYesilAnim: function() {
      if (this.pushButtonValue === "0") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.griYesil[0]}; transition: .4s ease-in-out;`
        );
      } else if (this.pushButtonValue === "1") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.griYesil[1]}; transition: .4s ease-in-out;`
        );
      }
    },
    yesilGriAnim: function() {
      if (this.pushButtonValue === "0") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.yesilGri[0]}; transition: .4s ease-in-out;`
        );
      } else if (this.pushButtonValue === "1") {
        this.getButtonBackground.setAttribute(
          "style",
          `fill:${this.colors.yesilGri[1]}; transition: .4s ease-in-out;`
        );
      }
    },
    changeLabelSize: function() {
      const tspan = this.getEtiket.getElementsByTagName("tspan")[0];
      tspan.setAttribute("font-size", this.currentItem["etiketFontSize"]);
    },
    changeLabel: function() {
      const tspan = this.getEtiket.getElementsByTagName("tspan")[0];
      tspan.innerHTML = this.currentItem["elementLabel"];
    },
    changeSpinnerColor: function() {
      this.getSpinner.setAttribute(
        "stroke",
        `${this.currentItem["spinner_color"]}`
      );
    },
    getBagliAlanInputs: function() {
      let getModemIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "2@3@4@5"
        }
      };
      this.sendMessageToWS(getModemIOList);
    }
  }
};
</script>

<style scoped>
</style>
