<template>
  <v-row no-gutters>
    <v-form class="col-12" ref="single-form" v-model="valid">
      <v-row no-gutters>
        <v-col
          class="pa-2"
          :class="
            $vuetify.breakpoint.name === 'sm' ||
            $vuetify.breakpoint.name === 'xs'
              ? 'col-12'
              : `col-${12 / fieldsData['ColumnCount']}`
          "
          v-for="(fields, fieldIndex) in fieldsData['Fields']"
          :key="fieldIndex"
        >
          <template v-for="field in fields">
            <v-text-field
              v-if="field['ElementType'] === 'text'"
              :key="field['Field']"
              :label="field['Label']"
              v-model="stickyData[field['Field']]"
              readonly
            />
            <v-text-field
              v-if="field['ElementType'] === 'password'"
              :key="field['Field']"
              :label="field['Label']"
              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_password ? 'text' : 'password'"
              @click:append="show_password = !show_password"
              v-model="stickyData[field['Field']]"
              readonly
            />
            <v-autocomplete
              v-if="
                field['ElementType'] === 'combo' &&
                  !field['CustomData'].hasOwnProperty('check_scope')
              "
              :label="field['Label']"
              :key="field['Field']"
              :items="field['ComboItems']['Elements']"
              item-value="Id"
              item-text="Text"
              :value="
                stickyData.hasOwnProperty(field['Field']) &&
                  Number(stickyData[field['Field']])
              "
              readonly
            />

            <v-autocomplete
              v-if="
                field['ElementType'] === 'combo' &&
                  field['CustomData'].hasOwnProperty('check_scope')
              "
              :label="field['Label']"
              :key="field['Field']"
              :items="field['ComboItems']['Elements']"
              item-value="Id"
              item-text="Text"
              readonly
              :value="
                stickyData.hasOwnProperty(field['Field']) &&
                  Number(stickyData[field['Field']])
              "
            />

            <modem-permissions-comp
              v-if="field['ElementType'] === 'ModemSelectList'"
              :id-property="stickyData[field['Field']]"
              :key="field['Field']"
              :read-only="true"
            />

            <text-editor-component
              v-if="field['ElementType'] === 'editor'"
              :key="field['Field']"
              :read-only="true"
              :field="field"
              :dynamic-m="stickyData"
            /><!-- v-if="field['ElementType'] === 'fileSelectFromServer'" -->

            <file-upload-comp
              v-if="field['ElementType'] === 'fileSelectFromServer'"
              :key="field['Field']"
              :field="field"
              :read-only="true"
              :field-item="field"
              :dynamic-m="stickyData"
              v-model="stickyData[field['Field']]"
            />

            <modem-field-selector
              :key="field['Field']"
              v-if="field['ElementType'] === 'modemFieldSelector'"
              action-type="incele"
              :field-item="field"
              :dynamic-m="stickyData"
              v-model="stickyData[field['Field']]"
              :read-only="true"
            />

            <p-id-field-selector-component
              :key="field['Field']"
              v-if="field['ElementType'] === 'pidFieldSelector'"
              :field-item="field"
              :dynamic-m="stickyData"
              :read-only="true"
              action-type="incele"
            />

            <watch-field-selector-component
              :key="field['Field']"
              v-if="field['ElementType'] === 'watchFieldSelector'"
              :field-item="field"
              :dynamic-m="stickyData"
              :read-only="true"
              action-type="incele"
            />

            <label-edit-text-component
              :key="field['Field']"
              v-if="field['ElementType'] === 'labelEditText'"
              :field="field"
              :read-only-value="
                stickyData[field['CustomData']['target']] !== ''
                  ? JSON.parse(
                      decodeURIComponent(
                        stickyData[field['CustomData']['target']]
                      )
                    )[field['CustomData']['id']]
                  : ''
              "
              :data-value-model="stickyData"
              action-type="incele"
            />

            <interactive-list-component
              :field="field"
              :model="stickyData"
              :interactive-target-value="
                Number(stickyData[field['CustomData']['filterField']])
              "
              :key="field['Field']"
              v-if="field['ElementType'] === 'interactiveList'"
              action-type="incele"
            />

            <i-o-component
              :key="field['Field']"
              v-if="field['ElementType'] === 'listConfigWizard'"
              action-type="duzenle"
              :dynamic-m="stickyData"
              :field-item="field"
              :modem-i-d="stickyData['modem_id']"
              :read-only="true"
            />

            <alarm-wizard
              :key="field['Field']"
              v-if="field['ElementType'] === 'alarmwizard'"
              :field-name="field['Field']"
              :config-object="JSON.parse(stickyData[field['Field']])"
              :read-only="true"
            />

            <linked-list-table
              :key="field['Field']"
              v-if="field['ElementType'] === 'linkedTable'"
              :field-item="field"
              :master-table-selected-row="[
                stickyData[field['CustomData']['DefaultFilter']]
              ]"
            />
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-row>
</template>

<script>
import ModemPermissionsComp from "@/components/ErisilebilirModemler/ModemPermissionsComp";
import TextEditorComponent from "@/components/TextEditorComponent/TextEditorComponent";
import LabelEditTextComponent from "@/components/LabelEditText/LabelEditTextComponent";
import FileUploadComp from "@/components/FileSelector/FileUploadComp";
import ModemFieldSelector from "@/components/ModemFieldSelector/ModemFieldSelector";
import pIdFieldSelectorComponent from "@/components/pIdFieldSelector/pIdFieldSelectorComponent";
import watchFieldSelectorComponent from "@/components/watchFieldSelector/watchFieldSelectorComponent";
import InteractiveListComponent from "@/components/InteractiveList/InteractiveListComponent";
import IOComponent from "@/components/EditorUtilityComponents/IOComponent";
import AlarmWizard from "@/components/AlarmWizard/AlarmWizard";

export default {
  name: "InceleForm",
  components: {
    TextEditorComponent,
    ModemPermissionsComp,
    LabelEditTextComponent,
    FileUploadComp,
    ModemFieldSelector,
    pIdFieldSelectorComponent,
    watchFieldSelectorComponent,
    InteractiveListComponent,
    IOComponent,
    AlarmWizard
  },
  props: {
    fieldsData: {
      type: Object,
      required: false
    },
    stickyData: {
      type: Object,
      required: false
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      show_password: false
    };
  }
};
</script>

<style scoped></style>
