<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <p>Jenerator Standart</p>
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="changeSizeOfSVG"
      />
    </v-row>

    <v-row class="mb-2" no-gutters>
      <span>Auto Ready</span>
      <v-row no-gutters>
        <v-col class="col-6">
          <v-select
            item-text="Text"
            item-value="Id"
            :items="modbusModemInputsList"
            label="Bağlı alan seçiniz"
            v-model="currentItem['auto_ready']"
          />
        </v-col>
        <v-col class="col-6">
          <v-select
            :items="['Off-On', 'On-Off']"
            label="Animasyon Tipi"
            v-model="currentItem['autoReadyAnimationType']"
          />
        </v-col> </v-row
    ></v-row>

    <v-row class="mb-2" no-gutters>
      <span>Alarm</span>
      <v-row no-gutters>
        <v-col class="col-6">
          <v-select
            item-text="Text"
            item-value="Id"
            :items="modbusModemInputsList"
            label="Bağlı alan seçiniz"
            v-model="currentItem['alarm']"
          />
        </v-col>
        <v-col class="col-6">
          <v-select
            :items="['Off-On', 'On-Off']"
            label="Animasyon Tipi"
            v-model="currentItem['alarmAnimationType']"
          />
        </v-col>
      </v-row>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <span>Warning</span>
      <v-row no-gutters>
        <v-col class="col-6">
          <v-select
            item-text="Text"
            item-value="Id"
            :items="modbusModemInputsList"
            label="Bağlı alan seçiniz"
            v-model="currentItem['warning']"
          />
        </v-col>
        <v-col class="col-6">
          <v-select
            :items="['Off-On', 'On-Off']"
            label="Animasyon Tipi"
            v-model="currentItem['warningAnimationType']"
          />
        </v-col>
      </v-row>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <span>Service Request</span>
      <v-row no-gutters>
        <v-col class="col-6">
          <v-select
            item-text="Text"
            item-value="Id"
            :items="modbusModemInputsList"
            label="Bağlı alan seçiniz"
            v-model="currentItem['service_request']"
          />
        </v-col>
        <v-col class="col-6">
          <v-select
            :items="['Off-On', 'On-Off']"
            label="Animasyon Tipi"
            v-model="currentItem['serviceRequestAnimationType']"
          />
        </v-col>
      </v-row>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <v-col class="col-12">
        <span>Fuel</span>
        <v-select
          item-text="Text"
          item-value="Id"
          :items="modbusModemFuelList"
          label="Bağlı alan seçiniz"
          v-model="currentItem['fuel']"
        />
      </v-col>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <v-col class="col-12">
        <span>Alarm List</span>
        <v-select
          item-text="Text"
          item-value="Id"
          :items="modbusModemAlarmList"
          label="Bağlı alan seçiniz"
          v-model="currentItem['alarm_list']"
        />
      </v-col>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <v-col class="col-12">
        <span>Test</span>
        <v-select
          item-text="Text"
          item-value="Id"
          :items="modbusModemButtonList"
          label="Bağlı alan seçiniz"
          v-model="currentItem['test']"
        />
      </v-col>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <v-col class="col-12">
        <span>Run</span>
        <v-select
          item-text="Text"
          item-value="Id"
          :items="modbusModemButtonList"
          label="Bağlı alan seçiniz"
          v-model="currentItem['run']"
        />
      </v-col>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <v-col class="col-12">
        <span>Auto</span>
        <v-select
          item-text="Text"
          item-value="Id"
          :items="modbusModemButtonList"
          label="Bağlı alan seçiniz"
          v-model="currentItem['auto']"
        />
      </v-col>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <v-col class="col-12">
        <span>Off</span>
        <v-select
          item-text="Text"
          item-value="Id"
          :items="modbusModemButtonList"
          label="Bağlı alan seçiniz"
          v-model="currentItem['off']"
        />
      </v-col>
    </v-row>

    <v-row class="mb-2" no-gutters>
      <v-col class="col-12">
        <span>Çalışma Durumu</span>
        <v-select
          item-text="Text"
          item-value="Id"
          :items="modbusModemCalismaDurumList"
          label="Bağlı alan seçiniz"
          v-model="currentItem['calisma_durum']"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changeSvgDimension,
  getSVGChildElements
} from "./svgComponentUtils/svgUtilFunctions";

export default {
  name: "jeneratorStd",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    fileList: {
      type: Array,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      svgNamespace: "http://www.w3.org/2000/svg",
      modbusModemInputsList: [],
      modbusModemFuelList: [],
      modbusModemAlarmList: [],
      modbusModemButtonList: [],
      modbusModemCalismaDurumList: [],
      ioListData: [],
      //svg jeneratör dom elements
      jeneratorDOMElements: {},
      //combobox models
      setModemOutputs: {
        type: "setModemOutput",
        payload: { Target: "", Value: -1 }
      },

      lastAnimatedCircleIndicator: null,

      jeneratorFuelHeightStyle: ""
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected",
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    getListSetupComps: function() {
      return this.listSetup["comps"];
    },
    getDataProperty: function() {
      return this.dataProperty;
    },
    /*
     * Jenerator Animation Style Properties
     * */
    jeneratorFuelAnimation: {
      get: function() {
        return this.jeneratorFuelHeightStyle;
      },
      set: function(height) {
        const maxHeightOfFuel = 392;
        let fuelHeight = (maxHeightOfFuel * Number(height)) / 100;
        let translateValue = maxHeightOfFuel - fuelHeight;
        this.jeneratorFuelHeightStyle = `height:${fuelHeight}px; transform: translateY(${translateValue}px); transition:1s ease-in-out`;
      }
    },
    jeneratorStatusAnimations: function() {
      return {
        autoReady: {
          on: `fill:#2193F7; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        },
        alarm: {
          on: `fill:#E04343; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        },
        warning: {
          on: `fill:#E04343; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        },
        serviceRequest: {
          on: `fill:#E04343; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        }
      };
    }
  },
  created() {
    //Called from admin scada component
    if (this.parentName !== "ModemTabLayout") {
      this.getModemIOList();
      this.getModbusInputs();
      this.getModbusFuel();
      this.getModbusAlarmList();
      this.getModbusButtons();
      this.getModbusCalisma();
    }
  },
  mounted() {
    //Called from modem tab layout component in Stations.
    if (this.parentName === "ModemTabLayout") {
      //here get svg elements and set them to an object.
      this.jeneratorDOMElements = this.getSVGElements();
      console.log("Svg item obj", this.svgItemObject);
      console.log("ModemID", this.modemID);
      console.log("getDataProperty Components", this.getDataProperty);

      const { Datas } = this.getDataProperty;

      Object.keys(Datas).forEach(datasKey => {
        /*eslint-disable*/
        const [master_id, tip] = datasKey.split("_");
        //console.log("Splitted: ", master_id, tip, key)

        Object.keys(this.svgItemObject).forEach(key => {
          const value = this.svgItemObject[key];

          if (value === parseInt(tip)) {
            /*console.log("Exists in svgItemObject Key", value)
            console.log("Exists in svgItemObject Value", Datas[datasKey])*/
            this.setFrontendSVG()[value](Datas[datasKey]);
          }
        });

        if (tip === "63") {
          this.setFrontendSVG()[tip](Datas[datasKey]);
        }
      });
    }
  },
  watch: {
    getterSocketMessage: function(value) {
      const { command, data } = JSON.parse(value.data);
      if (command === "ModemIOList") {
        this.ioListData = [];
        const { Elements } = data;
        this.ioListData = Object.entries(Elements).map(([key, value]) => {
          return {
            Id: key,
            Text: value
          };
        });
        console.log(this.ioListData);
      } else if (command === "ProcessData") {
        //const { data: process_data } = data;
        const { ModemId, Datas } = data;
        if (ModemId === this.modemID) {
          console.log(
            `%cGelen Jeneratör Update Modem ID: ${ModemId} - Gelen Data -> ${Datas} `,
            "color:hotpink"
          );
          this.parseProcessData(Datas);
        }
      } else if (command === "ModbusComponentsFromFormatResponse") {
        const { Source, List } = data;
        const { Elements } = List;
        console.log("List", List);
        switch (Source) {
          case "ModbusModemInputs":
            Elements !== null
              ? (this.modbusModemInputsList = Elements)
              : (this.modbusModemInputsList = []);
            break;
          case "ModbusFuel":
            Elements !== null
              ? (this.modbusModemFuelList = Elements)
              : (this.modbusModemFuelList = []);
            console.log("FUEL LIST", this.modbusModemFuelList, this.ioListData);
            if (this.ioListData.length > 0) {
              this.modbusModemFuelList = [
                ...this.modbusModemFuelList,
                ...this.ioListData
              ];
            }
            break;
          case "ModbusAlarmList":
            Elements !== null
              ? (this.modbusModemAlarmList = Elements)
              : (this.modbusModemAlarmList = []);
            break;
          case "ModbusButtons":
            Elements !== null
              ? (this.modbusModemButtonList = Elements)
              : (this.modbusModemButtonList = []);
            break;
          case "ModbusCalisma":
            Elements !== null
              ? (this.modbusModemCalismaDurumList = Elements)
              : (this.modbusModemCalismaDurumList = []);
            break;
          default:
        }
      }
    }
  },
  methods: {
    /*
     * deconstruct svg elements from svg <g>
     * */
    getSVGElements: function() {
      /* TEXT ALARM GROUP */
      const textGroupElement = document.createElementNS(
        this.svgNamespace,
        "text"
      );
      textGroupElement.setAttribute("fill", "white");
      textGroupElement.setAttribute("x", "300");
      textGroupElement.setAttribute("y", "160");
      textGroupElement.setAttribute("font-family", "Fira Sans");
      textGroupElement.setAttribute("font-weight", "normal");
      textGroupElement.setAttribute("font-size", "24");
      textGroupElement.setAttribute("text-anchor", "start");
      textGroupElement.setAttribute("id", "textAlarmGroup");

      /*STATUS TEXT*/
      const textGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_durum_text_group"
      );
      const statusTextTspan = textGroup.querySelectorAll("tspan")[1];

      /* FUEL */
      const fuelIndicator = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_fuel_bar_indicator"
      );

      //ALARM LIST BASE
      const alarmListBase = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_alarm_liste_base"
      );

      /* STATUS */
      const statusAutoReadyGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_status_auto_ready_group"
      );
      const statusAlarmGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_status_alarm_group"
      );
      const statusWarningGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_status_warning_group"
      );
      const statusServiceRequestGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_status_service_request_group"
      );

      /* BUTTONS */
      const buttonTestGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_button_test"
      );
      buttonTestGroup.addEventListener("click", this.buttonTestClick, false);
      const buttonTestIndicator = buttonTestGroup.querySelector(
        "#jenerator_button_test_indicator"
      );

      const buttonManRunGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_button_run"
      );
      buttonManRunGroup.addEventListener("click", this.buttonRunClick, false);
      const buttonManRunIndicator = buttonManRunGroup.querySelector(
        "#jenerator_button_run_indicator"
      );

      const buttonAutoGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_button_auto"
      );
      buttonAutoGroup.addEventListener("click", this.buttonAutoClick, false);
      const buttonAutoIndicator = buttonAutoGroup.querySelector(
        "#jenerator_button_auto_indicator"
      );

      const buttonOffGroup = getSVGChildElements(
        this.svgItemObject,
        "#jenerator_button_off"
      );
      buttonOffGroup.addEventListener("click", this.buttonOffClick, false);
      const buttonOffIndicator = buttonOffGroup.querySelector(
        "#jenerator_button_off_indicator"
      );

      return {
        buttonOffGroup,
        buttonAutoGroup,
        buttonManRunGroup,
        buttonTestGroup,
        buttonOffIndicator,
        buttonAutoIndicator,
        buttonManRunIndicator,
        buttonTestIndicator,
        statusAutoReadyGroup,
        statusAlarmGroup,
        statusWarningGroup,
        statusServiceRequestGroup,
        fuelIndicator,
        statusTextTspan,
        alarmListBase,
        textGroupElement
      };
    },
    changeSizeOfSVG: function(value) {
      console.log("CUrr", value);
      changeSvgDimension(this.currentItem);
    },
    getModemIOList: function() {
      let requestIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "3@4@8"
        }
      };
      this.sendMessageToWS(requestIOList);
    },
    getModbusInputs: function() {
      //get auto-ready, alarm, warning, service-request values
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusModemInputs",
          Formats: [1, 2, 4, 5, 6, 7]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusFuel: function() {
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusFuel",
          Formats: [1, 2, 3, 4, 5, 6, 7, 8]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusAlarmList: function() {
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusAlarmList",
          Formats: [9]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusButtons: function() {
      //Test, Run, Auto, Off outputs
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 2,
          Target: "ModbusButtons",
          Formats: [1, 2, 5]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusCalisma: function() {
      //Test, Run, Auto, Off outputs
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusCalisma",
          Formats: [10]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    buttonTestClick: function() {
      let setModemOutputs = {
        type: "setModemOutput",
        payload: {
          ModemId: this.modemID,
          Target: "8_0_" + this.svgItemObject["test"],
          Value: 1
        }
      };
      this.sendMessageToWS(setModemOutputs);
    },
    buttonRunClick: function() {
      let setModemOutputs = {
        type: "setModemOutput",
        payload: {
          ModemId: this.modemID,
          Target: "8_0_" + this.svgItemObject["run"],
          Value: 1
        }
      };
      this.sendMessageToWS(setModemOutputs);
    },
    buttonAutoClick: function() {
      let setModemOutputs = {
        type: "setModemOutput",
        payload: {
          ModemId: this.modemID,
          Target: "8_0_" + this.svgItemObject["auto"],
          Value: 1
        }
      };
      console.log("AUTO REQUEST", setModemOutputs);
      this.sendMessageToWS(setModemOutputs);
    },
    buttonOffClick: function() {
      let setModemOutputs = {
        type: "setModemOutput",
        payload: {
          ModemId: this.modemID,
          Target: "8_0_" + this.svgItemObject["off"],
          Value: 1
        }
      };
      this.sendMessageToWS(setModemOutputs);
    },
    parseProcessData: function(datas) {
      console.log("DATAS Gelen", datas);
      //datas => type of object
      for (const key in datas) {
        let splitted = key.split("_");
        /*eslint-disable*/
        let [modbus, modbusFunctionID, unit] = splitted;
        let modbusFunctionData = datas[key];
        //Burası neden ayrı. 63 this.svgItemObject içerisinde olmadığı için ayrı şekilde kontrol ediyoruz.
        if (modbusFunctionID === "63") {
          this.setFrontendSVG()[modbusFunctionID](modbusFunctionData);
        }
        Object.keys(this.svgItemObject).forEach(value => {
          if (Number(modbusFunctionID) === this.svgItemObject[value]) {
            this.setFrontendSVG()[modbusFunctionID](modbusFunctionData);
          }
        });
      }
    },
    setFrontendSVG: function() {
      console.log("Set FRONTEND Called", this.svgItemObject);
      const {
        alarm,
        alarm_list,
        auto,
        auto_ready,
        durum,
        fuel,
        off,
        run,
        service_request,
        test,
        warning,
        calisma_durum
      } = this.svgItemObject;

      return {
        [alarm]: Data => this.setAlarm(Data),
        [alarm_list]: Data => this.setAlarmList(Data),
        [auto]: Data => console.log("Auto", Data),
        [auto_ready]: Data => this.setAutoReady(Data),
        [durum]: Data => this.setDurumText(Data),
        [fuel]: Data => this.setFuel(Data),
        [off]: Data => console.log("Off", Data),
        [run]: Data => console.log("Run", Data),
        [service_request]: Data => this.setServiceRequest(Data),
        [test]: Data => console.log("Test", Data),
        [warning]: Data => this.setWarning(Data),
        [calisma_durum]: Data => this.setDurumText(Data),
        ["63"]: Data => this.setCalismaDurum(Data)
      };
    },
    setDurumText: function(Data) {
      console.log("Durum Geldi ", Data, typeof Data);
      if (typeof Data === "string") {
        this.jeneratorDOMElements["statusTextTspan"].innerHTML = JSON.parse(
          Data
        )["tr"];
      } else {
        this.jeneratorDOMElements["statusTextTspan"].innerHTML = Data["tr"];
      }
    },
    // Set Warning in STATUS Column
    setWarning: function(Data) {
      const { warningAnimationType } = this.svgItemObject;
      if (Data === "1.000000") {
        this.jeneratorDOMElements["statusWarningGroup"].setAttribute(
          "style",
          warningAnimationType === "Off-On"
            ? this.jeneratorStatusAnimations["warning"]["on"]
            : this.jeneratorStatusAnimations["warning"]["off"]
        );
      } else {
        this.jeneratorDOMElements["statusWarningGroup"].setAttribute(
          "style",
          warningAnimationType === "Off-On"
            ? this.jeneratorStatusAnimations["warning"]["off"]
            : this.jeneratorStatusAnimations["warning"]["on"]
        );
      }
    },
    // Set Alarm in STATUS Column
    setAlarm: function(Data) {
      const { alarmAnimationType } = this.svgItemObject;
      if (Data === "1.000000") {
        this.jeneratorDOMElements["statusAlarmGroup"].setAttribute(
          "style",
            alarmAnimationType === "Off-On" ? this.jeneratorStatusAnimations["alarm"]["on"] :
                this.jeneratorStatusAnimations["alarm"]["off"]
        );
      } else {
        this.jeneratorDOMElements["statusAlarmGroup"].setAttribute(
          "style",
            alarmAnimationType === "Off-On" ? this.jeneratorStatusAnimations["alarm"]["off"] :
                this.jeneratorStatusAnimations["alarm"]["on"]
        );
      }
    },
    // Set Auto Ready in STATUS Column
    setAutoReady: function(Data) {
      console.log("SET AUTO READY", Data);
      const { autoReadyAnimationType } = this.svgItemObject;
      if (Data === "1.000000") {
        this.jeneratorDOMElements["statusAutoReadyGroup"].setAttribute(
          "style",
            autoReadyAnimationType === "Off-On" ? this.jeneratorStatusAnimations["autoReady"]["on"] :
                this.jeneratorStatusAnimations["autoReady"]["off"]
        );
      } else {
        this.jeneratorDOMElements["statusAutoReadyGroup"].setAttribute(
          "style",
            autoReadyAnimationType === "Off-On" ? this.jeneratorStatusAnimations["autoReady"]["off"] :
                this.jeneratorStatusAnimations["autoReady"]["on"]
        );
      }
    },
    // Set Service Request in STATUS Column
    setServiceRequest: function(Data) {
      console.log("SET SERVICE REQUEST", Data);
      const { serviceRequestAnimationType } = this.svgItemObject;
      if (Data === "1.000000") {
        this.jeneratorDOMElements["statusServiceRequestGroup"].setAttribute(
          "style",
            serviceRequestAnimationType === "Off-On" ? this.jeneratorStatusAnimations["serviceRequest"]["on"] :
                this.jeneratorStatusAnimations["serviceRequest"]["off"]
        );
      } else {
        this.jeneratorDOMElements["statusServiceRequestGroup"].setAttribute(
          "style",
            serviceRequestAnimationType === "Off-On" ? this.jeneratorStatusAnimations["serviceRequest"]["off"] :
                this.jeneratorStatusAnimations["serviceRequest"]["on"]
        );
      }
    },
    setAlarmList: function(Data) {
      //Alarm Data => Array of objects
      console.log("Alarm Data", Data, Data.length);
      if (Array.isArray(Data)) {
        let element = this.jeneratorDOMElements["textGroupElement"];
        if (element.hasChildNodes()) {
          while (element.firstChild) {
            element.removeChild(element.firstChild);
          }
        }

        Data.forEach(obj => {
          let tspanChild = document.createElementNS(this.svgNamespace, "tspan");
          tspanChild.setAttribute("dy", "2em");
          tspanChild.setAttribute("x", "300");
          tspanChild.innerHTML = obj["tr"];
          element.appendChild(tspanChild);
        });

        this.jeneratorDOMElements["alarmListBase"].appendChild(element);
      }
    },
    setFuel: function(Data) {
      this.jeneratorFuelAnimation = Data;
      this.jeneratorDOMElements["fuelIndicator"].setAttribute(
        "style",
        this.jeneratorFuelAnimation
      );
    },
    setCalismaDurum: function(Data) {
      this.clearAllCircleAnimations();
      if (typeof Data === "string") {
        Data = JSON.parse(Data);
      }
      const { tr, eng } = Data;
      console.log("Calışma Durum Data Not Parsed", Data);
      console.log("Calısma Durum", tr, eng);

      const functions = {
        AUTO: () =>
          this.jeneratorDOMElements["buttonAutoIndicator"].classList.add(
            "startBlink"
          ),
        TEST: () =>
          this.jeneratorDOMElements["buttonTestIndicator"].classList.add(
            "startBlink"
          ),
        "MANUAL(D700)/RUN(D500)": () =>
          this.jeneratorDOMElements["buttonManRunIndicator"].classList.add(
            "startBlink"
          ),
        STOP: () => {
          this.jeneratorDOMElements["buttonOffIndicator"].classList.add(
            "startBlink"
          );
          this.jeneratorDOMElements["buttonAutoIndicator"].classList.remove(
            "startBlink"
          );
          this.jeneratorDOMElements["buttonTestIndicator"].classList.remove(
            "startBlink"
          );
          this.jeneratorDOMElements["buttonManRunIndicator"].classList.remove(
            "startBlink"
          );
        },
        "...": () => {
          this.jeneratorDOMElements["buttonOffIndicator"].classList.add(
            "startBlink"
          );
          this.jeneratorDOMElements["buttonAutoIndicator"].classList.remove(
            "startBlink"
          );
          this.jeneratorDOMElements["buttonTestIndicator"].classList.remove(
            "startBlink"
          );
          this.jeneratorDOMElements["buttonManRunIndicator"].classList.remove(
            "startBlink"
          );
        }
      };

      functions[tr]();
    },
    clearAllCircleAnimations: function() {
      console.log("Clearing animation classes");
      this.jeneratorDOMElements["buttonOffIndicator"].classList.remove(
        "startBlink"
      );
      this.jeneratorDOMElements["buttonAutoIndicator"].classList.remove(
        "startBlink"
      );
      this.jeneratorDOMElements["buttonTestIndicator"].classList.remove(
        "startBlink"
      );
      this.jeneratorDOMElements["buttonManRunIndicator"].classList.remove(
        "startBlink"
      );
    }
  }
};
</script>

<style scoped>
svg text {
  font-family: "Fira Sans", sans-serif !important ;
}
</style>
