import Vue from "vue";
import axios from "axios";
import router from "../../router";
import { resetRouter } from "@/router";
import querystring from "querystring";
const route_login_backend = 
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_LOCAL_LOGIN_ENDPOINT
    : process.env.VUE_APP_AUTH_URL;
import Cookies from "js-cookie";

// Actions:
// Login, Logout

//State:
// accessToken, loginStatus

//LOGIN CONSTANTS
const LOGIN_CONSTANTS = {
  loginStatus: {
    LOGGING_IN: "LOGGING_IN",
    LOGGING_ERROR: "LOGGING_ERROR"
  },
  loginResult: {
    LOGGED_IN: "LOGGED_IN",
    LOGGED_OUT: "LOGGED_OUT"
  }
};

const state = {
  accessToken: null,
  // Default logged out
  loginStatus: LOGIN_CONSTANTS.loginResult.LOGGED_OUT,
  errorMessage: ""
};

const getters = {
  getAccessToken: state => {
    return state.accessToken;
  },
  getLoginStatus: state => {
    return state.loginStatus;
  },
  getAccessTokenLocalStorage: () => {
    return Cookies.get("ACCESS_TOKEN");
    //return localStorage.getItem("ACCESS_TOKEN");
  }
};

const mutations = {
  setAccessToken: (state, newAccessToken) => {
    Cookies.set("ACCESS_TOKEN", newAccessToken, {
      path: "",
      expires: 1,
      secure: true
    });
    state.accessToken = newAccessToken;
  },
  setLoginStatus: (state, newStatus) => {
    state.loginStatus = newStatus;
  },
  setErrorMessage: (state, message) => {
    state.errorMessage = message;
  },
  removeCookieMute: state => {
    console.log("Cookie Remove Called");
    Cookies.remove("ACCESS_TOKEN", {
      path: ""
    });
    state.accessToken = null;
  }
};

const actions = {
  actionConnectToSocket() {
    console.log("Check Access Token", state.accessToken);
    if (state.accessToken !== null) {
      let socket_message = {
        type: "authenticate",
        payload: { token: state.accessToken }
      };
      Vue.prototype.connectToSocket(socket_message, "FROM_TOOLBAR");
    }
  },
  actionDisconnectFromSocket() {
    Vue.prototype.disconnectFromSocket();
  },
  //Action LOGIN
  async actionLogin({ commit }, loginData) {
    //set login status to LOGGING_IN at the beginning
    commit("setLoginStatus", LOGIN_CONSTANTS.loginStatus.LOGGING_IN);
    //now that login status is LOGGING_IN, we need to start making a login request.
    try {
      console.log("Hi");
      console.log(route_login_backend);
      const response = await axios.post(
        route_login_backend,
        process.env.NODE_ENV === "development"
          ? loginData
          : querystring.stringify({
              kullanici_adi: loginData.kullanici_adi,
              sifre: loginData.sifre
            })
      );
      console.log("Response From Login", response);
      if (response.data === "ECONNREFUSED") {
        commit("setLoginStatus", LOGIN_CONSTANTS.loginStatus.LOGGING_ERROR);
        commit("setErrorMessage", "Bir hata oluştu");
        commit("removeCookieMute");
        return;
      }
      const { message, data } = response.data;
      if (data !== null) {
        const accessToken = data;
        console.log("Response From Login", response);
        commit("setAccessToken", accessToken);
        //access token exists, let's connect to the wss with that token.
        const socket_message = {
          type: "authenticate",
          payload: { token: accessToken }
        };
        Vue.prototype.connectToSocket(socket_message);
        console.log("ACTION LOGIN", socket_message);
      } else {
        console.log("Message Error", message);
        const { text } = message;
        commit("setLoginStatus", LOGIN_CONSTANTS.loginStatus.LOGGING_ERROR);
        commit("setErrorMessage", text);
        commit("removeCookieMute");
      }

      //commit("setLoginStatus", LOGIN_CONSTANTS.loginResult.LOGGED_IN);
    } catch (e) {
      console.log("Error when logging in", e);
      commit("setLoginStatus", LOGIN_CONSTANTS.loginStatus.LOGGING_ERROR);
      commit("removeCookieMute");
    }
  },

  actionSetLoggedIn({ commit }) {
    commit("setLoginStatus", LOGIN_CONSTANTS.loginResult.LOGGED_IN);
  },

  //Action LOGOUT || TOKEN EXPIRED
  actionLogout({ commit }) {
    //commit("setAccessToken", null);
    //localStorage.removeItem("ACCESS_TOKEN");
    Vue.prototype.disconnectFromSocket();
    commit("removeCookieMute");
    commit("setLoginStatus", LOGIN_CONSTANTS.loginResult.LOGGED_OUT);
    router.push({ name: "LoginView" }).then(() => {
      resetRouter();
    });
  },

  updateAccessTokenFromStorage({ commit }) {
    const cookie = Cookies.get("ACCESS_TOKEN");
    if (cookie !== undefined) {
      commit("setAccessToken", Cookies.get("ACCESS_TOKEN"));
    } else {
      commit("removeCookieMute");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
