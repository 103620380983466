<template>
  <v-form>
    <v-text-field
        label="Bileşen Etiket"
        v-model="formModel['component_label']"
    />
    <v-text-field
        label="Push Button Etiket"
        v-model="formModel['output_label']"
    />
    <v-text-field
        label="Timeout (MS)"
        v-model="formModel['timeout']"
    />
    <v-autocomplete
        label="Bağlı Alan"
        v-model="formModel['bagli_alan']"
        :items="bagliAlanlar"
        item-text="Text"
        item-value="Id"
    />
  </v-form>
</template>

<script>
export default {
  name: "PushButtonComponent",
  props: {
    bagliAlanlar: {
      type: Array,
      required: false
    },
    animationTypes: {
      type: Array,
      required: false
    },
    formModel: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
