import { render, staticRenderFns } from "./elemanEtiket.vue?vue&type=template&id=f0673326&scoped=true&"
import script from "./elemanEtiket.vue?vue&type=script&lang=js&"
export * from "./elemanEtiket.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0673326",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VColorPicker,VIcon,VMenu,VRow,VTextField})
