var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parentName !== 'ModemTabLayout')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"label":"Genişlik"},on:{"input":_vm.changeSizeOfSVG},model:{value:(_vm.currentItem['genislik']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'genislik', $$v)},expression:"currentItem['genislik']"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-6 pr-1"},[_c('v-text-field',{attrs:{"label":"Push Button Label"},on:{"input":_vm.changeFixedLabel},model:{value:(_vm.currentItem['fixedLabel']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'fixedLabel', $$v)},expression:"currentItem['fixedLabel']"}})],1),_c('v-col',{staticClass:"col-6 pl-1"},[_c('v-menu',{ref:"cpRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Fixed Label Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['fixedLabelColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'fixedLabelColor', $$v)},expression:"currentItem['fixedLabelColor']"}},on),[_c('v-icon',{staticClass:"oneLabelIcon",attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['fixedLabelColor']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,1440202570),model:{value:(_vm.colorMenuFixedLabel),callback:function ($$v) {_vm.colorMenuFixedLabel=$$v},expression:"colorMenuFixedLabel"}},[_c('v-color-picker',{on:{"input":_vm.changePushButtonFixedLabelColor},model:{value:(_vm.currentItem['fixedLabelColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'fixedLabelColor', $$v)},expression:"currentItem['fixedLabelColor']"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-12 pl-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Buton 1 Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['buttonOnColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'buttonOnColor', $$v)},expression:"currentItem['buttonOnColor']"}},on),[_c('v-icon',{staticClass:"oneLabelIcon",attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['buttonOnColor']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,4212355145),model:{value:(_vm.colorMenuButtonOn),callback:function ($$v) {_vm.colorMenuButtonOn=$$v},expression:"colorMenuButtonOn"}},[_c('v-color-picker',{on:{"input":_vm.changePushButtonOnColor},model:{value:(_vm.currentItem['buttonOnColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'buttonOnColor', $$v)},expression:"currentItem['buttonOnColor']"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-12 pl-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Buton Çerçeve Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['buttonOnStrokeColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'buttonOnStrokeColor', $$v)},expression:"currentItem['buttonOnStrokeColor']"}},on),[_c('v-icon',{staticClass:"oneLabelIcon",attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['buttonOnStrokeColor']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,2521561081),model:{value:(_vm.colorMenuButtonStroke),callback:function ($$v) {_vm.colorMenuButtonStroke=$$v},expression:"colorMenuButtonStroke"}},[_c('v-color-picker',{on:{"input":_vm.changeCircleStrokeColor},model:{value:(_vm.currentItem['buttonOnStrokeColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'buttonOnStrokeColor', $$v)},expression:"currentItem['buttonOnStrokeColor']"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-12 pl-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Buton 0 Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['buttonOffColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'buttonOffColor', $$v)},expression:"currentItem['buttonOffColor']"}},on),[_c('v-icon',{staticClass:"oneLabelIcon",attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['buttonOffColor']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,3169569832),model:{value:(_vm.colorMenuButtonOff),callback:function ($$v) {_vm.colorMenuButtonOff=$$v},expression:"colorMenuButtonOff"}},[_c('v-color-picker',{on:{"input":_vm.changePushButtonOffColor},model:{value:(_vm.currentItem['buttonOffColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'buttonOffColor', $$v)},expression:"currentItem['buttonOffColor']"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-12 pr-1"},[_c('v-autocomplete',{attrs:{"items":_vm.ioListData,"label":"Bağlı Alan"},model:{value:(_vm.currentItem['bagli_alan']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'bagli_alan', $$v)},expression:"currentItem['bagli_alan']"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-6 pr-1"},[_c('v-text-field',{attrs:{"label":"0 Değer"},model:{value:(_vm.currentItem['offValue']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'offValue', $$v)},expression:"currentItem['offValue']"}})],1),_c('v-col',{staticClass:"col-6 pl-1"},[_c('v-text-field',{attrs:{"label":"1 Değer"},model:{value:(_vm.currentItem['onValue']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'onValue', $$v)},expression:"currentItem['onValue']"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"label":"Timeout Süre (ms)"},model:{value:(_vm.currentItem['timeoutMS']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'timeoutMS', $$v)},expression:"currentItem['timeoutMS']"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }