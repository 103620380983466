<template>
  <div fluid fill-height class="wrapper">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          class="mr-2 mt-2"
          fixed
          right
          v-on="on"
          @click="changeTheme"
          color="darkDef"
          icon
        >
          <v-icon>{{ themeIcon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ themeTooltip }}</span>
    </v-tooltip>
    <transition name="fade" mode="out-in">
      <ForgotPassword v-if="forgot_pass_status" key="1" />

      <v-container
        v-if="!forgot_pass_status"
        class="pa-0"
        fluid
        fill-height
        key="2"
      >
        <v-layout align-center justify-center>
          <v-flex class="pa-10" xs12 sm8 md4 lg4>
            <v-alert
              :dense="dense"
              :value="loginError"
              :dismissible="dismissible"
              type="error"
              class="mb-4 text-center"
            >
              {{errMessage}}
            </v-alert>

            <div class="tlx_logo_wrapper mt-5 mb-5">
              <!--<img src="img/telix-logo.svg"/>-->
              <picture>
                <source :srcset="requireLogo" media="(min-width: 1000px)" />
                <source :srcset="requireLogo" media="(max-width: 1000px)" />
                <img width="120px" :srcset="requireLogo" />
              </picture>
            </div>

            <v-form ref="loginForm">
              <v-flex>
                <v-text-field
                  class="mt-2 mb-2"
                  color="darkDef"
                  clearable
                  clear-icon="clear"
                  type="email"
                  label="E-Posta veya Kullanıcı Adı"
                  v-model="email"
                  :rules="setRules('email')"
                ></v-text-field>
                <v-text-field
                  class="mt-2"
                  color="darkDef"
                  clearable
                  clear-icon="clear"
                  label="Şifre"
                  v-model="password"
                  :rules="setRules('password')"
                  :append-icon="eyecon ? 'visibility' : 'visibility_off'"
                  :type="eyecon ? 'text' : 'password'"
                  @click:append="eyecon = !eyecon"
                ></v-text-field>
                <div class="my-2">
                  <v-btn
                    class="white--text text-capitalize body-1"
                    block
                    large
                    color="loginButton"
                    :loading="buttonIsLoading"
                    @click="startLogin"
                    >Giriş Yap</v-btn
                  >
                </div>
                <div class="my-2 mt-5 mb-5">
                  <v-btn
                    @click="onForgotPassword"
                    text
                    small
                    block
                    class="text-none body-2 font-weight-medium"
                    >Şifremi unuttum</v-btn
                  >
                </div>
              </v-flex>
              <div class="text-center">
              <vue-recaptcha
                @render="onCaptchaRerender"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                id="vue_rc"
                class="mb-2 mt-2 google_rc xs12 sm8 md4 lg4 g-recaptcha"
                sitekey="6LerHdIZAAAAAEWGplS6u-1ahcCQqVJAQ5wX4btx"
              ></vue-recaptcha>
              </div>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </transition>

    <v-snackbar
      v-model="snackbar_status"
      :timeout="snackbar_timeout"
      color="#E53935"
      top="top"
    >
      {{ snackbar_captcha_warning }}
    </v-snackbar>
    <LoginFooter />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "../vuex_store/store_wrapper";
import VueRecaptcha from "vue-recaptcha";
import LoginFooter from "../components/LoginFooter";
import ForgotPassword from "../components/ForgotPassword";
import { bus } from "@/main";

const mailRegex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
export default {
  name: "LoginView",
  components: {
    "vue-recaptcha": VueRecaptcha,
    ForgotPassword: ForgotPassword,
    LoginFooter: LoginFooter
  },
  data() {
    return {
      unsubscribe: null,
      buttonIsLoading: false,
      loginError: false,

      snackbar_status: false,
      snackbar_captcha_warning:
        "Geçersiz captcha. Robot olmadığınızı belirtmelisiniz.",
      snackbar_timeout: 3000,
      normal_form_status: true,
      forgot_pass_status: false,
      button_loading_state: false,
      dismissible: true,
      dense: true,
      email: null,
      password: null,
      eyecon: false,
      footer_date: new Date().getFullYear(),
      footer_info: "Telix Telemetry Systems",
      is_captcha_verified: false,
      is_socket_connected: this.getterIsSocketConnected,
      themeIcon: "mdi-brightness-4",
      themeTooltip: "Tema değiştir !",
      errMessage: 'Giriş Başarısız',

      isThemeDark: this.$vuetify.theme.dark
    };
  },
  computed: {
    requireLogo: function() {
      return this.$vuetify.theme.dark
        ? require("../assets/telix-logo-white.svg")
        : require("../assets/telix-logo.svg");
    },
    //module şeklinde ulaşım. getters ve states statik durumlar olduklarından computed içinde tanımlanacaklar.
    ...mapGetters({
      //socket getters
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected",
      getterSocketMessage: "storeSocket/getterSocketMessage",
    })
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    const themeDef = localStorage.getItem("THEME_OPTION");
    console.log("Theme", themeDef)
    if (themeDef === "DARK") {
      this.$vuetify.theme.dark = true;
      this.themeIcon = "mdi-brightness-6";
    }else if (themeDef === "LIGHT") {
      this.$vuetify.theme.dark = false;
      this.themeIcon = "mdi-brightness-4";
    }else if (themeDef === null) {
      localStorage.setItem("THEME_OPTION", "LIGHT");
      this.$vuetify.theme.dark = false;
      this.themeIcon = "mdi-brightness-4";
    }

    bus.$on("clickedBack", value => {
      console.log(value);
      this.normal_form_status = true;
      this.forgot_pass_status = false;
    });

    this.unsubscribe = store.subscribe(mutation => {
      //console.log("Mutation login =>", mutation);
      if (mutation.type === 'storeLoginEvents/setLoginStatus') {
        if (mutation.payload === "LOGGING_IN") {
          this.buttonIsLoading = true;
        }else if (mutation.payload === "LOGGED_IN") {
          console.log("LOGGEDIN GOING TO /DASHBOARD");
          this.buttonIsLoading = false;
          this.$router.push("/Dashboard");
        }else if (mutation.payload === "LOGGING_ERROR") {
          this.buttonIsLoading = false;
          this.loginError = true;
        }
      }else if (mutation.type === 'storeSocket/on_socket_error') {
        if (mutation.payload === 'SOCKET_ERROR') {
          this.buttonIsLoading = false;
          this.loginError = true;
        }
      }else if (mutation.type === 'storeLoginEvents/setErrorMessage') {
        console.log("MUTATION ERRR MESSAGE")
        this.errMessage = mutation.payload;
        this.loginError = true;
      }
    });
  },
  watch: {
    getterIsSocketConnected: function(value) {
      if (value) {
        this.actionSetLoggedIn();
        console.log("Soket bağlantısı: ", value);
      } else {
        console.log("Soket bağlantısı: ", value);
      }
    }
  },
  methods: {
    ...mapActions({
      //new store file for login events
      actionLogin: "storeLoginEvents/actionLogin",
      actionSetLoggedIn: "storeLoginEvents/actionSetLoggedIn"
    }),
    onCaptchaRerender: function() {
      this.is_captcha_verified = false;
    },
    onCaptchaExpired: function() {
      console.log("On Captcha expired.");
      this.is_captcha_verified = false;
      console.log(this.is_captcha_verified);
    },
    onCaptchaVerified: function() {
      console.warn("On Captcha Verified.");
      this.is_captcha_verified = true;
      console.log(this.is_captcha_verified);
    },
    onForgotPassword: function() {
      this.normal_form_status = false;
      this.forgot_pass_status = true;
    },
    showCaptchaSnackbar: function() {
      this.snackbar_status = !this.is_captcha_verified;
    },

    //login click eventi. axios a başla
    startLogin: function() {
      console.log("Valid ? ", this.$refs['loginForm'].validate());
      this.loginError = false;
      const isFormValid = this.$refs['loginForm'].validate();
      if (isFormValid) {
        this.is_captcha_verified = true;
        if (this.is_captcha_verified) {
          console.log("start login");
          this.actionLogin({
            kullanici_adi: this.email,
            sifre: this.password
          });
        } else {
          this.showCaptchaSnackbar();
        }
      }
    },
    changeTheme: function() {
      if (!this.$vuetify.theme.dark) {
        this.$vuetify.theme.dark = true;
        this.themeIcon = "mdi-brightness-6";
      } else {
        this.$vuetify.theme.dark = false;
        this.themeIcon = "mdi-brightness-4";
      }
      console.log(this.$vuetify.theme.dark);
    },
    setRules: function (value) {
      if (value === 'email') {
        return [
          v => !!v || `E Posta alanı boş bırakılamaz`,
          v =>
              (v || "").length >= 1
                  ? mailRegex.test(v) !== false || "Geçerli bir E Posta adresi giriniz."
                  : (v || "").length >= 1
        ]
      } else {
        return [
          v => !!v || `Şifre alanı boş bırakılamaz`
        ]
      }
    }
  }
};
</script>

<style scoped>
.tlx_logo_wrapper {
  text-align: center;
}

.wrapper {
  background-image: url("../assets/main.svg") !important;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 99;
  height: 100vh;
  width: 100%;
}

.text-center {
  text-align: center;
}

.g-recaptcha {
  display: inline-block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.error_banner {
  position: fixed !important;
  width: 100vw !important;
}
</style>
