//type -> error vb... text -> snackbar text duration -> snackbar duration
export const constructBasicSnackbar = (type,text,duration)  => {
    return {
        snackbarMDL: true,
        snackbarMSG: text,
        snackbarColor: type,
        snackbarTimeout: duration
    }
};

export const constructAlertDialog = (title,message,textPButton,textNButton) => {
    return {
        dialogModel: true,
        dialogTitle: title,
        dialogMessage:
        message,
        textPButton: textPButton,
        textNButton: textNButton
    };
};