<template
  ><p>PompaTek</p>
</template>

<script>

  export default {
  name: "pompaTek",
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
  }
};
</script>

<style scoped></style>
