<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Alarm ID</th>
          <th class="text-left">Tarih</th>
          <th class="text-left">Kod</th>
          <th class="text-left">Mesaj</th>
          <th class="text-left">Kapanma</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,alarmIndex) in componentData.alarms" :key="alarmIndex">
          <td>{{ item['alarm_log_id'] }}</td>
          <td>{{ item['tarih'] }}</td>
          <td>{{ item['alarm_kodu'] }}</td>
          <td>{{ item['alarm_mesaj'] }}</td>
          <td>{{ item['kapanma_tarihi'] }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "ReportsAlarmListComponent",
  props: {
    componentData: {
      type: Object,
      required: true
    }
  },
  created() {
    console.log("BBBBB", this.componentData);
  }
};
</script>

<style scoped></style>
