<template>
  <div>
    <v-snackbar
      v-model="snackbarModel"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
    >
      {{ snackbarText }}
    </v-snackbar>
    <!-- Upload Dialog -->
    <popup-upload
      v-if="uploadDialog"
      @close="uploadDialog = false"
      @upload-ok="uploadSuccess"
      @upload-error="uploadError"
      :dialog-model="uploadDialog"
      :field-item="fieldItem"
    />
    <!-- Upload Dialog -->
    <div class="mb-4">
      <v-app-bar class="indigo lighten-5">
        <v-toolbar-title>{{ fieldItem["Label"] }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="fieldItem['CustomData']['showSelection'] === 'true'"
          v-model="selectedFile"
          readonly
          label="Seçilen dosya"
          hide-details
          dense
          :rules="[v => !!v || 'Grafik mi lamba mı seçimi yapınız !']"
        ></v-text-field>
        <v-btn v-if="!readOnly" icon @click="uploadDialog = true">
          <v-icon>cloud_upload</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card class="elevation-1 overflow-y-auto" style="height: 500px;">
        <v-list-item-group
          v-if="fieldItem['CustomData']['showSelection'] === 'true'"
          v-model="fileListModel"
          :multiple="false"
        >
          <v-list-item
            v-for="item in fileList"
            :key="item['filename']"
            :value="item['filename']"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action v-if="!readOnly">
                <v-checkbox :input-value="active" color="primary"></v-checkbox>
              </v-list-item-action>
              <v-list-item-icon class="mr-4"
                ><v-icon
                  v-if="checkExtension(item['filename']) === 'js'"
                  style="background: black !important;"
                  color="#F0DB4F"
                  >mdi-language-javascript</v-icon
                >
                <v-icon
                  v-if="checkExtension(item['filename']) === 'svg'"
                  color="#e0487b"
                  >mdi-file-image</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title>{{ item["filename"] }}</v-list-item-title>
              <v-list-item-action v-if="item['deletable']">
                <v-btn @click.stop="removeFile(item)" icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>

              <v-list-item-content> </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>

        <v-list v-else>
          <v-list-item
            v-for="item in fileList"
            :key="item['filename']"
            :value="item['filename']"
          >
            <v-list-item-icon class="mr-4"
              ><v-icon
                v-if="checkExtension(item['filename']) === 'js'"
                style="background: black !important;"
                color="#F0DB4F"
                >mdi-language-javascript</v-icon
              >
              <v-icon
                v-if="checkExtension(item['filename']) === 'svg'"
                color="#e0487b"
                >mdi-file-image</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title>{{ item["filename"] }}</v-list-item-title>
            <v-list-item-action v-if="item['deletable']">
              <v-btn @click.stop="removeFile(item)" icon>
                <v-icon color="grey lighten-1">mdi-information</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileUploadPopup from "@/components/FileSelector/FileUploadPopup";

export default {
  name: "FileUploadComp",
  components: {
    "popup-upload": FileUploadPopup
  },
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dynamicM: [Array, Object],
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      snackbarTimeout: 4000,
      snackbarModel: false,
      snackbarColor: "#fff",
      snackbarText: "",
      dialogUploadBtnLoading: false,
      dialogUploadBtnDisabled: true,

      uploadDialog: false,
      fileListModel: [],
      fileList: [],
      fileToUpload: [],
      selectedFile: ""
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    })
  },
  watch: {
    fileToUpload: function(val) {
      console.log("File Value", val);
      this.dialogUploadBtnDisabled = val === null;
    },
    fileListModel: function(val) {
      if (this.fieldItem["CustomData"]["showSelection"] === "true") {
        this.selectedFile = val;
        const { Field } = this.fieldItem;
        this.dynamicM[Field] = val;
      }
    },
    getterSocketMessage: function(response) {
      const { command, data } = JSON.parse(response.data);
      if (command === "FileList") {
        console.log("Data File ", data);
        const extension = data["dosyalar"][0]["filename"].split(".").pop();
        if (extension === this.fieldItem["CustomData"]["extension"]) {
          this.fileList = data["dosyalar"];
        }
      }
    }
  },
  created() {
    this.sendFileSocketRequest();

    const { Field, CustomData } = this.fieldItem;
    const { showSelection } = CustomData;

    if (showSelection === "true") {
      if (Field !== "") {
        this.fileListModel = this.dynamicM[Field];
        this.selectedFile = this.dynamicM[Field];
      }
    }
    console.log("FIELD ITEM", this.fieldItem);
    console.log("Dynamic M", this.dynamicM);
  },
  mounted() {
    this.uploadDialog = false;
  },
  methods: {
    sendFileSocketRequest: function() {
      const { extension, path } = this.fieldItem["CustomData"];
      const req = {
        type: "getFileList",
        payload: { Path: path, Extension: extension }
      };
      this.sendMessageToWS(req);
    },
    blockDragOver: function(e) {
      console.log("Drag Over", e);
      e.preventDefault();
    },
    blockDragEnter: function(e) {
      console.log("Drag Enter", e);
      e.preventDefault();
    },
    removeFile: function(item) {
      console.log("File To Remove", item);
    },
    checkExtension: function(val) {
      return val.split(".").pop();
    },
    uploadClick: function() {
      document.querySelector("#file_input").click();
    },
    uploadSuccess: function(payload) {
      console.log("Upload Success", payload);
      this.uploadDialog = false;
      const { data } = payload;
      const { message } = data;
      const { type, text } = message;
      if (type === "Success") {
        this.snackbarText = text;
        this.snackbarColor = "#3edb46";
        this.snackbarModel = true;
        this.sendFileSocketRequest();
      }
    },
    uploadError: function(e) {
      console.log("Upload Error", e);
      this.snackbarText = "Bir hata ouştu";
      this.snackbarColor = "#f33f5c";
      this.snackbarModel = true;
    }
  }
};
</script>

<style scoped></style>
