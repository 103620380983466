<template>
  <div style="max-height: 350px !important; overflow-y: scroll;">
    <v-simple-table :dense="true" :fixed-header="false">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Modem İsmi</th>
            <th class="text-left">Alarm Mesaj</th>
            <th class="text-left">Tarih</th>
            <th class="text-left">Kapanma Tarihi</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="i === 0 && 'currentAlarm'"
            v-for="(item, i) in toolbarNotificationData['alarms']"
            :key="i"
          >
            <td>{{ item['modem_name'] }}</td>
            <td>{{ item['alarm_mesaj'] }}</td>
            <td>{{ item['tarih'] }}</td>
            <td>{{ item["kapanma_tarihi"] !== undefined ? item["kapanma_tarihi"] : '------' }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

export default {
  name: "NotificationsTable",
  props: {
    toolbarNotificationData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      alarmData: []
    };
  },
  computed: {
    getAlarmNotifications: {
      get: function() {
        return this.toolbarNotificationData["alarms"];
      },
      set: function(data) {
        this.toolbarNotificationData["alarms"] = [
          data,
          ...this.toolbarNotificationData["alarms"]
        ];
      }
    }
  },

  created() {
    console.log("PROP ALARM DATA", this.toolbarNotificationData);
  },
  watch: {

  },
  methods: {
  }
};
</script>

<style scoped>
.notification_row {
  border-radius: 5px !important;
}

.notification_row:nth-child(odd) {
  background: #fff6f9 !important;
}

.notification_row:nth-child(even) {
  background: #ffe1e4 !important;
}
</style>
