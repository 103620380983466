<template>
  <v-form ref="refrefref">
    <v-row no-gutters>
      <v-text-field label="Etiket" v-model="currentItem['etiket']" />
    </v-row>
    <v-row no-gutters>
      <v-text-field
        label="Birim"
        v-model="currentItem['birim']"
        @input="changeUnitLabel"
      />
    </v-row>

    <v-row no-gutters>
      <v-menu
        ref="cpRef"
        v-model="colorMenuArkaplan"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-x
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Arkaplan"
            prepend-icon="event"
            readonly
            v-on="on"
            v-model="currentItem['arkaplan']"
          >
            <v-icon slot="prepend" class="oneLabelIcon">
              mdi-invert-colors
            </v-icon>
          </v-text-field>
        </template>
        <v-color-picker
          v-model="currentItem['arkaplan']"
          @input="changeColorArkaplan"
        ></v-color-picker>
      </v-menu>
    </v-row>

    <v-row no-gutters>
      <v-menu
        ref="cpRef"
        v-model="colorMenuYazi"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-x
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Yazı"
            prepend-icon="event"
            readonly
            v-on="on"
            v-model="currentItem['yazi']"
          >
            <v-icon slot="prepend" class="oneLabelIcon">
              mdi-invert-colors
            </v-icon>
          </v-text-field>
        </template>
        <v-color-picker
          v-model="currentItem['yazi']"
          @input="changeColorYazi"
        ></v-color-picker>
      </v-menu>
    </v-row>

    <v-row no-gutters>
      <v-menu
        ref="cpRef"
        v-model="colorMenuKenar"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-x
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Kenarlık"
            prepend-icon="event"
            readonly
            v-on="on"
            v-model="currentItem['kenar']"
          >
            <v-icon slot="prepend" class="oneLabelIcon">
              mdi-invert-colors
            </v-icon>
          </v-text-field>
        </template>
        <v-color-picker
          v-model="currentItem['kenar']"
          @input="changeColorKenar"
        ></v-color-picker>
      </v-menu>
    </v-row>

    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="changeSizeOfSVG"
      />
    </v-row>

    <v-row no-gutters>
      <v-select
        label="Bağlı Alan"
        v-model="currentItem['bagli_alan']"
        item-text="text"
        item-value="value"
        :items="ioListData"
      />
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../../main";
import {changeSvgDimension} from "./svgComponentUtils/svgUtilFunctions";

export default {
  name: "roundedLabel",
  props: {
    currentItem: {
      type: Object,
      required: true
    },
    fileList: {
      type: Array,
      required: true
    },
    modemID: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      ioListData: [],
      colorMenuArkaplan: false,
      colorMenuYazi: false,
      colorMenuKenar: false
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    })
  },
  created() {
    console.log("Rounded LABEL", this.modemID);
    let requestIOList = {
      type: "getModemIOList",
      payload: {
        TargetID: this.currentItem["id"],
        ModemId: parseInt(this.modemID[0]),
        Secim: "1@3@4"
      }
    };
    this.sendMessageToWS(requestIOList);
    bus.$on("messageSVG", value => {
      console.log("Event Received", value);
    });
  },
  watch: {
    getterSocketMessage: function(value) {
      let command = JSON.parse(value.data).command;
      let targetID = JSON.parse(value.data).data.TargetID;
      if (command === "ModemIOList" && targetID === this.currentItem["id"]) {
        this.ioListData = [];
        let dataObj = JSON.parse(value.data).data.Elements;
        Object.keys(dataObj).forEach(key => {
          this.ioListData.push({
            value: key,
            text: dataObj[key]
          });
        });
        console.log("rounded label", this.ioListData);
      }
    }
  },
  methods: {
    changeSizeOfSVG: function() {
      changeSvgDimension(this.currentItem);
    },
    changeColorArkaplan: function(value) {
      console.log(value);
      let groupItem = document.getElementById(this.currentItem["id"]);
      let svgWrapper = groupItem.getElementsByTagName("svg");
      let basePath = svgWrapper[0].getElementsByClassName("label_base_class");
      basePath[0].setAttribute("fill", value);
    },
    changeColorYazi: function(value) {
      let groupItem = document.getElementById(this.currentItem["id"]);
      let svgWrapper = groupItem.getElementsByTagName("svg");
      let texts = svgWrapper[0].getElementsByTagName("text");
      texts.forEach(svg_item => {
        svg_item.setAttribute("fill", value);
      });
    },
    changeColorKenar: function(value) {
      let groupItem = document.getElementById(this.currentItem["id"]);
      let svgWrapper = groupItem.getElementsByTagName("svg");
      let basePath = svgWrapper[0].getElementsByClassName("label_base_class");
      basePath[0].setAttribute("stroke", value);
    },
    changeUnitLabel: function(value) {
      let groupItem = document.getElementById(this.currentItem["id"]);
      let svgWrapper = groupItem.getElementsByTagName("svg");
      let textUnit = svgWrapper[0].getElementsByClassName(
        "label_base_text_birim"
      );
      textUnit[0].innerHTML = value;
    },
    updateValue: function (id,value) {
      console.log("Value",value,this);
      let elementBase = document.getElementById(id);
      let svgWrapper = elementBase.getElementsByTagName("svg");
      let textToUpdate = svgWrapper[0].getElementsByClassName("label_base_text_deger");

      textToUpdate[0].innerHTML = value;

    }
  }
};
</script>

<style scoped></style>
