<template>
  <v-row no-gutters>
    <template v-for="(field, f_index) in getFieldData['Fields']">
      <v-col v-columnMode="getFieldData['ColumnCount']" :key="f_index">
        <v-card class="px-2 pt-2 pb-6" flat tile>
          <v-form ref="yeniLinkedForm" lazy-validation v-model="valid">
            <div
              v-for="(field_item, f_item_index) in field"
              :key="f_item_index"
            >
              <v-text-field
                v-model="dynamicM[field_item.Field]"
                v-if="field_item['ElementType'] === 'text'"
                :label="field_item['Label']"
                :rules="setRule(field_item, dynamicM[field_item.Field])"
              ></v-text-field>

              <v-autocomplete
                v-if="
                  field_item['ElementType'] === 'combo' &&
                    field_item['Field'] === 'modem_id'
                "
                :items="field_item['ComboItems']['Elements']"
                item-text="Text"
                item-value="Id"
                :label="field_item['Label']"
                :rules="setRule(field_item, dynamicM[field_item.Field])"
                :value="parseInt(masterId)"
                readonly
              ></v-autocomplete>
              <v-autocomplete
                v-model="dynamicM[field_item.Field]"
                v-if="
                  field_item['ElementType'] === 'combo' &&
                    field_item['Field'] !== 'modem_id'
                "
                :items="field_item['ComboItems']['Elements']"
                item-text="Text"
                item-value="Id"
                :label="field_item['Label']"
                :rules="setRule(field_item, dynamicM[field_item.Field])"
              ></v-autocomplete>

              <v-alert v-if="field_item['ElementType'] === 'ModemSelectList' || field_item['ElementType'] === 'linkedTable'"
                  type="error"
              >
                {{field_item['Label']}} bileşenine erişebilmek için kullanıcıyı kaydetmeniz gerekmektedir.
              </v-alert>

            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-responsive
        v-if="f_index === getFieldData['ColumnCount']"
        :key="`width-${f_index}`"
        width="100%"
      ></v-responsive>
    </template>
  </v-row>
</template>

<script>
import { setRuleData } from "@/form_stuff/set_form_rules";

export default {
  name: "yeni_linked_list",
  data() {
    return {
      valid: true
    };
  },
  props: {
    tabItem: {
      type: Object,
      required: true
    },
    modalData: {
      type: Object,
      required: false
    },
    dynamicM: {
      type: Object,
      required: true
    },
    masterId: {
      type: String,
      required: false
    }
  },
  computed: {
    /*
     * tabItem return. Fieldları içeriyor.
     * */
    getFieldData: function() {
      return this.tabItem;
    },
    /*
     * modalData return. Fieldlar dataları içeriyor. düzenle ve incele için kullanılacak.
     * Yeni kısmında bağlı bir field varsa(hidden fieldlar gibi) o zaman kullanılacak.
     * */
    getModalData: function() {
      return this.modalData;
    }
  },
  created() {
    console.log("YENI LINKED LIST MASTER ID", this.masterId);
  },
  methods: {
    setRule: function(v,fV) {
      return setRuleData(v,fV);
    },
    checkValidation: function() {
      const checkForms = val => val.validate() === true;
      console.log("Check", this.$refs.yeniLinkedForm.every(checkForms));
      if (this.$refs.yeniLinkedForm.every(checkForms)) {
        //{K: "", V: "" } oluştur.
        console.log("form vaaliddddd");

        return true;
      } else {
        console.log("Form  valid değil.");

        return false;
      }
    }
  }
};
</script>

<style scoped></style>
