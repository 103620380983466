<template>
  <div>
    <logout-dialog :is="componentOBJ.compName"
                   :dynamicProp="componentOBJ.compProp"
                   eventPositive="logoutPositive"
                   eventNegative="logoutNegative"
                   @logoutPositive="logoutPositive"
                   @logoutNegative="logoutNegative"/>
    <v-navigation-drawer
        color="drawerBackground"
        width="311"
        @transitionend="trans"
        bottom
        v-model="drawer"
        app
    >
      <div class="d-flex d-md-none pt-2 pb-2">
        <v-col class="col-auto align-self-center">
          {{ getToolbarTitle }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="col-auto">
          <v-chip text-color="toolbarUsernameColor" color="toolbarUsernameBg">
            <v-avatar left>
              <v-icon>account_circle</v-icon>
            </v-avatar>
            {{ getToolbarUsername }}
          </v-chip>
        </v-col>
      </div>

      <v-divider />

      <v-list shaped>
        <div v-for="item in getToolbarData" :key="item.label">
          <div v-if="item.submenus === null">
            <v-list-item
                :active-class="'drawerActive' + '--text'"
                :key="item.target"
                :to="{ path: `/${item.target}` }"
                link
            >
              <v-list-item-icon>
                <v-icon color="drawerText">icon-icon-{{ item.icon }}</v-icon>
                <!--<i :class="'icon-'+itemWO.icon"></i>-->
              </v-list-item-icon>

              <v-list-item-title color="drawerText">{{
                  item.label
                }}</v-list-item-title>
            </v-list-item>
          </div>

          <div v-else>
            <v-list-group
                :active-class="'drawerActive' + '--text'"
                v-model="item.active"
                no-action
                :expand="false"
            >
              <template v-slot:prependIcon>
                <v-icon>icon-icon-{{ item.icon }}</v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                      color="drawerText"
                      v-text="item.label"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  :to="subItem.target"
                  link
                  v-for="subItem in item.submenus"
                  :key="subItem.target"
                  color="drawerText"
                  class="ml-3"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.label"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :elevate-on-scroll="true" app color="toolbarBackground">
      <v-btn icon @click.stop="setDrawerState(drawer = !drawer)" v-if="!drawer">
        <v-icon color="toolbarIcons">menu</v-icon>
      </v-btn>

      <v-btn icon @click.stop="setDrawerState(drawer = !drawer)" v-else dark>
        <v-icon color="toolbarIcons">mdi-arrow-collapse-left</v-icon>
      </v-btn>
      <v-toolbar-title
          class="d-none d-sm-none d-md-block d-lg-block d-xl-block white--text"
      >{{ getToolbarTitle }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical :dark="true"></v-divider>
      <toolbar-menu-conn-status
          :theme-options="{
          iconColor: 'toolbarIcons',
          cardColor: 'popupMenuColor'
        }"
      />
      <v-spacer></v-spacer>

      <v-chip
          class="mr-10 d-none d-sm-none d-md-block d-lg-block d-xl-block"
          text-color="toolbarUsernameColor"
          color="toolbarUsernameBg"
      >
        <v-avatar left>
          <v-icon>account_circle</v-icon>
        </v-avatar>
        {{ getToolbarUsername }}
      </v-chip>

      <!-- Change theme button -->
      <v-btn @click="changeTheme" color="toolbarIcons" icon>
        <v-icon>{{ themeIcon }}</v-icon>
      </v-btn>
      <!-- Change theme button -->

      <!-- Hidden items menu -->
      <div v-if="currentRoute['name'] === 'Dashboard'">
        <toolbar-menu-hidden
            :theme-options="{
            iconColor: 'toolbarIcons',
            cardColor: 'popupMenuColor'
          }"
        />
      </div>
      <!-- Hidden items menu -->

      <!-- Alarm Popup Menu Component -->
      <toolbar-menu-alarms
          :theme-options="{
          iconColor: 'toolbarIcons',
          cardColor: 'popupMenuColor'
        }"
          :toolbar-alarm-data="toolbarAlarmData"
      />
      <!-- Alarm Popup Menu Component -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
              text
              icon
              color="toolbarIcons"
              dark
              v-on="on"
              @click="logoutFunc"
          >
            <v-icon :large="true">power_settings_new</v-icon>
          </v-btn>
        </template>
        <span>{{ logout_tooltip }}</span>
      </v-tooltip>
    </v-app-bar>
    <div class="toolbarIndicator"
         style="background-color: rgba(255,255,255,0.12); height: 1px; width: 100vw;"
    ></div>
  </div>
</template>

<script>
import ToolbarMenuHiddenItems from "@/components/ToolbarMenus/ToolbarMenuHiddenItems";
import ToolbarMenuAlarms from "@/components/ToolbarMenus/ToolbarMenuAlarms";
import ToolbarMenuConnectionStatus from "@/components/ToolbarMenus/ToolbarMenuConnectionStatus";
import {mapActions, mapGetters, mapState} from "vuex";
import CustomDialogAlert from "@/components/DialogComponent/CustomDialogAlert";
import {constructAlertDialog} from "@/components/ConstructDialogsAlertsSnackbars/constructor";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "Toolbar",
  components: {
    "toolbar-menu-hidden": ToolbarMenuHiddenItems,
    "toolbar-menu-alarms": ToolbarMenuAlarms,
    "toolbar-menu-conn-status": ToolbarMenuConnectionStatus,
    "logout-dialog": CustomDialogAlert
  },
  data() {
    return {
      drawer: false,
      logout_tooltip: "Çıkış yap",
      notifications_tooltip: "Bildirimler ve Alarmlar",
      item: "item",
      toolbar_title: "Telix Telemetri Sistemleri",
      menu: false,
      hiddenItemsMenu: false,
      hiddenGridItems: [],
      themeIcon: "mdi-brightness-4",

      alarmData: {},
      componentOBJ: { compName: "", compProp: null },
/*      soundEffect: "",
      audioPlay: null*/
    };
  },
  props: {
    toolbarAlarmData: {
      type: Object,
      required: true
    },
    toolbarSocketData: {
      type: Object,
      required: true
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    getToolbarData: function() {
      return this.toolbarSocketData.menuitems;
    },
    getToolbarTitle: function() {
      return this.toolbarSocketData.title;
    },
    getToolbarUsername: function() {
      return this.toolbarSocketData.username;
    },
    getToolbarAlarmData: {
      get: function() {
        return this.toolbarAlarmData;
      },
      set: function(data) {
        if (this.toolbarAlarmData["alarms"] === null) {
          this.toolbarAlarmData["alarms"] = [];
        }
        this.toolbarAlarmData["alarms"] = [
          data,
          ...this.toolbarAlarmData["alarms"]
        ];
      }
    },
    drawerState: {
      set: function (v) {
        this.setDrawerState(v)
      },
      get: function () {
        return this.getDrawerState;
      }
    },
    ...mapState({
      currentRoute: state => state.storeGridEvents.currentRoute
    }),
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getDrawerState: "storeGridEvents/getDrawerState"
    })
  },
  created() {
    //Set default theme
    const themeDef = localStorage.getItem("THEME_OPTION");
    console.log("Theme", themeDef);
    if (themeDef === "DARK") {
      this.$vuetify.theme.dark = true;
      this.themeIcon = "mdi-brightness-6";
    } else if (themeDef === "LIGHT") {
      this.$vuetify.theme.dark = false;
      this.themeIcon = "mdi-brightness-4";
    } else if (themeDef === null) {
      localStorage.setItem("THEME_OPTION", "LIGHT");
      this.$vuetify.theme.dark = false;
      this.themeIcon = "mdi-brightness-4";
    }
  },
  methods: {
    ...mapActions({
      setDrawerState: 'storeGridEvents/actionSetDrawerState'
    }),
    logoutFunc: function () {
      this.componentOBJ.compName = "logout-dialog";
      this.componentOBJ.compProp = constructAlertDialog(
          "Çıkış yap",
          "Sistemden çıkış yapmak istediğinize emin misiniz ?",
          "Evet, eminim",
          "İptal"
      );
      this.componentOBJ.compProp["from"] = "Toolbar";
    },
    logoutPositive: function () {
      store.dispatch('storeLoginEvents/actionLogout');
      this.componentOBJ.compProp.dialogModel = false;
      this.componentOBJ.compName = "";
    },
    logoutNegative: function () {
      this.componentOBJ.compProp.dialogModel = false;
      this.componentOBJ.compName = "";
    },
    trans: function() {
      //this.setDrawerState(this.drawer);
    },
    changeTheme: function() {
      console.log("Dark Theme", this.$vuetify.theme.dark);
      if (!this.$vuetify.theme.dark) {
        this.$vuetify.theme.dark = true;
        this.themeIcon = "mdi-brightness-6";
        localStorage.setItem("THEME_OPTION", "DARK");
      } else {
        this.$vuetify.theme.dark = false;
        this.themeIcon = "mdi-brightness-4";
        localStorage.setItem("THEME_OPTION", "LIGHT");
      }
    }
  }
};
</script>

<style scoped>
.v-breadcrumbs {
  padding: 5px 12px !important;
}

.no-hidden-text {
  font-weight: 500;
  opacity: 0.4;
}
</style>
